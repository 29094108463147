/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {produce} from 'immer';
import {PureComponent} from 'react';
import {MenuInfo, Pill, type PillProps} from 'components';
import {hrefUtils, ipUtils} from '@illumio-shared/utils';
import {getFriendlyName} from './WorkloadUtils';
import {isClasEnabled} from 'containers/App/AppState';
import type {Workload as WorkloadData} from 'illumio';

export type WorkloadProps = PillProps & {
  value: WorkloadData;
  contextualMenuFullIp?: ipUtils.IP_IP;
  icon?: string;
  isCSFrame?: boolean;
};

export default class Workload extends PureComponent<WorkloadProps> {
  element: HTMLElement | null | undefined = null;

  constructor(props: WorkloadProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: {element: HTMLElement | null} | null) {
    this.element = element?.element;
  }

  renderContextualMenu: PillProps['contextualMenu'] = (items, ...rest) => {
    const menu = produce(items, draft => {
      const firstInfo = draft.find(item => item.type === MenuInfo);

      if (this.props.contextualMenuFullIp && firstInfo?.attributes) {
        firstInfo.attributes.push({
          key: intl('Common.IPAddress'),
          value: this.props.contextualMenuFullIp,
        });
      }
    });

    return this.props.contextualMenu ? this.props.contextualMenu(menu, ...rest) : menu;
  };

  render() {
    const {value, contextualMenuFullIp, icon = 'workload', isCSFrame = false, ...pillProps} = this.props;
    const name = getFriendlyName(value);
    const linkToView =
      isClasEnabled && value.href.search(/kubernetes_workloads/) > 0
        ? 'workloads.kubernetes.item.view'
        : 'workloads.item.view';

    Object.assign(pillProps, {
      link: {to: linkToView, params: {id: hrefUtils.getId(value.href)}},
      disabled: value.deleted,
      tooltip: value.deleted ? intl('Pill.Tooltip.WorkloadIsDeleted') : undefined,
    });

    if (!pillProps.noContextualMenu) {
      pillProps.contextualMenu = this.renderContextualMenu;
      pillProps.contextualType ??= isCSFrame ? intl('Common.ServiceTag', {multiple: false}) : intl('Common.Workload');
      pillProps.contextualCopyValue ??= name;
    }

    return (
      <Pill icon={icon} {...pillProps} ref={this.saveRef}>
        {name}
      </Pill>
    );
  }
}
