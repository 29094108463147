/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';

export const getPanelOpen = state => state.map?.panel?.panelOpen;
export const getPanelResizing = state => state.map?.panel?.panelResizing;
export const getViewWorkloadsFromMap = state => state.map?.panel?.viewWorkloadsFromMap;

export default {
  panel: combineReducers({
    panelOpen(state = false, action) {
      switch (action.type) {
        case 'MAP_PANEL_OPENED':
          return true;
        case 'MAP_PANEL_CLOSED':
          return false;
        default:
          return state;
      }
    },

    panelResizing(state = false, action) {
      switch (action.type) {
        case 'MAP_PANEL_RESIZE_STARTED':
          return true;
        case 'MAP_PANEL_RESIZE_COMPLETED':
          return false;
        default:
          return state;
      }
    },

    viewWorkloadsFromMap(state = true, action) {
      switch (action.type) {
        case 'MAP_VIEW_WORKLOADS_FROM_MAP':
          return true;
        case 'MAP_VIEW_ALL_GROUP_WORKLOADS':
          return false;
        default:
          return state;
      }
    },
  }),
};
