/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {Document as FSDocument} from 'flexsearch';
import {PureComponent, createRef} from 'react';
import {KEY_ESCAPE, KEY_BACK_SPACE, KEY_RETURN, KEY_RIGHT, KEY_UP, KEY_DOWN, KEY_TAB, KEY_K} from 'keycode-js';
import * as progressBar from 'progressBar';
import {domUtils} from '@illumio-shared/utils';
import {generalUtils} from '@illumio-shared/utils/shared';
import {AppContext} from 'containers/App/AppUtils';
import {Modal, Input, Icon, Button, Tooltip} from 'components';
import {updateInstantSearchHistory, fetchInstantSearchHistory} from './InstantSearchSaga';
import Animator from './InstantSearchAnimator';
import styles from './InstantSearch.css';
import styleUtils from 'utils.css';
import {
  default as getTesseReactContainersPropertiesMap,
  getContainerPropertiesByRouteMap as getTesseReactRoutesNamesMap,
  getLegacyRoutesDisplayNamesMap,
} from '../ContainersProperties';
import {removeRouteAppPrefix} from '../../router/routesUtils';
import {connect} from '@illumio-shared/utils/redux';
import {isClassicIlluminationEnabled, isClassicExplorerEnabled} from 'containers/User/Settings/SettingsState';

const highlightedTextCache = new Map();
// Some containers are not part of the route map and need to be added as exception
const routesIndexExceptions = [
  'IlluminationMap',
  'IlluminationTraffic',
  'IlluminationMesh',
  'ProductVersion',
  'CloudSecure',
  'SupportPortal',
];

const getEmptyState = (active = false, filter = null, history = []) => ({
  value: '',
  matches: [],
  selected: 0,
  history,
  filter,
  active,
  error: null,
  loading: false,
  method: 'click',
});

@connect(state => ({
  classicIlluminationEnabled: isClassicIlluminationEnabled(state),
  classicExplorerEnabled: isClassicExplorerEnabled(state),
}))
export default class InstantSearch extends PureComponent {
  static contextType = AppContext;

  debounceOnInputChange = _.debounce(async () => {
    const matches = await this.getInstantSearchMatches();

    this.setState({matches});
  }, 450);

  constructor(props, context) {
    super(props, context);

    this.state = getEmptyState();

    this.handleClose = this.handleClose.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnFilterClick = this.handleOnFilterClick.bind(this);
    this.handleDeleteSearchItem = this.handleDeleteSearchItem.bind(this);
    this.handleDeleteAllSearchItems = this.handleDeleteAllSearchItems.bind(this);
    this.getValidatedInstantSearchHistory = this.getValidatedInstantSearchHistory.bind(this);
    this.getInstantSearchMatches = this.getInstantSearchMatches.bind(this);
    this.formatItem = this.formatItem.bind(this);
    this.formatToMatches = this.formatToMatches.bind(this);
    this.setupRoutesIndex = this.setupRoutesIndex.bind(this);
    this.selectSuggestion = this.selectSuggestion.bind(this);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleShowInstantSearch = this.handleShowInstantSearch.bind(this);
    this.sanitizeSearchHistory = this.sanitizeSearchHistory.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.filterInvalidMatches = this.filterInvalidMatches.bind(this);

    this.filterRef = createRef();
    this.listRef = createRef();
    this.inputRef = createRef();

    this.modalStyle = {};
    this.searchInputWidth = 0;
    this.searchInputHeight = 0;
    this.storeState = context.store.getState();
    this.containersRouteNamesMap = new Map([
      ...getTesseReactRoutesNamesMap(this.storeState).entries(),
      ...Object.entries(getLegacyRoutesDisplayNamesMap(this.storeState)),
    ]);
    this.tesseReactContainerProperties = getTesseReactContainersPropertiesMap(this.storeState);
  }

  async componentDidMount() {
    try {
      await this.setupRoutesIndex();

      document.addEventListener('keydown', this.handleKeydown);

      // check history on initial mount for collectionName
      let history = await this.context.fetcher.spawn(fetchInstantSearchHistory);
      // collectionName is deprecated from history, so we need to sanitize the previous history
      const sanitizeHistory = history.some(historyObject => historyObject.collectionName);

      if (sanitizeHistory) {
        history = await this.sanitizeSearchHistory(history);
      }

      this.setState({history});
      this.handleShowInstantSearch();
    } catch (error) {
      throw new Error(error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      let history = prevState.history;

      // compare prevState and current state history for changes and reassign history
      if (
        prevState.history.length &&
        this.state.history.length &&
        !generalUtils.areArraysEqualWhenSorted(this.state.history, prevState.history)
      ) {
        history = this.state.history;
      }

      // only fetch from api if there is no state history
      if (!prevState.history.length && !this.state.history.length) {
        history = await this.context.fetcher.spawn(fetchInstantSearchHistory);
      }

      if (this.state.active === true) {
        await this.setupRoutesIndex(); // Routes index needs to be updated based on current data

        const currentRoute = this.context.router.getState().name;
        const matches = this.formatToMatches(history);

        this.context.sendAnalyticsEvent('instantSearch.show', {
          routeName: removeRouteAppPrefix(currentRoute),
          method: this.state.method,
          length: history.length,
        });

        this.setState({history, matches});
      } else {
        await this.clearFilter(false, null, history);
      }
    }
  }

  componentWillUnmount() {
    this.debounceOnInputChange.cancel();
    document.removeEventListener('keydown', this.handleKeydown);
  }

  // also being used to format history into {k,v} structure for matches
  // if no params is passed it will fetch the history
  async getInstantSearchMatches() {
    this.setState({loading: true});

    const history = await this.getValidatedInstantSearchHistory();

    try {
      const {value, filter, container} = this.state;
      const matches = [];

      if (filter) {
        const {getCollection} = this.tesseReactContainerProperties.get(container);
        const query = {query: value, facet: 'name', max_results: 25};
        const {matches: collectionMatches} = await this.context.fetcher.spawn(getCollection, {query});

        if (collectionMatches.length) {
          for (const match of collectionMatches) {
            // Sometimes autocomplete returns objects with name or value (for example Workloads and Labels)
            matches.push({key: match.name || match.value, value: match});
          }
        }
      } else if (!this.state.filter && history.length) {
        for (const item of history) {
          matches.push({key: item.value, value: item});
        }
      }

      this.setState({loading: false});

      return matches;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getValidatedInstantSearchHistory() {
    const history = await this.context.fetcher.spawn(fetchInstantSearchHistory);

    return history.filter(item => {
      // Items with href refer to another page, so they are not part of route names
      if (item.params?.href) {
        return true;
      }

      // Product version modal adds a query param to route so its not a part of route names
      if (item.name === 'ProductVersion') {
        return true;
      }

      if (item.name.toLowerCase().includes('illumination')) {
        // Illumination Plus and Illumination Map are only available to classic UI
        // Illumination sections are only available in the new UI
        return !['Illumination Plus', 'Illumination Map'].includes(item.value);
      }

      const formattedItem = this.formatItem(item.name);

      if (!this.containersRouteNamesMap.has(formattedItem)) {
        return false;
      }

      return this.containersRouteNamesMap.get(formattedItem).isAvailable ?? true;
    });
  }

  // Generates default route suggestion list for legacy and TesseReact routes.
  async setupRoutesIndex() {
    const routesIndex = new FSDocument({
      document: {
        id: 'viewName',
        store: ['viewName', 'name', 'container', 'isAvailable', 'params'],

        index: [
          {
            field: 'viewName',
            tokenize: 'full', // To support forward, reverse, and partial matches e.g. Typing 'ser' or 'ervi' or 'es', will match Services
          },
          {
            field: 'name',
            tokenize: 'strict', // Use default since we don't do direct input search on Collections
          },
        ],
      },
    });

    const {router} = this.context;
    const {routesMap} = router;
    const legacyRoutesDisplayNamesMap = getLegacyRoutesDisplayNamesMap(this.context.store.getState());
    const tesseReactContainersPropertiesMap = new Map(
      [...this.tesseReactContainerProperties].filter(([, value]) => !value.legacy),
    );
    let i = 0; // ID tracker

    const routeEntries = Array.from(routesMap.entries()).reduce((result, [, {name, container, component, load}]) => {
      if (container === 'JumpToOld') {
        if (legacyRoutesDisplayNamesMap[name]) {
          const {viewName, isAvailable} = legacyRoutesDisplayNamesMap[name];

          if (__DEV__) {
            if (typeof viewName !== 'function') {
              throw new TypeError(`viewName=${viewName} should be a function.`);
            }
          }

          const view = viewName();

          if (!result.get(view)) {
            result.set(view, routesIndex.addAsync({id: i, viewName: view, name, isAvailable}));
            i += 1;
          }
        }
      }

      if ((!component && load && !tesseReactContainersPropertiesMap.get(container)) || (!component && !load)) {
        return result;
      }

      // A container component that is loaded dynamically will have meta-properties available via the Container Properties (tesseReactContainersPropertiesMap) map.
      // Containers that are available globally for example can have their static meta-properties used.
      const {viewName, aliases, isAvailable, linkToRoute} =
        tesseReactContainersPropertiesMap.get(container) || component;

      // linkToRoute is meant to support containers that may exist on multiple routes. This way we can configure
      // multiple instances in routes but only add a single entry for a container in Instant Search.
      if (linkToRoute && !linkToRoute({routeName: name})) {
        return result;
      }

      if (viewName) {
        if (!result.get(viewName)) {
          result.set(viewName, routesIndex.addAsync({id: i, viewName, name, isAvailable, container}));
          i += 1;
        }

        if (aliases?.length) {
          aliases.forEach(alias => {
            const aliasView = alias.viewName;
            const aliasParams = alias.params;

            if (!result.get(aliasView)) {
              result.set(
                aliasView,
                routesIndex.addAsync({
                  id: i,
                  viewName: aliasView,
                  name: alias.linkProps?.to ?? name,
                  container,
                  isAvailable: alias.isAvailable,
                  params: aliasParams,
                }),
              );
              i += 1;
            }
          });
        }
      }

      return result;
    }, new Map());

    // Some containers are not part of the route map and need to be added as exception
    // These containers tend to be enabled through query parameters
    const exceptions = this.routesIndexExceptions(i);

    exceptions.forEach(exception => routesIndex.addAsync(exception));

    await Promise.all(Array.from(routeEntries.values())); // Call all routesIndex.addAsync() entries for a route
    this.setState({routesIndex});
  }

  getPlaceholder(value, filterViewName) {
    let placeholder;

    if (value?.length) {
      placeholder = undefined;
    } else if (filterViewName?.length && !value?.length) {
      placeholder = intl('InstantSearch.SearchByFilter', {filter: filterViewName});
    } else {
      placeholder = intl('Common.Search');
    }

    return placeholder;
  }

  getHint(match, value) {
    // Only show hint when input is not overflowing
    if (this.inputRef?.current) {
      const input = this.inputRef.current.input.current;
      const isOverflown = input.scrollHeight > input.clientHeight || input.scrollWidth > input.clientWidth;

      if (isOverflown) {
        return '';
      }
    }

    let hint = match?.key || match?.value?.hostname || match;

    if (value?.length && hint?.toLowerCase().startsWith(value.toLowerCase())) {
      hint = Array.from(hint).reduce((acc, char, index) => {
        if (value[index]?.toLowerCase() === char.toLowerCase()) {
          return acc + value[index];
        }

        return acc + char;
      }, '');
    } else {
      hint = '';
    }

    return hint;
  }

  async handleKeydown(evt) {
    // Open modal
    if (generalUtils.cmdOrCtrlPressed(evt) && evt.keyCode === KEY_K) {
      domUtils.preventEvent(evt);
      await this.toggleModal();

      return;
    }

    // Only listen for these key combinations when InstantSearch is visible
    if (this.state.active) {
      // This results in either a filter being added to the searchbar.
      // Otherwise, an animation is triggered in order to alert the user about invalid filtering.
      if (evt.keyCode === KEY_TAB) {
        if (this.state.matches.length) {
          const {matches, selected} = this.state;
          const value = matches[selected].value;

          if (value.container) {
            const {getCollection} = this.tesseReactContainerProperties.get(value.container);

            if (!this.state.filter && getCollection) {
              this.context.sendAnalyticsEvent('instantSearch.tab', {
                routeName: value?.name,
                method: 'keyboard',
              });

              // get the name of the selection from the value
              // use the name to get the container from the store map
              // format filter I.E. workloads.item -> app.workloads.list
              this.setState({
                value: '',
                filter: this.formatItem(value?.name),
                container: value.container,
                active: true,
                matches: [],
                method: 'keyboard',
              });
            }
          }
        } else {
          this.setState({shake: true});
        }

        return;
      }

      // Enter - navigate to selection
      if (this.state.matches.length && evt.keyCode === KEY_RETURN) {
        domUtils.preventEvent(evt);
        this.navigate();

        return;
      }

      // Provides arrow key functionality and highlights active suggestion
      if (evt.keyCode === KEY_DOWN || evt.keyCode === KEY_UP) {
        domUtils.preventEvent(evt); // Prevent jumping between input start / end

        const {selected} = this.state;
        let selectedIndex;

        if (evt.keyCode === KEY_DOWN && selected < this.state.matches.length - 1) {
          selectedIndex = selected + 1;

          this.setState({selected: selectedIndex});
        }

        if (evt.keyCode === KEY_UP && selected > 0) {
          selectedIndex = selected - 1;

          this.setState({selected: selectedIndex});
        }

        if (this.listRef.current?.children[selectedIndex]) {
          domUtils.scrollToElement({element: this.listRef.current?.children[selectedIndex]});
        }

        return;
      }

      // Fill input with matching text after pressing right arrow
      if (this.state.matches.length && (evt.keyCode === KEY_RIGHT || evt.keyCode === KEY_TAB)) {
        evt.preventDefault();
        highlightedTextCache.clear();

        const {matches, selected} = this.state;
        const autocompleteValue = matches[selected]?.key || matches[selected]?.value?.hostname;

        if (evt.target.setSelectionRange) {
          this.setState({value: autocompleteValue}, () => {
            // Move cursor to end of value
            evt.target.scrollLeft = evt.target.scrollWidth;
            evt.target.setSelectionRange(evt.target.value.length, evt.target.value.length);

            this.forceUpdate();
          });
        }

        return;
      }

      if (generalUtils.cmdOrCtrlPressed(evt)) {
        if (evt.keyCode === KEY_BACK_SPACE) {
          if (evt.shiftKey) {
            // Search History: Clear all
            this.handleDeleteAllSearchItems('keyboard', evt);
          } else if (generalUtils.isMac() && (this.state.value || this.state.filter)) {
            // Mac OS: CMD + DEL - clear line
            this.clearFilter();
          } else {
            // Search History: Clear selected item
            this.handleDeleteSearchItem('keyboard', evt);
          }

          return;
        }
      }

      // Escape key should close modal
      if (this.state.active && evt.keyCode === KEY_ESCAPE) {
        domUtils.preventEvent(evt);
        await this.toggleModal();

        return;
      }

      // Clear filter
      if (!this.state.value?.length && evt.keyCode === KEY_BACK_SPACE) {
        if (this.state.filter) {
          const {history} = this.state;
          const matches = this.formatToMatches(history);

          this.setState({active: true, filter: null, matches});
        } else {
          this.clearFilter();
        }
      }
    }
  }

  async handleClose() {
    await this.toggleModal();
  }

  handleOnClick(item, evt) {
    this.setState({selected: item}, _.partial(this.navigate, evt, 'click'));
  }

  // filtering on Tab
  async handleOnFilterClick(evt) {
    domUtils.preventEvent(evt);

    const {matches, selected, filter} = this.state;
    const value = matches[selected].value;
    const {getCollection} = this.tesseReactContainerProperties.get(value.container);

    this.context.sendAnalyticsEvent('instantSearch.tab', {
      routeName: value?.name,
      method: 'click',
    });

    if (!filter && getCollection) {
      this.setState({filter: this.formatItem(value.name), active: true, container: value.container, matches: []});
    }
  }

  async handleOnChange(evt) {
    if (evt.target.value.length) {
      highlightedTextCache.clear();

      if (this.state.filter?.length) {
        this.setState({value: evt.target.value, selected: 0}, () => {
          this.debounceOnInputChange();
        });
      } else {
        const matches = [];

        if (this.state.routesIndex) {
          for (const {doc: match} of this.state.routesIndex.search(evt.target.value, 25, {
            enrich: true,
            pluck: 'viewName',
          })) {
            const {viewName: key, isAvailable, ...value} = match;

            if (isAvailable === false) {
              continue;
            }

            matches.push({key, value});
          }
        }

        this.setState({matches, value: evt.target.value, selected: 0, shake: false, noResultsFound: !matches.length});
      }
    } else {
      // when user deletes the input so there is no value
      const {filter, history} = this.state;
      // convert previous history into matches format
      const matches = this.formatToMatches(history);

      this.setState({active: true, filter, history, matches, value: ''});
    }
  }

  handleAnimationEnd() {
    this.setState({shake: false});
  }

  handleDeleteAllSearchItems(method = 'keyboard', evt) {
    domUtils.preventEvent(evt);

    this.context.sendAnalyticsEvent('instantSearch.clear', {
      method,
      length: this.state.history.length,
    });

    this.context.store.runSaga(updateInstantSearchHistory, {history: [], dispatch: true}).toPromise();
    this.clearFilter(true);
  }

  handleDeleteSearchItem(method = 'keyboard', evt) {
    domUtils.preventEvent(evt);

    const {matches, selected} = this.state;

    if (matches.length) {
      const value = matches[selected].value;
      const {history} = this.state;

      this.context.sendAnalyticsEvent('instantSearch.clear', {
        routeName: removeRouteAppPrefix(value?.name),
        method,
        length: history.length,
      });

      // filter out deleting item from state history
      const newHistory = history.filter(item => !_.isEqual(value, item));
      // convert history into matches format {k,v}
      const newMatches = this.formatToMatches(newHistory);

      this.context.store.runSaga(updateInstantSearchHistory, {history: newHistory, dispatch: true}).toPromise();
      this.setState({matches: newMatches, history: newHistory});
    }
  }

  handleShowInstantSearch() {
    const {top, left, width, height} = this.props.searchInputRef?.current.getBoundingClientRect() ?? {};

    this.searchInputWidth = width;
    this.searchInputHeight = height;
    this.modalStyle = {top, left};

    this.props.searchInputRef?.current.addEventListener('click', this.toggleModal);
  }

  async sanitizeSearchHistory(history) {
    history.map(historyObject => {
      delete historyObject.collectionName;

      // convert the name from iplists.item -> app.iplists.list
      const name = this.formatItem(historyObject.name);

      const container = this.state.routesIndex?.search(name, {
        limit: 1,
        enrich: true,
        pluck: 'name',
      })?.[0]?.doc.container;

      historyObject.container = container;

      return historyObject;
    });

    await this.context.store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();

    return history;
  }

  formatToMatches(history) {
    const matches = [];

    for (const item of history) {
      matches.push({key: item.value, value: item});
    }

    return matches;
  }

  formatItem(item) {
    // Note we should only expect on instance of 'item' per route as it is the convention we follow
    // every route from routes.ts that has 'item' has parent 'list'
    if (!item.startsWith('app') && item.endsWith('item')) {
      return `app.${item.replace('item', 'list')}`;
    }

    return item;
  }

  routesIndexExceptions(index) {
    const exceptions = [...routesIndexExceptions];

    return exceptions.map(item => {
      const {viewName, isAvailable, linkProps, name} = this.tesseReactContainerProperties.get(item);

      index += 1;

      return (
        viewName &&
        isAvailable && {
          id: index,
          viewName,
          name: name ?? item,
          isAvailable,
          container: item,
          params: {...linkProps?.params, ...(linkProps?.href && {href: linkProps?.href})},
        }
      );
    });
  }

  async clearFilter(active = false, filter = null, history = []) {
    this.setState(getEmptyState(active, filter, history));
  }

  selectSuggestion(selected) {
    this.setState({selected});
  }

  async navigate(evt, method = 'keyboard') {
    const {store, router} = this.context;
    const {matches, selected} = this.state;
    const dest = matches[selected].value;
    const filter = this.state.filter || this.formatItem(dest.name);
    const container = this.state.routesIndex?.search(filter, {
      limit: 1,
      enrich: true,
      pluck: 'name',
    })?.[0]?.doc.container;
    const aliases = this.tesseReactContainerProperties.get(container)?.aliases ?? [];
    const getItemLinkProps = this.tesseReactContainerProperties.get(container)?.getItemLinkProps;
    let history = this.state.history;

    if (dest) {
      if (dest.href) {
        const params = getItemLinkProps?.(dest);

        // Parse route into route translatable form e.g.
        // 'app.workloads.list' --> 'workloads'
        // 'app.workloads.vens.list' --> 'workloads.vens'
        const parsedRouteName = filter.slice(filter.indexOf('.') + 1, filter.lastIndexOf('.'));
        const mostRecentSearch = this.state.history[0];

        if (
          matches[selected]?.key !== mostRecentSearch?.value ||
          dest.hostname !== mostRecentSearch?.value ||
          dest.name !== mostRecentSearch?.value
        ) {
          const historyObject = {
            value: matches[selected]?.key || dest.hostname,
            name: `${parsedRouteName}.item`,
            href: dest.href,
            container,
          };

          if (params) {
            historyObject.params = params;
          }

          history = [
            historyObject,
            ...this.state.history.filter(historyItem => !_.isEqual(historyItem, historyObject)),
          ];

          let isLegacy = false;

          if (router.routesMap.get(`app.${parsedRouteName}.item`)) {
            let itemRoute = router.routesMap.get(`app.${parsedRouteName}.item`);

            if (itemRoute.redirectTo) {
              itemRoute = router.routesMap.get(itemRoute.redirectTo);
              isLegacy = itemRoute.component?.name === 'JumpToOld';
            } else {
              isLegacy = itemRoute.component?.name === 'JumpToOld';
            }
          }

          // Since we are firing off an API request which could take N time to resolve, let's display a loading
          // indicator early so the user understands a change is occurring and use await to ensure the call is
          // finished before routing to the legacy app.
          if (isLegacy) {
            progressBar.start({delay: false});
            await store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();
          } else {
            store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();
          }
        }

        // format history into matches
        const matchesFromUpdatedHistory = this.formatToMatches(history);

        this.setState({history, matches: matchesFromUpdatedHistory}, this.toggleModal);

        this.context.sendAnalyticsEvent('instantSearch.navigate', {
          routeName: `${parsedRouteName}.item`,
          method,
        });

        // Navigate to item view
        this.context.navigate({to: `${parsedRouteName}.item`, params, evt});
      } else {
        const navigateOptions = {to: dest.name.split('app.')[1], evt};
        const key = matches[selected].key;
        const aliasParams = aliases.find(alias => alias.viewName === key)?.params;

        if (dest.params) {
          navigateOptions.params = dest.params;
        }

        if (aliasParams) {
          navigateOptions.params = aliasParams;
        }

        const mostRecentSearch = this.state.history[0];

        if (matches[selected]?.key !== mostRecentSearch?.value) {
          const historyObject = {
            value: matches[selected]?.key,
            name: dest.name,
            container,
          };

          if (dest.params) {
            historyObject.params = dest.params;
          }

          history = [
            historyObject,
            ...this.state.history.filter(historyItem => !_.isEqual(historyItem, historyObject)),
          ];

          let isLegacy = false;

          if (router.routesMap.get(dest.name)) {
            let itemRoute = router.routesMap.get(dest.name);

            if (itemRoute.redirectTo) {
              itemRoute = router.routesMap.get(itemRoute.redirectTo);
              isLegacy = itemRoute.component?.name === 'JumpToOld';
            } else {
              isLegacy = itemRoute.component?.name === 'JumpToOld';
            }
          }

          // Since we are firing off an API request which could take N time to resolve, let's display a loading
          // indicator early so the user understands a change is occurring and use await to ensure the call is
          // finished before routing to the legacy app.
          if (isLegacy) {
            progressBar.start({delay: false});
            await store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();
          } else {
            store.runSaga(updateInstantSearchHistory, {history, dispatch: true}).toPromise();
          }
        }

        // format history into matches
        const matchesFromUpdatedHistory = this.formatToMatches(history);

        this.setState({history, matches: matchesFromUpdatedHistory}, this.toggleModal);

        this.context.sendAnalyticsEvent('instantSearch.navigate', {
          routeName: navigateOptions?.to,
          method,
        });

        if (dest.params?.href) {
          // Open href in a new tab
          window.open(dest.params.href, dest.params.target);
        } else {
          // Navigate to menu level view
          this.context.navigate(navigateOptions);
        }
      }
    }
  }

  async toggleModal() {
    this.setState(prevState => ({active: !prevState.active}));
  }

  highlightText(match, input) {
    let matchLocations = [];

    if (input?.trim()) {
      const escapedInput = input.trim().replaceAll(/[$()*+.?[\\\]^{|}]/g, '\\$&');
      const regex = new RegExp(escapedInput, 'gi');
      let regexMatch;

      // Perform regex match on matching keys based on user input.
      // e.g. User enters "pro" which matches "Pairing Profile", this regex will execute one match at a time
      // and return the indexes of where "pro" occurs. Since RegExp is stateful we can re-execute it in order to capture
      // all matches until there are no more matches (where the regex returns null).
      if (highlightedTextCache.has(match)) {
        matchLocations = highlightedTextCache.get(match);
      } else {
        do {
          regexMatch = regex.exec(match);

          if (regexMatch) {
            matchLocations.push([regexMatch.index, regex.lastIndex]);
            highlightedTextCache.set(match, matchLocations);
          }
        } while (regexMatch !== null);
      }
    }

    const result = match?.split('').reduce((nodes, char, index) => {
      let currentNode = char;

      for (const location of matchLocations) {
        // Bold the element if the character is within the location tuple range.
        if (index >= location[0] && index < location[1]) {
          currentNode = (
            <span key={index} className={styles.highlight}>
              {char}
            </span>
          );
        }
      }

      // Concatenate strings to limit elements from crowding the dom.
      if (nodes.at(-1)) {
        const lastNode = nodes.at(-1);

        if (typeof currentNode === 'string' && typeof lastNode === 'string') {
          nodes.pop();
          currentNode = lastNode + char;
        }
      }

      nodes.push(currentNode);

      return nodes;
    }, []);

    return result;
  }

  formatHistoryItem(item) {
    const historyItem = typeof item.value === 'object' ? item.value : item;

    if (historyItem.name.toLowerCase().includes('illumination')) {
      return historyItem.value;
    }

    const {viewName} = this.containersRouteNamesMap.get(this.formatItem(historyItem.name)) ?? {};

    let historyItemText = (typeof viewName === 'function' ? viewName() : viewName) ?? historyItem.value;

    if (historyItem.value && historyItem.value !== historyItemText) {
      if (historyItem.value.includes(viewName)) {
        historyItemText = historyItem.value;
      } else {
        historyItemText = `${viewName} - ${historyItem.value}`;
      }
    }

    return historyItemText;
  }

  filterInvalidMatches(matches) {
    const {classicExplorerEnabled, classicIlluminationEnabled} = this.props;

    if (classicExplorerEnabled && classicIlluminationEnabled) {
      return matches;
    }

    return matches?.filter(({value}) => {
      const isExplorer = value?.name === 'app.explorer';
      const isMap = value?.name === 'app.map';

      // Include items that are not 'app.explorer' or 'app.map' when the corresponding flags are enabled
      return !((isExplorer && !classicExplorerEnabled) || (isMap && !classicIlluminationEnabled));
    });
  }

  render() {
    const {selected, value, shake, routesIndex, noResultsFound, history, filter, active, matches, loading} = this.state;
    const showHistory = history.length > 0 && !(filter || value?.length);

    let suggestions;

    if (loading) {
      suggestions = _.range(4).map(value => (
        <li data-tid="is-suggestion-loading-skeleton" key={value} className={styles.suggestionItem}>
          <span className={styles.textSkeleton} />
        </li>
      ));
    } else {
      suggestions = this.filterInvalidMatches(matches)?.map((match, index) => {
        let getCollection;

        if (match.value?.container) {
          getCollection = this.tesseReactContainerProperties.get(match.value?.container)?.getCollection;
        }

        return (
          <li
            data-tid={`is-suggestion-${index}`}
            key={index}
            className={
              index === selected
                ? styles.selectedSuggestionItem
                : showHistory
                ? styles.suggestionHistoryItem
                : styles.suggestionItem
            }
            onClick={_.partial(this.handleOnClick, index)}
            onMouseOver={_.partial(this.selectSuggestion, index)}
          >
            <div className={styles.animateSuggestionItem}>
              {showHistory && (
                <Icon
                  theme={{icon: index === selected ? styles.recentSearchesIconSelected : styles.recentSearchesIcon}}
                  name="search"
                />
              )}
              <span className={styles.suggestionText}>
                {showHistory
                  ? this.formatHistoryItem(match)
                  : this.highlightText(match.key || match.value?.hostname || match, value)}
              </span>
              <span className={cx(styles.actions, styleUtils.gapMedium, styleUtils.gapHorizontal)}>
                {getCollection && (
                  <Button
                    size="small"
                    color="standard"
                    theme={{button: styles.button}}
                    text={intl('InstantSearch.TabToFilter')}
                    icon="filter"
                    onClick={this.handleOnFilterClick}
                  />
                )}
                {showHistory && (
                  <Tooltip
                    placement="top"
                    content={`${intl('Common.Delete')} (${generalUtils.isMac() ? 'Cmd + Delete' : 'Ctrl + Delete'})`}
                  >
                    <Button
                      size="small"
                      noFill
                      noAnimateInAndOut
                      tid="is-delete-search-item"
                      theme={styles}
                      themePrefix={index === selected ? 'selectedDelete-' : 'delete-'}
                      icon="close"
                      aria-label={`${intl('Common.Delete')} (${
                        generalUtils.isMac() ? 'Cmd + Delete' : 'Ctrl + Delete'
                      })`}
                      onClick={_.partial(this.handleDeleteSearchItem, 'click')}
                    />
                  </Tooltip>
                )}
              </span>
            </div>
          </li>
        );
      });
    }

    const filterViewName = routesIndex?.search(filter, {enrich: true, limit: 1, pluck: 'name'})?.[0]?.doc.viewName;
    const hint = this.getHint(matches && matches[selected], value, selected);
    const placeholder = this.getPlaceholder(value, filterViewName);

    if (filterViewName && this.filterRef?.current && filterViewName !== this.filterRef?.current?.textContent) {
      this.forceUpdate();
    }

    return (
      active && (
        <Modal
          style={this.modalStyle}
          instant
          full
          notResizable
          autoFocus
          tid="instant-search"
          theme={styles}
          minHeight="0"
          onClose={this.handleClose}
        >
          <Animator inputDimensions={{width: this.searchInputWidth, height: this.searchInputHeight}}>
            <div className={shake ? styles.shake : styles.container}>
              <Icon theme={styles} name="search" />
              <span
                data-tid="is-filter"
                ref={this.filterRef}
                className={styles.filter}
                style={{
                  visibility: filterViewName ? 'visible' : 'hidden',
                }}
              >
                {filterViewName}
              </span>
              <Input
                tid="instantSearchHint"
                noWrap
                tabIndex="-1"
                readOnly
                value={hint}
                placeholder={placeholder}
                style={{
                  paddingLeft: filterViewName && `calc(${this.filterRef?.current?.clientWidth}px + var(--55px))`,
                  paddingRight: value.length ? 'calc(var(--100px) + var(--40px))' : '0',
                }}
                theme={{
                  input: suggestions?.length ? styles.inputBorderRadiusTopHint : styles.inputHint,
                }}
              />
              <Input
                tid="instantSearchInput"
                noWrap
                ref={this.inputRef}
                value={value}
                style={{
                  paddingLeft: filterViewName && `calc(${this.filterRef?.current?.clientWidth}px + var(--55px))`,
                  paddingRight: value.length ? 'calc(var(--100px) + var(--40px))' : '0',
                }}
                theme={{
                  input: suggestions?.length ? styles.inputBorderRadiusTop : styles.input,
                }}
                onChange={this.handleOnChange}
              />
              {noResultsFound && (
                <span data-tid="is-no-results" className={styles.noResultsFound}>
                  {intl('Common.NoResultsFound')}
                </span>
              )}
              {showHistory && (
                <div className={styles.recentSearches}>
                  <span data-tid="is-recent-searches" className={styles.recentSearchesText}>
                    {intl('InstantSearch.RecentSearches')}
                  </span>
                  <Tooltip
                    placement="top"
                    content={`${intl('InstantSearch.ClearAll')} (${
                      generalUtils.isMac() ? 'Cmd + Shift + Delete' : 'Ctrl + Shift + Delete'
                    })`}
                  >
                    <Button
                      size="small"
                      noFill
                      color="standard"
                      tid="is-clear-all"
                      theme={{button: styles.clearAllButton}}
                      aria-label={`${intl('InstantSearch.ClearAll')} (${
                        generalUtils.isMac() ? 'Cmd + Shift + Delete' : 'Ctrl + Shift + Delete'
                      })`}
                      text={intl('InstantSearch.ClearAll')}
                      onClick={_.partial(this.handleDeleteAllSearchItems, 'click')}
                    />
                  </Tooltip>
                </div>
              )}
              <div
                className={styles.listWrapper}
                style={{
                  height: suggestions?.length ? `calc(${suggestions?.length} * var(--46px))` : 0,
                  overflowY: suggestions?.length >= 7 ? 'auto' : 'hidden',
                }}
              >
                <Modal.StickyShadow top />
                <ul ref={this.listRef} className={styles.suggestions}>
                  {suggestions}
                </ul>
                <Modal.StickyShadow bottom />
              </div>
              {suggestions?.length > 0 && (
                <div className={styles.shortcuts}>
                  <span className={styles.shortcut}>↑</span>
                  <span className={styles.shortcut}>↓</span>
                  <span className={styles.shortcutText}>to navigate</span>
                  <span className={styles.shortcutOffset}>↵</span>
                  <span className={styles.shortcutText}>to select</span>
                  <span className={styles.shortcut}>esc</span>
                  <span className={styles.shortcutText}>to close</span>
                </div>
              )}
            </div>
          </Animator>
        </Modal>
      )
    );
  }
}
