/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Icon} from 'components';
import {createSelector} from 'reselect';

//This file maps all the routes to a list of help popup items
//Each help popup item is an object with a title and subtitle
export default createSelector([], () => ({
  map: [
    {
      title: intl('Map.ReportedView'),
      subtitle: intl('Help.Desc.ReportedView'),
    },
    {
      title: intl('Common.IlluminationMap'),
      subtitle: intl('Help.Desc.IlluminationMap'),
    },
    {
      title: intl('Common.Location'),
      subtitle: intl('Help.Desc.NoHelp'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Group'),
      subtitle: intl('Help.Desc.NoHelp'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Map.ConnectedGroup'),
      subtitle: intl('Help.Desc.ConnectedGroup'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
  ],
  mapDraft: [
    {
      title: intl('Map.DraftView'),
      subtitle: intl('Help.Desc.DraftView'),
    },
    {
      title: intl('Common.Ruleset'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Service'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Common.SecureConnect'),
      subtitle: intl('Help.Desc.SecureConnect'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
    {
      title: intl('Help.Title.TrafficLines'),
      subtitle: intl('Help.Desc.TrafficLines'),
    },
  ],
  appMap: [
    {
      title: intl('Common.AppGroup'),
      subtitle: intl('Help.Desc.AppGroup'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Map.ConsumingAppGroups'),
      subtitle: intl('Help.Desc.ConsumingAppGroups'),
    },
    {
      title: intl('Map.ProvidingAppGroups'),
      subtitle: intl('Help.Desc.ProvidingAppGroups'),
    },
    {
      title: intl('Common.Service'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Common.Ruleset'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Help.Title.TrafficLines'),
      subtitle: intl('Help.Desc.TrafficLines'),
    },
  ],
  appMapDraft: [
    {
      title: intl('Common.AppGroup'),
      subtitle: intl('Help.Desc.AppGroup'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Map.ConsumingAppGroups'),
      subtitle: intl('Help.Desc.ConsumingAppGroups'),
    },
    {
      title: intl('Map.ProvidingAppGroups'),
      subtitle: intl('Help.Desc.ProvidingAppGroups'),
    },
    {
      title: intl('Common.Service'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Common.Ruleset'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
    {
      title: intl('Help.Title.Rule'),
      subtitle: intl('Help.Desc.Rule'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Common.SecureConnect'),
      subtitle: intl('Help.Desc.SecureConnect'),
    },
    {
      title: intl('Help.Title.TrafficLines'),
      subtitle: intl('Help.Desc.TrafficLines'),
    },
  ],
  segmentationTemplates: [
    {
      title: intl('SegmentationTemplates.SegmentationTemplate'),
      subtitle: intl('Help.Desc.SegmentationTemplate'),
    },
    {
      title: intl('Common.Rulesets'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Services'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.Group'),
      subtitle: intl('Help.Desc.LabelGroup'),
    },
    {
      title: intl('Common.IPList'),
      subtitle: intl('Help.Desc.IPList'),
    },
  ],
  services: [
    {
      title: intl('Common.Services'),
      subtitle: intl('Help.Desc.Services'),
    },
    {
      title: intl('Provision.Status'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
  ],
  serviceAccounts: [
    {
      title: intl('Common.ServiceAccounts'),
      subtitle: intl('Help.Desc.ServiceAccounts'),
    },
  ],
  mymanagedtenants: [
    {
      title: intl('MSP.MyManagedTenants'),
      subtitle: intl('Help.Desc.MyManagedTenants'),
    },
  ],
  apiKeySettings: [
    {
      title: intl('APIKeySettings.APIKeySettings'),
      subtitle: intl('Help.Desc.APIKeySettings'),
    },
  ],
  iplists: [
    {
      title: intl('Common.IPList'),
      subtitle: intl('Help.Desc.IPList'),
    },
    {
      title: intl('Provision.Status'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
  ],
  labelGroups: [
    {
      title: intl('Labels.Group'),
      subtitle: intl('Help.Desc.LabelGroup'),
    },
    {
      title: intl('Provision.Status'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
  ],
  userGroups: [
    {
      title: intl('Common.UserGroups'),
      subtitle: intl('Help.Desc.UserGroup'),
    },
    {
      title: intl('UserGroups.SID'),
      subtitle: intl('Help.Desc.SID'),
    },
  ],
  virtualServers: [
    {
      title: intl('Common.VirtualServers'),
      subtitle: intl('Help.Desc.VirtualServers'),
    },
  ],
  securitysettings: [
    {
      title: intl('Common.SecuritySettings'),
      subtitle: intl('Help.Desc.SecuritySettings'),
    },
    {
      title: intl('Firewall.Coexistence.Title'),
      subtitle: intl('Help.Desc.FirewallCoexistence'),
    },
    {
      title: intl('Common.StaticPolicy'),
      subtitle: intl('Help.Desc.StaticPolicy'),
    },
    {
      title: intl('Settings.Containers.ContainerInheritHostPolicy'),
      subtitle: intl('Help.Desc.ContainerInheritHostPolicy'),
    },
    {
      title: intl('Common.BlockAction'),
      subtitle: intl('Help.Desc.RejectConnections'),
    },
  ],
  essentialservicerules: [
    {
      title: intl('Settings.EssentialServiceRules'),
      subtitle: intl('Help.Desc.EssentialServiceRules'),
    },
  ],
  ikecertificates: [
    {
      title: intl('Settings.IKECertificates'),
      subtitle: intl('Help.Desc.IKECertificates'),
    },
  ],
  provision: [
    {
      title: intl('Common.Provision'),
      subtitle: intl('Help.Desc.Provision'),
    },
    {
      title: intl('Common.Revert'),
      subtitle: intl('Help.Desc.Revert'),
    },
  ],
  workloads: [
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.CustomLabelType'),
      subtitle: intl('Help.Desc.CustomLabelType'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Common.Unmanaged'),
      subtitle: intl('Help.Desc.Unmanaged'),
    },
    {
      title: intl('Common.Managed'),
      subtitle: intl('Help.Desc.Managed'),
    },
    {
      title: intl('Settings.EssentialServiceRules'),
      subtitle: intl('Help.Desc.EssentialServiceRules'),
    },
  ],

  workloadsWithVulnerabilities: [
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: intl('Help.Desc.VEScore'),
    },
    {
      title: (
        <span>
          <Icon name="internet" /> {intl('Vulnerability.InternetExposure')}
        </span>
      ),
      subtitle: intl('Help.Desc.InternetExposure'),
    },
    {
      title: intl('Vulnerability.Score'),
      subtitle: intl('Help.Desc.VulnerabilityScore'),
    },
    {
      title: intl('Vulnerability.ExposureScore'),
      subtitle: intl('Help.Desc.ExposureScore'),
    },
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.CustomLabelType'),
      subtitle: intl('Help.Desc.CustomLabelType'),
    },
    {
      title: intl('Common.Enforcement'),
      subtitle: intl('Help.Desc.Enforcement'),
    },
    {
      title: intl('Common.Unmanaged'),
      subtitle: intl('Help.Desc.Unmanaged'),
    },
    {
      title: intl('Common.Managed'),
      subtitle: intl('Help.Desc.Managed'),
    },
    {
      title: intl('Settings.EssentialServiceRules'),
      subtitle: intl('Help.Desc.EssentialServiceRules'),
    },
  ],
  workloadDetails: [
    {
      title: intl('Workloads.VENConnectivity'),
      subtitle: intl('Help.Desc.VENConnectivity'),
    },
    {
      title: intl('Workloads.PolicySync'),
      subtitle: intl('Help.Desc.PolicySync'),
    },
    {
      title: intl('Common.Attributes'),
      subtitle: intl('Help.Desc.Attributes'),
    },
  ],
  virtualServices: [
    {
      title: intl('Common.VirtualServices'),
      subtitle: intl('Help.Desc.VirtualServices'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.CustomLabelType'),
      subtitle: intl('Help.Desc.CustomLabelType'),
    },
  ],
  labels: [
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.CustomLabelType'),
      subtitle: intl('Help.Desc.CustomLabelType'),
    },
  ],
  pairingProfiles: [
    {
      title: intl('PairingProfiles.Profile'),
      subtitle: intl('Help.Desc.PairingProfile'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.CustomLabelType'),
      subtitle: intl('Help.Desc.CustomLabelType'),
    },
  ],
  loadBalancers: [
    {
      title: intl('Menu.LoadBalancers'),
      subtitle: intl('Help.Desc.LoadBalancers'),
    },
  ],
  containerClusters: [
    {
      title: intl('Menu.ContainerClusters', {multiple: true}),
      subtitle: intl('Help.Desc.ContainerClusters'),
    },
    {
      title: intl('Common.OpenShift'),
      subtitle: intl('Help.Desc.OpenShift'),
    },
    {
      title: intl('Common.Kubelink'),
      subtitle: intl('Help.Desc.Kubelink'),
    },
    {
      title: intl('Common.Services'),
      subtitle: intl('Help.Desc.CCServices'),
    },
  ],
  containerWorkloads: [
    {
      title: intl('Common.ContainerWorkloads'),
      subtitle: intl('Help.Desc.ContainerWorkloads'),
    },
  ],
  secureGateways: [
    {
      title: intl('SecureGateway.SecureConnect'),
      subtitle: intl('Help.Desc.SecureGateways'),
    },
  ],
  networks: [
    {
      title: intl('Menu.Networks', {multiple: true}),
      subtitle: intl('Help.Desc.Networks'),
    },
  ],
  reports: [
    {
      title: intl('Exports.PageName'),
      subtitle: intl('Help.Desc.Reports'),
    },
  ],
  outboundpolicy: [
    {
      title: intl('Help.Title.OrganizationalOutboundPolicy'),
      subtitle: intl('Help.Desc.OrganizationalOutboundPolicy'),
    },
  ],
  vens: [
    {
      title: intl('VEN.Library'),
      subtitle: intl('Help.Desc.Vens'),
    },
  ],
  venslist: [
    {
      title: intl('VEN.IndividualEndpoints'),
      subtitle: intl('Help.Desc.Vens'),
    },
  ],
  vensListCore: [
    {
      title: intl('Common.VEN'),
      subtitle: intl('Help.Desc.VenInfo'),
    },
    {
      title: intl('Common.Actions'),
      subtitle: intl('Help.Desc.VenActions'),
    },
    {
      title: intl('PairingProfiles.Profile'),
      subtitle: intl('Help.Desc.VenPairingProfiles'),
    },
    {
      title: intl('VEN.PairingScript'),
      subtitle: intl('Help.Desc.VenPairingScripts'),
    },
  ],
  authenticationSettings: [
    {
      title: intl('Common.AccessConfiguration'),
      subtitle: intl('Help.Desc.AccessConfiguration'),
    },
  ],
  provisioning: [
    {
      title: intl('Provision.Settings'),
      subtitle: intl('Help.Desc.Provisioning'),
    },
  ],
  policySettings: [
    {
      title: intl('Policy.Settings'),
      subtitle: intl('Help.Desc.PolicySettings'),
    },
  ],
  blockedTraffic: [
    {
      title: intl('Common.Blocked'),
      subtitle: intl('Help.Desc.Blocked'),
    },
    {
      title: intl('Common.PotentiallyBlocked'),
      subtitle: intl('Help.Desc.PotentiallyBlocked'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
  ],
  orgevents: [
    {
      title: intl('Common.OrganizationEvents'),
      subtitle: intl('Help.Desc.OrgEvents'),
    },
  ],
  connectivity: [
    {
      title: intl('Policy.Check'),
      subtitle: intl('Help.Desc.PolicyCheck'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Connectivity.PortAndProtocol'),
      subtitle: intl('Help.Desc.PortAndProtocol'),
    },
  ],
  rulesets: [
    {
      title: intl('Common.Rulesets'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Scope'),
      subtitle: intl('Help.Desc.Scope'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.CustomLabelType'),
      subtitle: intl('Help.Desc.CustomLabelType'),
    },
    {
      title: intl('Provision.Status'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Status'),
      subtitle: intl('Help.Desc.Status'),
    },
    {
      title: intl('Rulesets.Rules.LabelAndLabelGroupsExcept'),
      subtitle: intl('Help.Desc.LabelAndLabelGroupsExcept'),
    },
  ],
  rulesetRules: [
    {
      title: intl('Common.Ruleset'),
      subtitle: intl('Help.Desc.Rulesets'),
    },
    {
      title: intl('Common.Scope'),
      subtitle: intl('Help.Desc.Scope'),
    },
    {
      title: intl('Role.Label'),
      subtitle: intl('Help.Desc.RoleLabel'),
    },
    {
      title: intl('Labels.Application'),
      subtitle: intl('Help.Desc.ApplicationLabel'),
    },
    {
      title: intl('Labels.Environment'),
      subtitle: intl('Help.Desc.EnvironmentLabel'),
    },
    {
      title: intl('Labels.Location'),
      subtitle: intl('Help.Desc.LocationLabel'),
    },
    {
      title: intl('Labels.CustomLabelType'),
      subtitle: intl('Help.Desc.CustomLabelType'),
    },
    {
      title: intl('Provision.Status'),
      subtitle: intl('Help.Desc.ProvisionStatus'),
    },
    {
      title: intl('Common.Destinations'),
      subtitle: intl('Help.Desc.Destination'),
    },
    {
      title: intl('Rulesets.Rules.ProvidingService'),
      subtitle: intl('Help.Desc.ProvidingService'),
    },
    {
      title: intl('Common.Sources'),
      subtitle: intl('Help.Desc.Source'),
    },
    {
      title: intl('Common.SecureConnect'),
      subtitle: intl('Help.Desc.SecureConnect'),
    },
    {
      title: intl('Common.Status'),
      subtitle: intl('Help.Desc.Status'),
    },
    {
      title: intl('Rulesets.DuplicateRuleset'),
      subtitle: intl('Help.Desc.DuplicateRuleset'),
    },
    {
      title: intl('PolicyGenerator.IntraScopeRules'),
      subtitle: intl('Help.Desc.IntraScopeRules'),
    },
    {
      title: intl('PolicyGenerator.ExtraScopeRules'),
      subtitle: intl('Help.Desc.ExtraScopeRules'),
    },
    {
      title: intl('Help.Title.CustomiptablesRules'),
      subtitle: intl('Help.Desc.CustomiptablesRules'),
    },
    {
      title: intl('Help.Title.DraftVersion'),
      subtitle: intl('Help.Desc.DraftVersion'),
    },
    {
      title: intl('Help.Title.ActiveVersion'),
      subtitle: intl('Help.Desc.ActiveVersion'),
    },
    {
      title: intl('Rulesets.Rules.LabelAndLabelGroupsExcept'),
      subtitle: intl('Help.Desc.LabelAndLabelGroupsExcept'),
    },
    {
      title: intl('Rulesets.Rules.UseWorkloadSubnets'),
      subtitle: intl('Help.Desc.UseWorkloadSubnets'),
    },
  ],
  supportreports: [
    {
      title: intl('PCESupportBundles.PageName'),
      subtitle: intl('Help.Desc.PCESupportBundles'),
    },
    {
      title: intl('SupportReports.PageName'),
      subtitle: intl('Help.Desc.SupportReports'),
    },
  ],
  explorer: [
    {
      title: intl('Common.Explorer'),
      subtitle: intl('Help.Desc.Explorer'),
    },
    {
      title: intl('TrafficParameters.ExplorerSearchFormats'),
      subtitle: intl('Help.Desc.ExplorerSearchFormats'),
    },
  ],
  illuminationPlus: [
    {
      title: intl('Common.IlluminationPlus'),
      subtitle: intl('Help.Desc.IlluminationPlus'),
    },
    {
      title: intl('TrafficParameters.IlluminationPlusSearchFormats'),
      subtitle: intl('Help.Desc.IlluminationPlusSearchFormats'),
    },
  ],
  usersActivity: [
    {
      title: intl('RBAC.UsersActivity.UserActivity'),
      subtitle: intl('Help.Desc.UserActivity'),
    },
  ],
  globalRoles: [
    {
      title: intl('RBAC.GlobalRoles'),
      subtitle: intl('Help.Desc.GlobalRoles'),
    },
  ],
  scopedRoles: [
    {
      title: intl('Common.Scoped'),
      subtitle: intl('Help.Desc.ScopedRoles'),
    },
  ],
  usersAndGroups: [
    {
      title: intl('Users.UsersAndGroups'),
      subtitle: intl('Help.Desc.UsersAndGroups'),
    },
  ],
  passwordPolicy: [
    {
      title: intl('PasswordPolicy.PasswordPolicy'),
      subtitle: intl('Help.Desc.PasswordPolicy'),
    },
  ],
  events: [
    {
      title: intl('Common.Events'),
      subtitle: intl('Help.Desc.Events'),
    },
    {
      title: intl('Events.Configuration'),
      subtitle: intl('Help.Desc.EventConfiguration'),
    },
  ],
  versions: [
    {
      title: intl('Provision.Versions', {multiple: true}),
      subtitle: intl('Help.Desc.Versions'),
    },
  ],
  appGroups: [
    {
      title: intl('Common.AppGroup'),
      subtitle: intl('Help.Desc.AppGroupList'),
    },
  ],
  appGroupType: [
    {
      title: intl('AppGroups.AppGroupType'),
      subtitle: intl('Help.Desc.AppGroupType'),
    },
  ],
  sso: [
    {
      title: intl('Users.SSOConfig.SSOConfig'),
      subtitle: intl('Help.Desc.SSOConfig'),
    },
  ],
  offlineTimers: [
    {
      title: intl('OfflineTimers.OfflineTimers'),
      subtitle: intl('Help.Desc.OfflineTimers'),
    },
  ],
  appGroupDetailsWorkloads: [
    {
      title: intl('Common.Workload'),
      subtitle: intl('Help.Desc.Workload'),
    },
    {
      title: intl('Vulnerability.VulnerabilityExposureScore'),
      subtitle: `${intl('Help.Desc.VEScore')} ${intl('Help.Desc.VEScoreAppGroupAggregation')}`,
    },
    {
      title: intl('Vulnerability.Score'),
      subtitle: intl('Help.Desc.VulnerabilityScore'),
    },
    {
      title: intl('Common.ExposureScore'),
      subtitle: intl('Help.Desc.ExposureScore'),
    },
    {
      title: intl('Vulnerability.EWExposure'),
      subtitle: intl('Help.Desc.EWExposure'),
    },
    {
      title: (
        <span className="HelpCards-Icon">
          <Icon name="internet" size="xlarge" position="before" /> {intl('Vulnerability.InternetExposure')}
        </span>
      ),
      subtitle: intl('Help.Desc.InternetExposure'),
    },
  ],
  appGroupDetailsRules: [
    {
      title: intl('Common.Rules'),
      subtitle: intl('Help.Desc.AppGroupDetailsRules'),
    },
  ],
  appGroupDetailsPP: [
    {
      title: intl('PairingProfiles.Profile'),
      subtitle: intl('Help.Desc.PairingProfile'),
    },
  ],
  pceHealth: [
    {
      title: intl('Health.PCEHealth'),
      subtitle: intl('Help.Desc.PCEHealth'),
    },
  ],
  myProfile: [
    {
      title: intl('Users.MyProfile'),
      subtitle: intl('Help.Desc.MyProfile'),
    },
  ],
  apiKeys: [
    {
      title: intl('Users.APIKeys.Name'),
      subtitle: intl('Help.Desc.APIKeys'),
    },
  ],
  ruleSearch: [
    {
      title: intl('Common.RuleSearch'),
      subtitle: intl('Help.Desc.RuleSearch'),
    },
  ],
  accessRestrictions: [
    {
      title: intl('Common.AccessRestrictions'),
      subtitle: intl('Help.Desc.AccessRestrictions'),
    },
  ],
  noRoute: [
    {
      title: intl('Help.Title.NoHelp'),
      subtitle: intl('Help.Desc.NoHelp'),
    },
  ],
  group: [
    {
      title: intl('Common.Groups'),
      subtitle: intl('Groups.GroupHelpTextSubtitle'),
    },
    {
      title: intl('Common.GroupDashboard'),
      subtitle: intl('Groups.GroupDashboardHelpTextSubtitle'),
    },
    {
      title: intl('Common.GroupWizard'),
      subtitle: intl('Groups.GroupWizardHelpTextSubtitle'),
    },
  ],
  admin: [
    {
      title: intl('Common.Groups'),
      subtitle: intl('Common.GroupHelpTextSubtitle'),
    },
    {
      title: intl('Common.GroupDashboard'),
      subtitle: intl('Groups.GroupDashboardHelpTextSubtitle'),
    },
    {
      title: intl('Common.GroupWizard'),
      subtitle: intl('Common.GroupWizardHelpTextSubtitle'),
    },
  ],
  reporting: [
    {
      title: intl('Common.Reports'),
      subtitle: intl('Help.Desc.Reporting'),
    },
    {
      title: intl('Reports.ReportType'),
      subtitle: intl('Help.Desc.ReportType'),
    },
    {
      title: intl('Reports.RecurringReports'),
      subtitle: intl('Help.Desc.RecurringReports'),
    },
    {
      title: intl('Reports.ExecutiveSummaryReport'),
      subtitle: intl('Help.Desc.ExecutiveSummaryReport'),
    },
    {
      title: intl('Reports.AppGroupSummaryReport'),
      subtitle: intl('Help.Desc.AppGroupSummaryReport'),
    },
    {
      title: intl('Reports.ReportRetentionPeriod'),
      subtitle: intl('Help.Desc.ReportRetentionPeriod'),
    },
    {
      title: intl('Reports.ReportRecurrence'),
      subtitle: intl('Help.Desc.ReportRecurrence'),
    },
  ],
  coreServices: [
    {
      title: intl('CoreServices.CoreServicesDetector'),
      subtitle: intl('Help.Desc.CoreServicesDetector'),
    },
    {
      title: intl('CoreServices.CoreServices'),
      subtitle: intl('Help.Desc.CoreServices'),
    },
    {
      title: intl('CoreServices.DetectionModel'),
      subtitle: intl('Help.Desc.DetectionModel'),
    },
    {
      title: intl('CoreServices.PortMatch'),
      subtitle: intl('Help.Desc.PortMatch'),
    },
    {
      title: intl('CoreServices.TrafficFlows'),
      subtitle: intl('Help.Desc.PortBased'),
    },
    {
      title: intl('CoreServices.ProcessNames'),
      subtitle: intl('Help.Desc.ProcessBased'),
    },
    {
      title: intl('CoreServices.RecommendedCoreService'),
      subtitle: intl('Help.Desc.RecommendedCoreService'),
    },
    {
      title: intl('CoreServices.AcceptedCoreServices'),
      subtitle: intl('Help.Desc.AcceptedCoreService'),
    },
    {
      title: intl('CoreServices.RejectedCoreServices'),
      subtitle: intl('Help.Desc.RejectedCoreService'),
    },
  ],
  enforcementBoundaries: [
    {
      title: intl('Rulesets.DenyRules'),
      subtitle: intl('Help.Desc.EnforcementBoundaries'),
    },
    {
      title: intl('Help.Title.SelectiveEnforcement'),
      subtitle: intl('Help.Desc.SelectiveEnforcementState'),
    },
    {
      title: intl('Help.Title.EnforcementBoundaryWorkflow'),
      subtitle: intl('Help.Desc.EnforcementBoundaryWorkflow'),
    },
    {
      title: intl('EnforcementBoundaries.BlockedConnections'),
      subtitle: intl('Help.Desc.EnforcementBoundaryBlockedConnections'),
    },
    {
      title: intl('Help.Title.AllowSelectedConnections'),
      subtitle: intl('Help.Desc.AllowSelectedConnections'),
    },
  ],
  dashboard: [
    {
      title: intl('RansomwareDashboard.RansomwareProtectionDashboard'),
      subtitle: intl('Help.Desc.RansomwareProtectionDashboard'),
    },
    {
      title: intl('Common.VenDashboard'),
      subtitle: intl('Help.Desc.VENDashboard'),
    },
    {
      title: intl('Dashboard.RefreshDashboard'),
      subtitle: intl('Help.Desc.RefreshDashboard', undefined, {html: true}),
      noLink: true,
    },
    {
      title: intl('Common.NewUI'),
      subtitle: intl('Help.Desc.NewUI', undefined, {html: true}),
    },
    {
      title: intl('Health.PCEHealth'),
      subtitle: intl('Help.Desc.PCEHealth'),
    },
    {
      title: intl('Provision.Settings'),
      subtitle: intl('Help.Desc.Provisioning'),
    },
    {
      title: intl('Common.UserProfile'),
      subtitle: intl('Help.Desc.UserProfile'),
      noLink: true,
    },
  ],
}));
