/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {combineReducers} from 'redux';
import {produce} from 'immer';
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import {getAppGroupLabelTypes, getIsCSFrame, getRouteName, getRouteParams} from 'containers/App/AppState';
import {getUserId} from 'containers/User/UserState';
import FilterBarReducer from './Filter/MapFilterState';
import MapGraphState, {getOpenCombos, getShowAllMembers} from './Graph/MapGraphState';
import MapPanelState from './Panels/MapPanelState';
import {calculatePanelType} from './Panels/MapPanelUtils';
import {
  calculateFilteredLinks,
  calculateFilteredTableLinks,
  calculateQuickFilteredLinks,
} from './Utils/MapSelectionUtils';
import {calculateComboLinks} from './Graph/Utils/MapGraphLinkUtils';
import {
  calculateGraphType,
  decodeRouteParams,
  getAppGroupComboId,
  getPolicyItem,
  getUnmanagedEndpointsAddresses,
} from './MapUtils';
import {getAppGroupLabels} from '../AppGroups/AppGroupState';
import {
  combineSameLinks,
  getAggregatedLinks,
  getCombinedGroupingKeys,
  getFullIP,
  getParsedIndividualLinks,
} from './Utils/MapTrafficQueryResponseUtils';
import {
  calculateClosedCombos,
  calculateClosedCombosForFocusedView,
  calculateComboIds,
  calculateCombos,
} from './Graph/Utils/MapGraphComboUtils';
import {
  calculateAutoGrouping,
  calculateLabelCounts,
  defaultAutoGrouping,
  defaultFocusedGrouping,
  defaultMapFilters,
} from './ToolBar/Grouping/MapGroupingUtils';
import {calculateGraphItemRules, calculateGraphItems} from './Graph/Utils/MapGraphUtils';
import {hrefUtils, webStorageUtils} from '@illumio-shared/utils';
import {getLabelSetting} from 'containers/Label/LabelSettings/LabelSettingState';
import {isUnmanagedEndpoint} from './MapTypes';
import {getDefaultPolicyVersion, getUserSettings} from 'containers/User/Settings/SettingsState';
import {
  computeTimeMachineData,
  getTimeMachineProgressState,
} from 'containers/IlluminationMap/ToolBar/TimeMachine/MapTimeMachineUtils';
import {
  getFirstLevelLabelMap,
  getFirstLevelUnmanagedMap,
  getParallelCoordinatesLinks,
  getTicksForParallelCoordinates,
} from 'containers/IlluminationMap/ParallelCoordinates/MapParallelCoordinatesDataUtils';
import {RESULT_ACTIONS_STATUS} from './MapActions';
import {
  getPaginationDetails,
  getPaginationProgress,
} from 'containers/IlluminationMap/ToolBar/Pagination/MapPaginationUtils';
import {getDefaultFilters, getSettingsFromFilters} from 'containers/IlluminationMap/Filter/MapFilterUtils';
import {mapQueryNamePrefix} from 'containers/IlluminationMap/Filter/MapFilterConstants';
import {connectionQuickFilterOptionValues} from 'containers/IlluminationMap/ToolBar/QuickFilter/MapQuickFilter';
import {calculateComboLinksWithVulnerabilities} from 'containers/IlluminationMap/Utils/MapVulnerabilityUtils';

export default {
  map: combineReducers({
    links(state = [], action) {
      switch (action.type) {
        case 'EXPLORER_GET_LINKS':
          return action.data;
        case 'EXPLORER_CLEAR_LINKS':
          return [];
        default:
          return state;
      }
    },

    matchingFlowsCount(state = 0, action) {
      switch (action.type) {
        case 'EXPLORER_LINK_MATCHING_COUNT':
          return action.data;
        default:
          return state;
      }
    },

    rules(state = [], action) {
      switch (action.type) {
        case 'RULE_COVERAGE_GET':
          return [...state, {rules: action.data, linksForRules: action.links}];
        case 'EXPLORER_GET_LINKS':
          return [];
        default:
          return state;
      }
    },

    excludedIpRange(state = null, action) {
      switch (action.type) {
        case 'EXPLORER_DEFAULT_EXCLUDED_IPRANGE':
          return action.data;
        default:
          return state;
      }
    },

    excludedService(state = null, action) {
      switch (action.type) {
        case 'EXPLORER_DEFAULT_EXCLUDED_SERVICE':
          return action.data;
        default:
          return state;
      }
    },

    dnslookup(state = {}, action) {
      switch (action.type) {
        case 'DNS_REVERSE_LOOKUP':
          return {...state, ...action.data};
        default:
          return state;
      }
    },

    dnsLookupStatus(state = [], action) {
      switch (action.type) {
        case 'DNS_LOOKUP_STATUS':
          return action.data;
        default:
          return state;
      }
    },

    subLinks(state = [], action) {
      switch (action.type) {
        case 'EXPLORER_SET_SUBLINKS':
          return action.data;
        default:
          return state;
      }
    },

    linksLoading(state = {done: 'new'}, action) {
      switch (action.type) {
        case 'EXPLORER_SET_LINKS_LOADING':
          return action.data;
        case 'EXPLORER_ERROR':
          return {done: 'error'};
        default:
          return state;
      }
    },

    rulesLoading(state = false, action) {
      switch (action.type) {
        case 'EXPLORER_SET_RULES_LOADING':
          return action.data;
        default:
          return state;
      }
    },

    showAllEnabled(state = false, action) {
      switch (action.type) {
        case 'MAP_SET_ENABLE_SHOW_ALL_MEMBERS':
          return action.data;
        default:
          return state;
      }
    },

    managedEndpoints(state = [], action) {
      switch (action.type) {
        case 'MAP_SET_MANAGED_ENDPOINTS':
          return action.data;
        default:
          return state;
      }
    },

    selectedItems(state = {}, action) {
      switch (action.type) {
        case 'SET_SELECTED_ITEMS':
          return action.data;
        case 'MAP_SET_GRAPH_STATE':
          return action.data?.selection || state;
        case 'MAP_CLEAR_GRAPH_STATE':
          return {};
        default:
          return state;
      }
    },

    selectedGraphNode(state = {}, action) {
      switch (action.type) {
        case 'SET_GRAPH_SELECTED_NODE':
          return action.data;
        case 'MAP_CLEAR_GRAPH_STATE':
          return {};
        default:
          return state;
      }
    },

    selectedTableLinks(state = webStorageUtils.getItem('selectedTableLinks', {session: true}) || new Set(), action) {
      switch (action.type) {
        case 'MAP_TABLE_SET_SELECTED_LINKS':
          webStorageUtils.setItem('selectedTableLinks', action.data, {session: true});

          return action.data;
        default:
          return state;
      }
    },

    selectedLinksForModalTable(state = [], action) {
      switch (action.type) {
        case 'MAP_SELECTED_LINKS_TABLE_MODAL':
          return action.data;
        default:
          return state;
      }
    },

    selectedLinksAfterTickClick(state = [], action) {
      switch (action.type) {
        case 'MAP_SELECTED_LINKS_AFTER_TICK_CLICK':
          return action.data;
        default:
          return state;
      }
    },

    expandedUnmanagedList(state = {}, action) {
      switch (action.type) {
        case 'SET_EXPANDED_UNMANAGEDLIST':
          return action.data;
        default:
          return state;
      }
    },

    serviceMap(state = {}, action) {
      switch (action.type) {
        case 'SET_SERVICE_MAP':
          return action.data;
        default:
          return state;
      }
    },

    serviceMatches(state = {}, action) {
      switch (action.type) {
        case 'SET_MATCHED_SERVICES':
          return action.data;
        default:
          return state;
      }
    },

    fetchedPolicyItemTraffic(state = null, action) {
      switch (action.type) {
        case 'FETCHED_POLICY_ITEM_TRAFFIC':
          return action.id;
        default:
          return state;
      }
    },

    results(state = [], action) {
      switch (action.type) {
        case 'EXPLORER_GET_RESULTS':
          return action.data;
        case 'EXPLORER_DELETE_RESULT_COMPLETE':
          return state.filter(({href}) => hrefUtils.getId(href) !== action.data);
        default:
          return state;
      }
    },

    resultsLoaded(state = false, action) {
      switch (action.type) {
        case 'EXPLORER_GET_RESULTS':
          return true;
        default:
          return state;
      }
    },

    resultsData(state = {}, action) {
      switch (action.type) {
        case 'EXPLORER_CALC_RESULT_DATA':
          return action.data;
        default:
          return state;
      }
    },

    landingQuery(state = {}, action) {
      switch (action.type) {
        case 'LANDING_QUERY_SET':
          return action.data;
        default:
          return state;
      }
    },

    timeMachineTimeRange(state = {start: undefined, end: undefined}, action) {
      switch (action.type) {
        case 'MAP_TIME_RANGE_CHANGE':
          return action.data.range;
        default:
          return state;
      }
    },

    resultsStatus(state = {}, action) {
      const {type, data} = action;
      const status = RESULT_ACTIONS_STATUS[type];

      if (!data?.key || !status) {
        return state;
      }

      const {key, error, params} = data;

      switch (type) {
        case 'EXPLORER_TRAFFIC_QUERY_INIT':
        case 'EXPLORER_TRAFFIC_DOWNLOAD_INIT':
          return {
            ...state,
            [key]: {...data, status, previous: state[key]},
          };
        case 'EXPLORER_TRAFFIC_QUERY_SUCCESS':
        case 'EXPLORER_TRAFFIC_QUERY_ERROR':
        case 'EXPLORER_TRAFFIC_DOWNLOAD_LOADING':
        case 'EXPLORER_TRAFFIC_DOWNLOAD_LOADED':
        case 'EXPLORER_TRAFFIC_DOWNLOAD_PROCESSING':
        case 'MAP_TIME_RANGE_CHANGE':
        case 'EXPLORER_TRAFFIC_DOWNLOAD_READY':
        case 'EXPLORER_TRAFFIC_DOWNLOAD_ERROR':
        case 'MAP_GRAPH_CHART_UPDATE_COMPLETE':
          // resultsStatus should only update on MAP_GRAPH_CHART_UPDATE_COMPLETE if it was processing
          if (
            type === 'MAP_GRAPH_CHART_UPDATE_COMPLETE' &&
            state[key]?.status !== 'download-processing' &&
            state[key]?.status !== 'processing'
          ) {
            return state;
          }

          return {
            ...state,
            [key]: {
              ...state[key],
              status,
              previous: state[key],
              ...(params ? {params} : {}),
              ...(error ? {error} : {}),
            },
          };
        case 'EXPLORER_TRAFFIC_QUERY_CLEANUP':
        case 'EXPLORER_TRAFFIC_DOWNLOAD_CLEANUP':
          if (key === 'all') {
            return {};
          }

          return {...state, [key]: undefined};
        default:
          return state;
      }
    },
    rulesCalculateStatus(state = webStorageUtils.getItem('rulesCalculateStatus') || {}, action) {
      const {type} = action;

      if (
        type !== 'EXPLORER_CALC_RULES_INIT' &&
        type !== 'EXPLORER_CALC_RULES_BACKGROUND' &&
        type !== 'EXPLORER_CALC_RULES_EXPORT' &&
        type !== 'EXPLORER_CALC_RULES_ADD_NEXT' &&
        type !== 'EXPLORER_CALC_RULES_REMOVE_NEXT' &&
        type !== 'EXPLORER_CALC_RULES_COMPLETE' &&
        type !== 'EXPLORER_REFRESH_RULES' &&
        type !== 'EXPLORER_CALC_RULES_BACKGROUND_ALL' &&
        type !== 'EXPLORER_ERROR'
      ) {
        return state;
      }

      const {data} = action;

      if (!data?.key) {
        return state;
      }

      const {key, range, background, mode} = data;

      const newState = produce(state, draft => {
        draft[key] ||= {};

        switch (type) {
          case 'EXPLORER_CALC_RULES_INIT':
            draft[key].inProgress = range;
            draft[key].mode = mode;
            break;
          case 'EXPLORER_CALC_RULES_BACKGROUND':
            draft[key].background = background;
            break;
          case 'EXPLORER_CALC_RULES_BACKGROUND_ALL':
            Object.keys(draft).forEach(key => {
              if (draft[key].inProgress?.limit) {
                draft[key].background = true;
              }
            });
            break;
          case 'EXPLORER_CALC_RULES_EXPORT':
            draft[key].exportFlows = data?.exportFlows;
            break;
          case 'EXPLORER_CALC_RULES_ADD_NEXT':
            draft[key].next ??= [];
            draft[key].next.concat(range);
            break;
          case 'EXPLORER_CALC_RULES_REMOVE_NEXT':
            const removeNext = state[key]?.next;

            draft[key].next = removeNext?.length ? removeNext.slice(1) : [];
            break;
          case 'EXPLORER_CALC_RULES_COMPLETE':
            const inProgress = state[key]?.inProgress;

            draft[key].inProgress = {min: 0, max: 0};
            draft[key].background = false;
            draft[key].exportFlows = false;

            draft[key].complete ??= [];

            if (!draft[key].inProgressOld) {
              draft[key].complete.push(inProgress);
            }

            draft[key].inProgressOld = false;
            break;
          case 'EXPLORER_REFRESH_RULES':
            draft[key] = {
              inProgress: draft[key].inProgress,
              mode: draft[key].mode,
              inProgressOld: draft[key].inProgress.max,
            };
            break;
          case 'EXPLORER_ERROR':
            draft[key].inProgress = {min: 0, max: 0};
            break;
          default:
            return;
        }

        if (key && draft[key]) {
          draft[key].timestamp = Date.now();

          const lastWeek = Date.now() - intl.utils.WEEK;

          for (const [item, status] of Object.entries(draft)) {
            if (status.timestamp < lastWeek) {
              delete draft[item];
            }
          }
        }
      });

      webStorageUtils.setItem('rulesCalculateStatus', newState);

      return newState;
    },

    // User Grouping settings
    groupingSettings(state = defaultAutoGrouping, action) {
      switch (action.type) {
        case 'MAP_SET_AUTO_GROUPING_SETTINGS':
          if (_.isEmpty(action.data)) {
            return state;
          }

          return action.data;
        default:
          return state;
      }
    },

    focusedGrouping(state = defaultFocusedGrouping, action) {
      switch (action.type) {
        case 'MAP_SET_FOCUSED_GROUPING':
          return action.data;
        default:
          return state;
      }
    },

    // User Common Set Of Labels
    commonSetOfLabels(state = null, action) {
      switch (action.type) {
        case 'MAP_SET_COMMON_SET_OF_LABELS':
          return action.data?.commonLabels || [];
        default:
          return state;
      }
    },

    defaultGrouping(state = null, action) {
      switch (action.type) {
        case 'MAP_SET_DEFAULT_GROUPING':
          return action.data;
        default:
          return state;
      }
    },

    // Grouping for a loaded graph
    graphAutoGrouping(state = null, action) {
      switch (action.type) {
        case 'MAP_SET_GRAPH_STATE':
          return action.data?.autoGrouping || state;
        case 'MAP_SET_AUTO_GROUPING_SETTINGS':
          return null;
        case 'MAP_CLEAR_GRAPH_STATE':
          return null;
        default:
          return state;
      }
    },

    autoGroupingSettingsLoaded(state = false, action) {
      switch (action.type) {
        case 'MAP_SET_AUTO_GROUPING_SETTINGS':
          return true;
        default:
          return state;
      }
    },

    storageMetrics(state = {}, action) {
      switch (action.type) {
        case 'TRAFFIC_DB_STORAGE_METRICS':
          return action.data;
        default:
          return state;
      }
    },

    clickedTicks(state = {source: [], target: []}, action) {
      switch (action.type) {
        case 'MAP_SET_CLICKED_TICKS':
          return action.data;
        case 'MAP_SET_AUTO_GROUPING_SETTINGS':
          return {source: [], target: []};
        default:
          return state;
      }
    },

    // Filters
    filters(state = getDefaultFilters({}) || defaultMapFilters, action) {
      switch (action.type) {
        case 'MAP_SET_FILTER':
          return action.data;
        default:
          return state;
      }
    },

    lastPolicy(state = {}, action) {
      switch (action.type) {
        case 'LAST_POLICY':
          return action.data;
        default:
          return state;
      }
    },

    route(state = webStorageUtils.getItem('mapRouteParams', {session: true}) ?? null, action) {
      switch (action.type) {
        case 'MAP_SET_ROUTE_STATE':
          return action.data;
        default:
          return state;
      }
    },

    basic(state = getSettingsFromFilters({})?.basicMode || false, action) {
      switch (action.type) {
        case 'MAP_SET_BASIC_MODE':
          return action.data;
        default:
          return state;
      }
    },

    quickFilters(
      state = new Set(webStorageUtils.getItem('mapQuickFilters') ?? connectionQuickFilterOptionValues),
      action,
    ) {
      switch (action.type) {
        case 'MAP_SET_QUICK_FILTERS':
          webStorageUtils.setItem('mapQuickFilters', [...action.data]);

          return action.data;
        default:
          return state;
      }
    },

    unFilteredQuickFilters(
      state = new Set(webStorageUtils.getItem('mapFilteredQuickFilters') ?? connectionQuickFilterOptionValues),
      action,
    ) {
      switch (action.type) {
        case 'MAP_SET_UNFILTERED_QUICK_FILTERS':
          webStorageUtils.setItem('mapFilteredQuickFilters', [...action.data]);

          return action.data;
        default:
          return state;
      }
    },

    vulnerabilityItemsLoading(state = new Set(), action) {
      switch (action.type) {
        case 'MAP_AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_LOADING':
        case 'MAP_DETECTED_VULNERABILITIES_GET_COLLECTION_LOADING':
          return {...state, [action.key]: true};
        case 'MAP_AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS':
        case 'MAP_DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS':
          return {..._.omit(state, action.key)};
        default:
          return state;
      }
    },

    detectedVulnerabilities(state = {}, action) {
      switch (action.type) {
        case 'MAP_AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS':
        case 'MAP_DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS':
          return {...state, [action.key]: action.data};
        default:
          return state;
      }
    },

    ...FilterBarReducer,
    ...MapGraphState,
    ...MapPanelState,
  }),
};

export const getLinks = state => state.map.links;
export const getManagedEndpoints = state => state.map.managedEndpoints;
export const getShowAllEnabled = state => state.map.showAllEnabled;
export const getSubLinks = state => state.map.subLinks;
export const getRules = state => state.map.rules;
export const getExcludedIpRange = state => state.map.excludedIpRange;
export const getExcludedService = state => state.map.excludedService;
export const getDnsReverseLookup = state => state.map.dnslookup;
export const getDnsLookupStatus = state => state.map.dnsLookupStatus;
export const getSelectedItems = state => state.map.selectedItems;
export const getSelectedGraphNode = state => state.map.selectedGraphNode;
export const getSelectedTableLinks = state => state.map.selectedTableLinks;
export const getSelectedLinksForModalTable = state => state.map.selectedLinksForModalTable;
export const getLinksAfterTickClick = state => state.map.selectedLinksAfterTickClick;
export const getLinksLoading = state => state.map.linksLoading;
export const getRulesLoading = state => state.map.rulesLoading;
export const getSelectedUnmanagedEndpoint = state => getSelectedItems(state)?.unmanaged?.[0];
export const getExpandedUnmanagedList = state => state.map.expandedUnmanagedList.expandUnmanagedList;
export const getExpandedHref = state => state.map.expandedUnmanagedList.href;
export const getExpandedDirection = state => state.map.expandedUnmanagedList.direction;
export const getSelectedLinks = state => state.map.selectedLinks;
export const getAnyIpList = state => state.map.anyIpList;
export const getServiceMap = state => state.map.serviceMap;
export const getMatchedServices = state => state.map.serviceMatches;
export const getResults = state => state.map.results;
export const getResultsLoaded = state => state.map.resultsLoaded;
export const getResultsData = state => state.map.resultsData;
export const getFetchedPolicyItemTraffic = state => state.map.fetchedPolicyItemTraffic;
export const getResultsStatus = state => state.map.resultsStatus;
export const getTimeMachineTimeRange = state => state.map.timeMachineTimeRange;
export const getRuleCalculationStatus = state => state.map.rulesCalculateStatus;
export const getGraphAutoGrouping = state => state.map.graphAutoGrouping;
export const getGroupingSettings = state => state.map.groupingSettings;
export const getDefaultGrouping = state => state.map.defaultGrouping;
export const getFocusedGrouping = state => state.map.focusedGrouping;
export const getCommonSetOfLabels = state => state.map.commonSetOfLabels;
export const getAutoGroupingSettingsLoaded = state => state.map.autoGroupingSettingsLoaded;
export const getStorageMetrics = state => state.map.storageMetrics;
export const getClickedTicks = state => state.map.clickedTicks;
export const getLandingQuery = state => state.map.landingQuery;
export const getMapFilters = state => state.map.filters;
export const getLastPolicy = state => state.map.lastPolicy;
export const getBasic = state => state.map.basic;
export const getLastMapRouteParams = state => state.map?.route;
export const getMatchingFlowsCount = state => state.map?.matchingFlowsCount;
export const getQuickFilters = state => state.map?.quickFilters;
export const getUnfilteredQuickFilters = state => state.map?.unFilteredQuickFilters;
export const getDetectedVulnerabilities = state => state.map?.detectedVulnerabilities;
export const getVulnerabilityItemsLoading = state => state.map?.vulnerabilityItemsLoading;

export const getMapRouteParams = createSelector([getRouteName, getRouteParams], (routeName, routeParams) => {
  const decodedRouteParams = decodeRouteParams(routeParams);

  const policyId = decodedRouteParams?.id;
  const policyItem = getPolicyItem(routeName);

  const defaultDisplay = webStorageUtils.getItem('illuminationplus-display', {parse: false});
  const params = {...decodedRouteParams};
  const displayValue = decodedRouteParams.display || defaultDisplay || 'table';

  if (decodedRouteParams.display === 'map' || decodedRouteParams.display === 'table') {
    delete params.viewTable;
  }

  return {...params, policyId, policyItem, display: displayValue};
});

export const getDownloadState = createSelector(
  [getMapRouteParams, getResultsStatus],
  (routeParams, statuses) => statuses[routeParams.query],
);

export const getPolicyVersion = createSelector(
  [getMapRouteParams, getDefaultPolicyVersion, getIsCSFrame],
  (routeParams, defaultPolicy, isCSFrame) => {
    if (isCSFrame) {
      return 'draft';
    }

    if (routeParams?.policyItem === 'denyRules') {
      return routeParams.policy || 'draft';
    }

    return routeParams.policy || defaultPolicy || 'reported';
  },
);

export const getMinMaxSequenceNumbers = createSelector([getLinks], links => {
  return {
    min: links.length ? links[0].seq_id : 0,
    max: links.length ? links.at(-1).seq_id + 1 : 0,
  };
});

export const getCommonLabelsSetting = createSelector(
  [getLabelSetting, getCommonSetOfLabels],
  (labelTypes, commonLabels) => {
    return labelTypes.filter(label => commonLabels?.includes(label.key));
  },
);

export const getParsedLinks = createSelector(
  [getLinks, getAppGroupLabelTypes, getMatchedServices, getIsCSFrame],
  (links, appGroupTypes, serviceMatches, isCSFrame) => {
    const parsedLinks = getParsedIndividualLinks(links, appGroupTypes, serviceMatches, isCSFrame);

    return parsedLinks;
  },
);

export const getLinksWithRules = createSelector([getParsedLinks], parsedLinks => {
  return parsedLinks;
});

export const getLabelCounts = createSelector([getParsedLinks], links => calculateLabelCounts(links));

export const getDefaultGraphName = createSelector(
  [getUserId],
  userId => `${mapQueryNamePrefix}${intl('IlluminationMap.DefaultGraph')}-${userId}`,
);

export const getDefaultGraphResults = createSelector([getDefaultGraphName, getResults], (defaultGraphName, results) => {
  const defaultResults = results
    .filter(result =>
      result.queryParameters?.query_name.startsWith(`${mapQueryNamePrefix}${intl('IlluminationMap.DefaultGraph')}`),
    )
    .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0));

  return defaultResults.length ? defaultResults[0].queryId : null;
});

export const getAutoGrouping = createSelector(
  [getGroupingSettings, getCommonLabelsSetting],
  (autoGroupingSettings, labelTypes) => {
    const settings = autoGroupingSettings;
    const labelKeys = labelTypes.map(type => type.key);

    labelKeys.push('appGroup');

    return {
      ...settings,
      groupingOrder: (settings.groupingOrder || []).filter(grouping => labelKeys.includes(grouping)),
    };
  },
);

export const getBasicMode = createSelector([getBasic], basic => basic);

export const getGrouping = createSelector(
  [getLabelCounts, getAutoGrouping, getFocusedGrouping, getMapRouteParams, getCommonLabelsSetting, getBasicMode],
  (counts, groupingSettings, focusedGrouping, routeParams, labelTypes, basicMode) => {
    if (routeParams.policyItem === 'appGroups') {
      return [focusedGrouping, 'appGroup'];
    }

    const labelKeys = labelTypes.map(type => type.key);

    if (!basicMode) {
      labelKeys.push('appGroup', 'nodes');
    }

    if (routeParams.grouping) {
      return routeParams.grouping.filter(grouping => labelKeys.includes(grouping));
    }

    const grouping = calculateAutoGrouping(counts, groupingSettings);

    return (grouping || []).filter(grouping => labelKeys.includes(grouping));
  },
);

export const getFocusedGraphData = createSelector(
  [getMapRouteParams, getAppGroupLabels],
  (routeParams, appGroupLabels) => {
    let focusedComboId;
    let openComboId;

    if (routeParams.policyItem === 'appGroups') {
      focusedComboId = getAppGroupComboId(routeParams.policyId, appGroupLabels);
    }

    if (routeParams.consuming) {
      openComboId = `${getAppGroupComboId(routeParams.consuming, appGroupLabels)}_source`;
    }

    if (routeParams.providing) {
      openComboId = `${getAppGroupComboId(routeParams.providing, appGroupLabels)}_target`;
    }

    return {focusedComboId, openComboId};
  },
);

export const getLinksFilteredByRules = createSelector(
  [getParsedLinks, getMapRouteParams, getIsCSFrame],
  (links, {policyFilter, policyItem}, isCSFrame) => {
    if (policyFilter) {
      if (isCSFrame) {
        return links.filter(link => link.policy.reported.decision !== 'unknown');
      }
    }

    if (policyItem === 'denyRules') {
      return links.filter(link => !(link.source.type === 'virtualService' || link.target.type === 'virtualService'));
    }

    return links;
  },
);

export const getLinksWithDNS = createSelector([getLinksFilteredByRules, getDnsReverseLookup], (links, dnsLookup) => {
  return links.map(link => {
    let {source, target} = link;

    if (isUnmanagedEndpoint(source) && source.details.fqdn === undefined && dnsLookup[source.ip]) {
      source = {
        ...source,
        fullIp: getFullIP(source.ip, dnsLookup[source.ip]),
        details: {...source.details, fqdn: dnsLookup[source.ip]},
      };
    }

    if (isUnmanagedEndpoint(target) && target.details.fqdn === undefined && dnsLookup[target.ip]) {
      target = {
        ...target,
        fullIp: getFullIP(target.ip, dnsLookup[target.ip]),
        details: {...target.details, fqdn: dnsLookup[target.ip]},
      };
    }

    return {...link, source, target};
  });
});

export const getQuickFilteredLinks = createSelector(
  [
    getLinksWithDNS,
    getSelectedGraphNode,
    getSelectedLinksForModalTable,
    getMapRouteParams,
    getQuickFilters,
    getPolicyVersion,
  ],
  (links, filters, selectedLinkKeysforModal, routeParams, connectionDirections, policyVersion) => {
    const filteredLinks =
      routeParams.viewTable && selectedLinkKeysforModal.length !== 0
        ? links
        : calculateQuickFilteredLinks(links, filters, connectionDirections, policyVersion);

    return filteredLinks;
  },
);

export const getFilteredLinks = createSelector(
  [
    getQuickFilteredLinks,
    getSelectedItems,
    getFocusedGraphData,
    getSelectedLinksForModalTable,
    getMapRouteParams,
    getQuickFilters,
  ],
  (links, filters, focusedData, selectedLinkKeysforModal, routeParams, connectionDirections) => {
    const filteredLinks =
      routeParams.viewTable && selectedLinkKeysforModal.length !== 0
        ? calculateFilteredTableLinks(links, selectedLinkKeysforModal)
        : calculateFilteredLinks(links, filters, focusedData, connectionDirections);

    return filteredLinks;
  },
);

export const getUnmanagedEndpoints = createSelector([getFilteredLinks, getDnsReverseLookup], (links, dnsLookup) => {
  return getUnmanagedEndpointsAddresses(links, dnsLookup);
});

export const getAggregateLinksWithRules = createSelector(
  [getFilteredLinks, getIsCSFrame, getGrouping, getAppGroupLabelTypes, getCommonSetOfLabels],
  (filteredLinks, isCSFrame, grouping, appGroupLabelTypes, commonLabels) => {
    const links = isCSFrame ? filteredLinks : combineSameLinks(filteredLinks);
    const allGroupings = getCombinedGroupingKeys(grouping, appGroupLabelTypes, commonLabels);

    return getAggregatedLinks(links, allGroupings);
  },
);

export const getGraphType = createSelector([getMapRouteParams], routeParams => {
  return calculateGraphType(routeParams);
});

export const getItems = createSelector(
  [
    getLinksWithDNS,
    getQuickFilteredLinks,
    getCommonLabelsSetting,
    getFocusedGraphData,
    getGraphType,
    getGrouping,
    getAppGroupLabelTypes,
    getCommonSetOfLabels,
    getManagedEndpoints,
    getShowAllMembers,
  ],
  (
    links,
    filteredLinks,
    labelTypes,
    {focusedComboId},
    viewType,
    grouping,
    appGroupLabelTypes,
    commonLabels,
    managedEndpoints,
    showAllMembers,
  ) => {
    return calculateGraphItems(
      combineSameLinks(links),
      combineSameLinks(filteredLinks),
      labelTypes,
      viewType,
      focusedComboId,
      grouping,
      appGroupLabelTypes,
      commonLabels,
      managedEndpoints,
      showAllMembers,
    );
  },
);

export const getResult = createSelector([getResults, getMapRouteParams], (results, {query: queryId}) =>
  queryId ? results.find(result => result.queryId === queryId) : undefined,
);

export const getItemsWithRules = createSelector([getItems, getLinksFilteredByRules], (items, links) => {
  return calculateGraphItemRules(items, links);
});

export const getCombos = createSelector(
  [getItemsWithRules, getMapRouteParams, getFocusedGraphData, getCommonLabelsSetting, getGraphType, getGrouping],
  (items, routeParams, {focusedComboId, openComboId}, labelTypes, viewType, grouping) => {
    return calculateCombos(items, grouping, labelTypes, viewType, focusedComboId, openComboId);
  },
);

export const getComboIds = createSelector([getCombos], combos => calculateComboIds(combos.combos));

export const getClosedCombos = createSelector(
  [getCombos, getComboIds, getOpenCombos, getMapRouteParams, getFocusedGraphData, getCommonLabelsSetting, getGrouping],
  (combos, comboIds, openCombos, routeParams, {focusedComboId, openComboId}, labelTypes, grouping) => {
    return focusedComboId
      ? calculateClosedCombosForFocusedView(combos, comboIds, openCombos, grouping, focusedComboId, openComboId)
      : calculateClosedCombos(combos, comboIds, openCombos, grouping, labelTypes);
  },
);

export const getComboLinks = createSelector(
  [getItemsWithRules, getCombos, getClosedCombos],
  (items, combos, closedCombos) => calculateComboLinks(items.links, combos, closedCombos, 'closed'),
);

export const getComboLinksWithVulnerabilities = createSelector(
  [getComboLinks, getDetectedVulnerabilities],
  (comboLinks, detectedVulnerabilities) => {
    return calculateComboLinksWithVulnerabilities(comboLinks, detectedVulnerabilities);
  },
);

export const getComboLinksForOpenCombos = createSelector(
  [getItemsWithRules, getCombos, getClosedCombos],
  (items, combos, closedCombos) => calculateComboLinks(items.links, combos, closedCombos, 'open'),
);

export const getSelectedCombo = createSelector([getSelectedItems, getCombos], (filters, {combos}) => {
  const selectedComboId = filters.combo?.length && filters.combo[0];

  return combos[selectedComboId] || {};
});

export const getSelectedComboWorkloads = createSelector([getSelectedCombo], selectedCombo => {
  const {endpoints} = selectedCombo;

  return Object.values(endpoints?.workload || {});
});

export const getSelectedSuperAppGroups = createSelector([getCombos, getFocusedGraphData], (combos, {openComboId}) => {
  const superAppGroupSource = '_superAppGroups_source';
  const superAppGroupTarget = '_superAppGroups_target';

  if (openComboId) {
    const superAppGroupCombo = openComboId.includes('source')
      ? combos.combos[superAppGroupSource]
      : combos.combos[superAppGroupTarget];

    return superAppGroupCombo;
  }

  return null;
});

export const getResultStatus = createSelector(
  [getResultsStatus, getMapRouteParams],
  (resultsStatus, {query}) => resultsStatus[query],
);

export const getPaginationData = createSelector(
  [getUserSettings, getMapRouteParams, getResult, getResultStatus, getMatchingFlowsCount],
  (
    {explorerMaxResults: maxResults, explorerMaxDownloadResults: maxDownloadResults},
    {offset},
    result,
    resultStatus,
    flowsCount,
  ) => {
    const details = getPaginationDetails({result, offset, maxResults, maxDownloadResults, flowsCount});
    const progress = getPaginationProgress({resultStatus, currentPage: details.currentPage});

    return {
      details,
      progress,
      maxResults,
    };
  },
);

export const getExceededMaxResultsLimit = createSelector([getPaginationData], details => {
  return Boolean(details?.details.exceededMaxResultsLimit);
});

export const getTimeMachineData = createSelector(
  [getItems, getResult, getPaginationData, getResultStatus, getTimeMachineTimeRange],
  (items, result, pagination, resultStatus) => ({
    ...computeTimeMachineData(items),
    result,
    resultStatus,
    progress: getTimeMachineProgressState({resultStatus}),
  }),
);

export const getPanelType = createSelector(
  [getSelectedItems, getCombos, getMapRouteParams],
  (selection, comboMapping, routeParams) =>
    routeParams.rulesTab ? 'proposedRules' : calculatePanelType(selection, comboMapping.combos),
);

export const getFirstLevelPCLabels = createSelector([getCommonLabelsSetting, getItems], (labels, items) => {
  return {...getFirstLevelLabelMap(labels), ...getFirstLevelUnmanagedMap(items.unmanagedEndpoints)};
});

export const getPCData = createSelector(
  [getClickedTicks, getCombos, getParsedLinks, getItems, getGrouping],
  (clickedTicks, combos, parsedLinks, items, grouping) => {
    const ticks = getTicksForParallelCoordinates(items, combos, grouping, clickedTicks);
    const pcLinks = getParallelCoordinatesLinks(ticks, combos.combos, parsedLinks, clickedTicks, items);

    return Object.values(pcLinks);
  },
);

export const getFilters = createSelector([getMapFilters], filters => {
  return filters;
});

export const getMapMode = createSelector([getRouteParams], routeParams => {
  return routeParams.mapMode === 'vulnerability' ? 'vulnerability' : 'policy';
});
