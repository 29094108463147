/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import styles from './Selectors.css';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import Option from './Option';
import {getPluralObjectName} from './SelectorUtils';

// Escape Regex http://stackoverflow.com/questions/3115150/#answer-9310752
const ESCAPE = str => str.replaceAll(/[\s#$()*+,.?[\\\]^{|}-]/g, '\\$&');

export default class OptionContainer extends PureComponent {
  static propTypes = {
    category: PropTypes.shape({
      value: PropTypes.string,
      categoryKey: PropTypes.string,
    }),
    pageInvokerProps: PropTypes.object,
    customPickers: PropTypes.object,
    disabled: PropTypes.bool,
    values: PropTypes.array,
    total: PropTypes.number,
    input: PropTypes.string,
    inputValue: PropTypes.any,
    items: PropTypes.array,
    checkbox: PropTypes.bool,
    isOpen: PropTypes.bool,
    isOption: PropTypes.bool,
    isPartial: PropTypes.bool,
    loading: PropTypes.bool,
    maxResults: PropTypes.number,
    object: PropTypes.object,
    onCustomPickerOpen: PropTypes.func,
    renderOption: PropTypes.func,
    showContainerTitle: PropTypes.bool,
    showResultsFooter: PropTypes.bool,
    saveRef: PropTypes.func,
    onOpen: PropTypes.func,
    onHover: PropTypes.func,
    onSelect: PropTypes.func,
    onUnselect: PropTypes.func,
    onCreate: PropTypes.func,
  };

  static defaultProps = {
    input: '',
    values: [],
  };

  constructor(props) {
    super(props);

    this.options = [];

    this.getTitle = this.getTitle.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.saveOptionRef = this.saveOptionRef.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  // Example: "Label - 2 of 2 Total"
  getTitle(name, count, total) {
    const {
      category: {freeSearch},
      input,
    } = this.props;

    if (count && !(freeSearch && !input.length)) {
      return intl('ObjectSelector.MatchCount', {name, count, total});
    }

    return name;
  }

  getHintText(name, category, prepopulated) {
    const object = getPluralObjectName(name);

    if (category.freeSearch && (category.validate || prepopulated)) {
      return intl('ObjectSelector.TypeToShowObject', {object});
    }

    if (category.freeSearch) {
      return intl('ObjectSelector.TypeToSearchObject', {object});
    }

    return intl('ObjectSelector.TypeToShowMoreObject', {object});
  }

  // Bold or underline the text match in each string
  getHighlightedText(input, value, bold) {
    if (!input || input.length === 0 || !value) {
      return value;
    }

    const regex = new RegExp(`(${ESCAPE(input)})`, 'i');
    const matches = value.split(regex).map((text, index) => {
      const highlight = text.toLowerCase() === input.toLowerCase();
      const highlightStyle = bold ? styles.bold : styles.underline;

      return (
        <span key={index} className={highlight ? highlightStyle : ''}>
          {text}
        </span>
      );
    });

    return matches;
  }

  saveOptionRef(option) {
    this.props.saveRef(option);
  }

  handleOpen() {
    const {
      values,
      onOpen,
      category: {categoryKey},
    } = this.props;

    if (values) {
      onOpen(categoryKey);
    }
  }

  handleCreate() {
    const {
      input,
      category: {categoryKey},
      onCreate,
    } = this.props;

    if (onCreate) {
      onCreate(input, categoryKey);
    }
  }

  // lift state up to selector when saving new service or ip list in page invoker modal
  handleSave(data) {
    const {
      onSave,
      category: {categoryKey, resource},
    } = this.props;

    onSave(categoryKey, resource, data);
  }

  render() {
    const {
      total,
      values,
      input,
      inputValue,
      items,
      isOpen,
      isOption,
      isPartial,
      category,
      // Use noStartCase for values with multiple capitalized words or values that contain special characters
      category: {value, noStartCase = false, categoryKey, freeSearch},
      customPickers,
      checkbox,
      loading,
      maxResults,
      renderOption,
      showContainerTitle,
      showResultsFooter,
      object,
      onCreate,
      onHover,
      onCustomPickerOpen,
      onSelect,
      onUnselect,
      pageInvokerProps,
      pageInvokerRef,
    } = this.props;

    if (isOpen) {
      let createOption;
      const optionProps = {
        categoryKey,
        checkbox,
        saveRef: this.saveOptionRef,
        onHover,
        onUnselect,
        renderOption,
        pageInvokerRef,
        onSave: this.handleSave,
      };

      const label = noStartCase ? value : _.startCase(value);
      const showHintText = (values.length > maxResults || freeSearch) && input.length === 0;
      const displayedValues = values.slice(0, maxResults);

      const title = showContainerTitle ? (
        <li className={styles.title} data-tid="comp-select-results-header">
          {this.getTitle(label, displayedValues.length, total || values.length)}
        </li>
      ) : null;

      let optionList;

      if (freeSearch && !input.length) {
        optionList = [];
      } else if (freeSearch && input.length && !displayedValues.length) {
        const results = category.validate ? category.validate(categoryKey, input, items) : [{value: input}];

        optionList = results.map((option, i) => (
          <Option key={value + i} value={option} {...optionProps} onSelect={onSelect}>
            {this.getHighlightedText(input, option.value, true)}
          </Option>
        ));
      } else if (displayedValues.length) {
        optionList = displayedValues.map((option, i) => {
          const text = option.name || option.value || option.hostname;
          const selected = items.some(
            item => (option.href && item.href === option.href) || item.name === text || item.value === text,
          );

          return (
            <Option
              key={text + i}
              selected={selected}
              value={option}
              object={object}
              {...optionProps}
              desc={this.getHighlightedText(input, option.desc, true)}
              onSelect={customPickers[text] ? onCustomPickerOpen : onSelect}
            >
              {this.getHighlightedText(input, text, true)}
            </Option>
          );
        });

        if (isPartial && input && !displayedValues.some(value => value.value === input)) {
          const selected = items.some(item => item.name === input || item.value === input);

          optionList.unshift(
            <Option
              key={`${input}partial`}
              className={styles.partial}
              selected={selected}
              value={{value: input}}
              onSelect={onSelect}
              {...optionProps}
            >
              {input}
            </Option>,
          );
        }
      } else if (loading) {
        optionList = <div className={styles.results}>{intl('ObjectSelector.LoadingValues')}</div>;
      } else if (!showResultsFooter) {
        // Only show '0 matching results' in container if there isn't already a results footer
        optionList = [
          <div key="no-matches" className={styles.results}>
            {intl('ObjectSelector.NumMatchingResults', {count: 0})}
          </div>,
        ];

        if (isPartial && input) {
          const selected = items.some(item => item.name === input || item.value === input);

          optionList.unshift(
            <Option
              key={`${input}partial`}
              className={styles.partial}
              selected={selected}
              value={{value: input}}
              onSelect={onSelect}
              {...optionProps}
            >
              {input}
            </Option>,
          );
        }
      }

      const optionsElem = optionList ? (
        <div className={title ? styles.titledOptionList : null}>
          {optionList}
          {showHintText ? (
            <div className={styles.hint} data-tid="comp-select-results-hint">
              {this.getHintText(value, category, displayedValues.length)}
            </div>
          ) : null}
        </div>
      ) : null;

      if (
        onCreate !== undefined &&
        input.length &&
        ![
          intl('Common.AllApplications').toLowerCase(),
          intl('Common.AllEnvironments').toLowerCase(),
          intl('Common.AllLocations').toLowerCase(),
        ].includes(input.toLowerCase())
      ) {
        createOption = (
          <Option isCustom value={inputValue} {...optionProps} onSelect={this.handleCreate}>
            {`${input} (${intl('Common.New')} ${_.upperFirst(value)})`}
          </Option>
        );
      }

      return createOption || optionsElem ? (
        <div className={styles.activeOptionContainer}>
          {title}
          {optionsElem}
          {createOption}
        </div>
      ) : null;
    }

    if (isOption) {
      return (
        <Option
          isCategory
          saveRef={this.saveOptionRef}
          value={category}
          categoryKey={categoryKey}
          onSelect={onSelect}
          onHover={onHover}
          pageInvokerProps={pageInvokerProps}
          pageInvokerRef={pageInvokerRef}
          onSave={this.handleSave}
        >
          {this.getHighlightedText(input, value, false)}
        </Option>
      );
    }

    const categoryProps = {
      disabled: false, // Verified that in no circumstances, should this be disabled.
      categoryKey,
      saveRef: this.saveOptionRef,
      onSelect: this.handleOpen,
      onHover,
      pageInvokerProps,
    };

    return (
      <Option isCategory value={category} {...categoryProps}>
        {this.getHighlightedText(input, value, false)}
      </Option>
    );
  }
}
