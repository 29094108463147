/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import stringify from 'safe-stable-stringify';
import {getTimeRange} from '../Utils/MapTrafficQueryRequestUtils';
import {EmptyFilters, mapQueryNamePrefix, transmissionOptions} from './MapFilterConstants';
import {
  convertFiltersValuesToMap,
  convertFiltersValuesToObject,
  parsePortProtoString,
  parsePortRangeProtoString,
} from 'containers/IlluminationMap/Filter/MapFilterUtils';
import {explorerTimeOptions, getCustomRangeLabel} from 'containers/IlluminationMap/MapUtils';
import {webStorageUtils} from '@illumio-shared/utils';
import {generalUtils} from '@illumio-shared/utils/shared';

const legacyCustomTimeRangePattern = /^from:\s((\d+\/){2}\d+,\s\d+:\d+)\sto:\s((\d+\/){2}\d+,\s\d+:\d+)$/i;

/**
 * Returns 'label_group' if the href is for a label group or 'label' otherwise.
 * @param labelHref
 * @returns {string} (label_group || label)
 */
function getLabelHrefType(labelHref) {
  return /\/label_groups\/[\w-]+$/.test(labelHref) ? 'label_group' : 'label';
}

/**
 Create filter query to using info from info from filter bar.
 */
export const getExplorerQuery = ({filters = {}, filtersSettings = {}, nameTag = mapQueryNamePrefix, name = null}) => {
  const {
    consumerOrProviderInclude = {},
    consumerOrProviderExclude = {},
    servicesInclude = {},
    servicesExclude = {},
    time,
    policyDecisions: reportedPolicyDecisions = {},
    queryName,
  } = filters;
  let {consumerInclude = {}, consumerExclude = {}, providerInclude = {}, providerExclude = {}} = filters;
  const {excludeWorkloadsFromIPListQuery = true} = filtersSettings;

  const orView = !(_.isEmpty(consumerOrProviderInclude) && _.isEmpty(consumerOrProviderExclude));
  const {policyDecisions, boundaryDecisions} = (reportedPolicyDecisions.policy_decisions || []).reduce(
    (result, action) => {
      if (action.value.endsWith('by_boundary')) {
        return {
          boundaryDecisions: ['blocked'],
          policyDecisions: [...result.policyDecisions, action.value.replace(/_by_boundary$/, '')],
        };
      }

      return {
        ...result,
        policyDecisions: [...result.policyDecisions, action.value],
      };
    },
    {policyDecisions: [], boundaryDecisions: []},
  );

  consumerInclude = structuredClone(orView ? consumerOrProviderInclude : consumerInclude);
  consumerExclude = structuredClone(orView ? consumerOrProviderExclude : consumerExclude);
  providerInclude = structuredClone(orView ? consumerOrProviderInclude : providerInclude);
  providerExclude = structuredClone(orView ? consumerOrProviderExclude : providerExclude);

  providerExclude.transmission = getTransmissionExcludes(providerInclude, providerExclude);
  delete providerInclude.transmission;
  delete consumerInclude.transmission;
  delete consumerExclude.transmission;
  delete consumerInclude.fqdn;
  delete consumerExclude.fqdn;

  const {start: startDate, end: endDate} = getTimeRange(time);

  return {
    sources: {
      include: getIncludeQueries(consumerInclude),
      exclude: getExcludeQueries(consumerExclude),
    },
    destinations: {
      include: getIncludeQueries(providerInclude),
      exclude: getExcludeQueries(providerExclude),
    },
    services: {
      include: getServicesIncludeQueries(servicesInclude),
      exclude: getExcludeQueries(servicesExclude),
    },
    sources_destinations_query_op: orView ? 'or' : 'and',
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    policy_decisions: policyDecisions,
    boundary_decisions: boundaryDecisions,
    query_name: `${nameTag}${name || queryName || createDefaultName(filters)}`,
    exclude_workloads_from_ip_list_query: excludeWorkloadsFromIPListQuery,
  };
};

export const convertTimeRangeValuesToDateObjects = time => {
  return time?.value === 'custom' && time?.range
    ? {...time, range: {start: new Date(time.range.start), end: new Date(time.range.end)}}
    : time;
};

export function getAutoSavedFilters(appId) {
  let autoSavedFilters;

  if (appId) {
    autoSavedFilters = webStorageUtils.getItem('appMapFilterState', {parse: true})?.[appId];
  } else {
    autoSavedFilters = webStorageUtils.getItem('mapFilterState', {parse: true});
  }

  if (autoSavedFilters) {
    autoSavedFilters = {
      ...EmptyFilters,
      ...convertFiltersValuesToMap(autoSavedFilters),
    };
    autoSavedFilters.time = convertTimeRangeValuesToDateObjects(autoSavedFilters.time);
  }

  return autoSavedFilters;
}

export function updateAutoSavedFilters(filters, appId) {
  const serialized = convertFiltersValuesToObject(filters);

  if (appId) {
    const appMapFilters = webStorageUtils.getItem('appMapFilterState') || {};

    webStorageUtils.setItem('appMapFilterState', {...appMapFilters, [appId]: serialized}, {stringify: true});
  } else {
    webStorageUtils.setItem('mapFilterState', serialized, {stringify: true});
  }
}

function getFriendlyTime(time) {
  // support legacy format, which stored time as a string.
  if (typeof time === 'string') {
    return time;
  }

  if (time.value === 'custom' && time.range?.start && time.range?.end) {
    return String(getCustomRangeLabel(time.range));
  }

  return time.label ?? '';
}

export function createDefaultName(filters) {
  if (!filters) {
    return intl('Explorer.EmptyFilter');
  }

  const name = Object.entries(filters).reduce((result, [key, value]) => {
    if (_.isEmpty(value) || key === 'uuid' || key === 'dateFrom' || key === 'dateTo') {
      return result;
    }

    result = `${result} ${getFriendlyFilterName(key)}: `;

    if (key === 'time') {
      return `${result} ${getFriendlyTime(value)}`;
    }

    if (key === 'policyDecisions') {
      return `${result} ${(value?.policy_decisions ?? []).map(item => item.label ?? '').join(', ')} `;
    }

    if (key === 'servicesInclude' || key === 'servicesExclude') {
      if (value.policy_services?.length) {
        result += `${getPortProtocolFriendlyName(value.policy_services, key)} `;
      }

      if (value.port_proto?.length) {
        result += `${getPortProtocolFriendlyName(value.port_proto, key)} `;
      }

      if (value.port_range?.length) {
        result += `${getPortProtocolFriendlyName(value.port_range, key)} `;
      }

      if (value.windows_service_name?.length) {
        result += `${getPortProtocolFriendlyName(value.windows_service_name, key)} `;
      }

      if (value.process_name?.length) {
        result += `${getPortProtocolFriendlyName(value.process_name, key)} `;
      }

      return result;
    }

    if (
      key === 'consumerInclude' ||
      key === 'providerInclude' ||
      key === 'consumerOrProviderInclude' ||
      key === 'consumerExclude' ||
      key === 'providerExclude' ||
      key === 'consumerOrProviderExclude'
    ) {
      const items = (Array.isArray(value) ? value : Object.values(value)).flat();
      const filterString = items.map(item => {
        if (!item) {
          return '';
        }

        if (typeof item === 'string') {
          return item;
        }

        if (typeof item.value === 'string' && item.value !== 'all_workloads') {
          return item.value;
        }

        return item.name ?? item.hostname;
      });

      if (filterString) {
        return `${result} ${filterString}`;
      }
    }

    return result;
  }, '');

  return name
    .replaceAll(',', ', ')
    .replaceAll(':', ': ')
    .replaceAll(/\s{2,}/g, ' ')
    .trim();
}

function getFriendlyFilterName(key) {
  switch (key) {
    case 'consumerInclude':
      return intl('Common.Source');
    case 'providerInclude':
      return intl('Common.Destination');
    case 'servicesInclude':
      return intl('Port.PortProtocolProcessService');
    case 'consumerOrProviderInclude':
      return intl('IlluminationMap.ConsumerOrProvider', {not: false});
    case 'consumerExclude':
      return intl('Explorer.ConsumerIsNot');
    case 'providerExclude':
      return intl('Explorer.ProviderIsNot');
    case 'servicesExclude':
      return intl('Port.PortProtocolProcessServiceIsNot');
    case 'consumerOrProviderExclude':
      return intl('IlluminationMap.ConsumerOrProvider', {not: true});
    case 'policyDecisions':
      return intl('Common.ReportedPolicy');
    default:
      return _.startCase(key);
  }
}

function getPortProtocolFriendlyName(filters, key) {
  const name = filters.reduce((result, filter) => {
    if (filter && filter.name) {
      if (key === 'servicesInclude') {
        return `${result} ${filter.name}`;
      }

      return `${result}  ${
        Array.isArray(filter.name)
          ? filter.name
              .reduce((acc, name) => [...acc, Object.values(name).filter(name => name !== null)], [])
              .map(item => item.join(', '))
          : filter.name
      }`;
    }

    if (filter && filter.value && typeof filter.value === 'string') {
      return `${result} ${filter.value}`;
    }

    if (filter && typeof filter === 'string') {
      return `${result} ${filter}`;
    }

    return result;
  }, '');

  return name.replaceAll(',', ', ').trim();
}

/**
 Take an selector result in form  of object and return an array of excluded query
 */
function getExcludeQueries(selectorValues) {
  if (_.isEmpty(selectorValues)) {
    return [];
  }

  return Object.entries(selectorValues).reduce((result, [categoryName, valuesArray]) => {
    /**
     CASE 1: ---> {workload : {href:...}}
     workload
     container_workload
     */
    if (['workload', 'container_workload'].includes(categoryName)) {
      valuesArray.map(value => result.push({workload: {href: value.href}}));

      return result;
    }

    /**
     CASE 2: ---> {ip_list : {href:...}}
     ip_list
     */
    if (categoryName === 'ip_list') {
      valuesArray.map(value => result.push({ip_list: {href: value.href}}));

      return result;
    }

    /**
     CASE 3: ----> {label: {href: 'label href'}} or {label_group: {href: 'label group href'}}
     labelsAndLabelGroups
     */
    if (categoryName === 'labelsAndLabelGroups') {
      valuesArray.map(value => result.push({[getLabelHrefType(value.href)]: {href: value.href}}));

      return result;
    }

    /**
     CASE 4: ----> {[category]: '..(value in string)..'}
     fqdn
     transmission
     process_name
     windows_service_name
     */
    if (['fqdn', 'transmission', 'process_name', 'windows_service_name'].includes(categoryName)) {
      valuesArray.map(value => result.push({[categoryName]: value.value ?? value}));

      return result;
    }

    /**
     CASE 5: ----> {'ip_address': 'ip address or cidr block'}
     ip_address
     cidr_block
     */
    if (categoryName === 'ip_or_cidr') {
      valuesArray.map(value => result.push({ip_address: (value.value ?? value ?? '').trim()}));

      return result;
    }

    /**
     CASE 6: ----> TODO : Not sure yet
     appgroups
     */

    /**
     CASE 7: ----> detail part of the value : {proto: 58}, {port: 87}, {port: 78, to_port: 100, proto: 17}, {port: 3, to_port: 7}
     port_range
     port_proto
     */
    if (['port_range', 'port_proto'].includes(categoryName)) {
      valuesArray.map(value => result.push(value.detail));

      return result;
    }

    /**
     CASE 8: ----> extract detail using `getPolicyServiceDetailValues(valuesArray);` and return only the the detail :  [{proto: 58}, {port: 78, to_port: 100, proto: 17}, {port: 3, to_port: 7}....]
     policy_services

     For detail, look at return data in policy_services categories or at https://jira.illum.io/browse/EYE-81260 comment section.
     */

    if (categoryName === 'policy_services') {
      result = [...result, ...getPolicyServiceDetailValues(valuesArray)];

      return result;
    }

    return result;
  }, []);
}

function getAndQuery(query1, query2) {
  return [[...query1], [...query2]].reduce(
    (outerCombo, currentGroup) => {
      return _.flatten(
        outerCombo.map(innerCombo => {
          return currentGroup.map(newItem => {
            return [...innerCombo, ...newItem];
          });
        }),
        true,
      );
    },
    [[]],
  );
}

/**
 Take an selector result in form of kay-value object and construct Query for Include field for a given selection filters

 * PURPOSE: for construct include query
 * USED-IN: `getExplorerQuery`

 * RETURN:  an nested array

 Look in comment section of https://jira.illum.io/browse/EYE-81260 for logic detail
 */
function getIncludeQueries(selectorValues) {
  if (_.isEmpty(selectorValues)) {
    return [[]];
  }

  const stickyAppGroupLabels = [];
  const allWorkloads = (selectorValues.workload ?? []).some(workload => workload.value === 'all_workloads');
  let query = Object.entries(selectorValues).reduce((result, [categoryName, valuesArray]) => {
    /**
      CASE 1: --> [[{label: {href: labelhref}}, {label: {href: labelhref}}]]
      appgroups
    */
    if (categoryName === 'appgroups' && !allWorkloads) {
      const outterArray = valuesArray.reduce((outerCombo, appgroupOption) => {
        const innerArray = appgroupOption.detail.labelsQuery.reduce((innerCombo, label) => {
          innerCombo.push(label);

          return innerCombo;
        }, []);

        // Save the sticky app group to be and'ed with the final query at the end
        if (appgroupOption.detail.sticky) {
          stickyAppGroupLabels.push(innerArray);
        } else {
          outerCombo.push(innerArray);
        }

        return outerCombo;
      }, []);

      result = [...result, ...outterArray];

      return result;
    }

    /**
     CASE 2:
     labelsAndLabelGroups ---> [[{label: {href: "/orgs/2/labels/40"}}, {label_group: {href: "/orgs/2/label_groups/1455"}},…],…]
     */
    if (categoryName === 'labelsAndLabelGroups' && !allWorkloads) {
      // labelsAndLabelGroups : [{href: "/orgs/2/labels/68", key: "role", value: "push"}, ...]

      // For the Label items send the cartesian product of each type of label
      // Example: role: [r1, r2, r3] and env: [e1, e2]
      // Is sent as: [[r1, e1], [r1, e2], [r2, e1], [r2, e2], [r3, e1], [r3, e2]]
      // Algorithm taken from http://stackoverflow.com/questions/12303989
      const labelsByKey = _.groupBy(valuesArray, _.iteratee('key'));
      const labelsCombinations = _.reduce(
        Object.values(labelsByKey),
        (outerCombo, currentGroup) => {
          return _.flatten(
            _.map(outerCombo, innerCombo => {
              return _.map(currentGroup, newItem => {
                return innerCombo.concat([{[getLabelHrefType(newItem.href)]: {href: newItem.href}}]);
              });
            }),
            true,
          );
        },
        [[]],
      );

      // Or the labels with other options
      result = [...result, ...labelsCombinations];

      return result;
    }

    /**
     CASE 3: ---> [{ip_address: "12.22.1.1"}, {ip_address: "222.222.222.222"}, {ip_address: "1.2.3.4/5"},…] add to bigger array
     is_address
     cidr_block : use 'ip_address' as key instead but still in different group
     */
    if (categoryName === 'ip_or_cidr') {
      result.push(
        valuesArray.reduce((ipAddressList, value) => {
          ipAddressList.push({ip_address: (value.value ?? value ?? '').trim()});

          return ipAddressList;
        }, []),
      );

      return result;
    }

    /**
     CASE 4:
     workload or container_workload : {workload : {href:...}}
     */
    if (['workload', 'container_workload'].includes(categoryName) && !allWorkloads) {
      result.push(valuesArray.map(({href}) => ({workload: {href}})));

      return result;
    }

    /**
      CASE 5: [[{fqdn: "amazon.com"}], [{fqdn: "fedex.com"}], ...]
       fqdn
    */
    if (categoryName === 'fqdn') {
      valuesArray.map(value => result.push([{fqdn: value.value || value}]));

      return result;
    }

    /**
      CASE 5:[{ip_list: {href: "/orgs/2/sec_policy/draft/ip_lists/731"}}],…]
       fqdn
    */
    if (categoryName === 'ip_list') {
      valuesArray.map(value => result.push([{ip_list: {href: value.href}}]));

      return result;
    }

    return result;
  }, []);

  /**
   * CASE 6: All Workloads (must not be sent with appGroups, labels, or workloads)
   * all_workloads : {actors: 'ams'}
   */
  if (allWorkloads) {
    query.push([{actors: 'ams'}]);
  }

  // And the sticky labels with the rest of the query
  if (stickyAppGroupLabels.length) {
    query = query.length ? getAndQuery(query, stickyAppGroupLabels) : stickyAppGroupLabels;
  }

  return query;
}

/**
 Take an Services selector result in form of kay-value object and construct query for Service Include field

 * PURPOSE: for construct include query specifically for Services selector
 * USED-IN: `getExplorerQuery`

 * RETRUN:  a nested array

 */
function getServicesIncludeQueries(selectorValues) {
  if (_.isEmpty(selectorValues)) {
    return [];
  }

  let result = [];

  /**
   CASE 1: Calculate the info into {}
   policy_services
   logic inside `getPolicyServiceDetailValues()`:
   1. loop through every keys and values of 'policy_services' object
   For each object, check for:
   a.  service_ports key with value array should not be empty or null
   - loop through each object to extract only the necessary detail info
   - if detail obj not empty, stringify it and add strigified detail object to `serviceList` array
   b.  windows_services key with value array should not be empty or null
   - loop through each object to extract only the necessary detail info
   - if detail obj not empty, stringify it and add strigified detail object to `serviceList` array
   Return `serviceList` main array of stringified objects
   2. remove duplicated object using _.uniq() on `serviceList` of stringified object
   3. parse array of string to array of objects
   */

  if (_.has(selectorValues, 'policy_services') && !_.isEmpty(selectorValues.policy_services)) {
    const policyServiceList = getPolicyServiceDetailValues(selectorValues.policy_services);

    result = [...policyServiceList];
  }

  /**
   CASE 2: Perform Cartesian Produce between groups if selected: 'port', 'windows_service_name', 'process_name'
   windows_service_name
   port_proto
   port_range
   process_name

   1 . Construct Groups for Cartesian products based on selections
   2 . Perform Cartesian products between these groups above --> [[]]
   3 . Convert result nested array into array of object --> [{}]
   4 . add return value to result
   */

  const otherValues = _.omit(selectorValues, ['policy_services']);

  // 0 . Early Exit if there is no other values in : 'port_proto','port_range', 'windows_service_name', 'process_name'
  if (_.isEmpty(otherValues)) {
    return result;
  }

  // 1. Extract only the detail and combine 'port_range' and 'port_proto' into 'port'
  const otherValuesDetail = get_Port_Process_WindowsService_DetailValues(otherValues);

  // 2. Cartesian product of port, windows_service_name, process_name , return result in form of [[]]
  const allCombination = _.reduce(
    Object.values(otherValuesDetail),
    (outerCombo, currentGroup) => {
      return _.flatten(
        _.map(outerCombo, innerCombo => {
          return _.map(currentGroup, newItem => {
            return innerCombo.concat(newItem);
          });
        }),
        true,
      );
    },
    [[]],
  );

  // 3. Turn combination [[]] into one object [{}]
  const otherValuesFinalResult = _.reduce(
    allCombination,
    (finalResult, arr) => {
      const final = _.reduce(
        arr,
        (finalObj, obj) => {
          Object.assign(finalObj, obj);

          return finalObj;
        },
        {},
      );

      finalResult.push(final);

      return finalResult;
    },
    [],
  );

  // 4. add return value to result
  result = [...result, ...otherValuesFinalResult];

  return result;
}

// ------------------------------- Helpers for Query Construction -----------------------

/**
 * Transfers the inverse (i.e. the unselected transmission options) of providerInclude
 * to providerExclude.
 *
 * PURPOSE: transfer `transmission` filters from providerInclude to providerExclude
 * USED-IN: `getExplorerQuery`
 */
export function getTransmissionExcludes(providerInclude = {}, providerExclude = {}) {
  const includes = new Set((providerInclude.transmission ?? []).map(({value}) => value));
  const excludes = new Set((providerExclude.transmission ?? []).map(({value}) => value));

  if (includes.size) {
    transmissionOptions.forEach(({value}) => {
      if (!includes.has(value)) {
        excludes.add(value);
      }
    });
  }

  return Array.from(excludes).map(value => ({value}));
}

/**

 Take raw data (object) of Service selector result and extract only the detail.
 This function look  into complex object structure of the raw data and extract only necessary data needed for query and next step processing
 The end result will combine 'port_range' and 'port_proto' into 'port'

 This is specifically for `policy_service` category of Services selector include field.

 * PURPOSE: clean data before combination process
 * USED-IN: `getPolicyServiceDetailValues`

 * RETURN: an object with only necessay detail for query. Return object return object in form of : (not every keys appear in the final object)
 {
          proto: '',
          port: '',
          to_port: '',
          process_name: '',
          service_name: ''
        }
 */

function getPolicyServiceDetailObject(portDetail) {
  if (!portDetail) {
    return {};
  }

  const result = {};

  if (portDetail.hasOwnProperty('proto') && !_.isNil(portDetail.proto)) {
    result.proto = portDetail.proto;
  }

  if (portDetail.hasOwnProperty('port') && !_.isNil(portDetail.port)) {
    result.port = portDetail.port;
  }

  if (portDetail.hasOwnProperty('to_port') && !_.isNil(portDetail.to_port)) {
    result.to_port = portDetail.to_port;
  }

  if (portDetail.hasOwnProperty('process_name') && !_.isNil(portDetail.process_name)) {
    result.process_name = portDetail.process_name.split('\\').pop();
  }

  if (portDetail.hasOwnProperty('service_name') && !_.isNil(portDetail.service_name)) {
    result.windows_service_name = portDetail.service_name;
  }

  return result;
}

/**
 Take raw data (object) of Service selector result and extract only the detail.
 This function look  into complex object structure of the raw data and extract only necessary data needed for query and next step processing

 This is specifically for other categories in Services selector include field besides `policy_service`.

 The end result will combine 'port_range' and 'port_proto' into 'port'
 Logic inside `getPolicyServiceDetailValues()`:
 1. loop through every keys and values of 'policy_services' object
 For each object, check for:
 a.  service_ports key with value array should not be empty or null
 - loop through each object to extract only the necessary detail info
 - if detail obj not empty, stringify it and add strigified detail object to `serviceList` array
 b.  windows_services key with value array should not be empty or null
 - loop through each object to extract only the necessary detail info
 - if detail obj not empty, stringify it and add strigified detail object to `serviceList` array
 Return `serviceList` main array of stringified objects
 2. remove duplicated object using _.uniq() on `serviceList` of stringified object
 3. parse array of string to array of objects

 * PURPOSE: clean data before combination process
 * USED-IN: `getExcludeQueries` and `getServicesIncludeQueries`
 * RETURN: an object with only necessay detail for query. Return object return object in form of :
 {
          port:[{}, {}...],
          process_name:[{}, {}...],
          windows_service_name:[{}, {}...],
        }
 */

function getPolicyServiceDetailValues(policyService) {
  const policyServiceQueryValues = _.map(
    // remove duplication of ['{port...}']
    _.uniq(
      _.reduce(
        policyService,
        (serviceList, service) => {
          if (_.has(service, ['service_ports'])) {
            _.map(service.service_ports, serviceObject => {
              const detail = getPolicyServiceDetailObject(serviceObject);

              if (!_.isEmpty(detail)) {
                serviceList.push(stringify(detail)); // stringify object to for _.uniq()
              }
            });
          }
          // {port : 90, to_port:100}, {to_port:100, port : 90}

          if (_.has(service, ['windows_services'])) {
            _.map(service.windows_services, serviceObject => {
              const detail = getPolicyServiceDetailObject(serviceObject);

              if (!_.isEmpty(detail)) {
                serviceList.push(stringify(detail)); // stringify object to for _.uniq()
              }
            });
          }

          if (__ANTMAN__ && _.has(service, ['windows_egress_services'])) {
            _.map(service.windows_egress_services, serviceObject => {
              const detail = getPolicyServiceDetailObject(serviceObject);

              if (!_.isEmpty(detail)) {
                serviceList.push(stringify(detail)); // stringify object to for _.uniq()
              }
            });
          }

          if (__ANTMAN__ && (_.has(service, ['port']) || _.has(service, ['proto']))) {
            serviceList.push(stringify(service));
          }

          return serviceList;
        },
        [],
      ),
    ),
    JSON.parse, // parse array of string to array of object
  );

  return policyServiceQueryValues;
}

/**
 Take raw data (object) of Service selector result and extract only the detail.
 This function look  into complex object structure of the raw data and extract only necessary data needed for query and next step processing

 This is specifically for other categories in Services selector include field besides `policy_service`.

 The end result will combine 'port_range' and 'port_proto' into 'port'

 * PURPOSE: clean data before combination process
 * USED-IN: `getServicesIncludeQueries`

 * RETURN:  an object with only necessay detail for query. Return object return object in form of :
 {
          port:[{}, {}...],
          process_name:[{}, {}...],
          windows_service_name:[{}, {}...],
        }
 */
function get_Port_Process_WindowsService_DetailValues(selectorValues) {
  const otherValuesDetail = {};

  if (_.has(selectorValues, 'process_name') && !_.isEmpty(selectorValues.process_name)) {
    const cleanedProcessName = _.reduce(
      selectorValues.process_name,
      (result, item) => {
        result.push({process_name: item.split('\\').pop()});

        return result;
      },
      [],
    );

    otherValuesDetail.process_name = cleanedProcessName;
  }

  if (_.has(selectorValues, 'windows_service_name') && !_.isEmpty(selectorValues.windows_service_name)) {
    const cleanedWindowsServiceName = _.reduce(
      selectorValues.windows_service_name,
      (result, item) => {
        result.push({windows_service_name: item});

        return result;
      },
      [],
    );

    otherValuesDetail.windows_service_name = cleanedWindowsServiceName;
  }

  if (_.has(selectorValues, 'port_proto') && !_.isEmpty(selectorValues.port_proto)) {
    const cleanedPort = _.reduce(
      selectorValues.port_proto,
      (result, object) => {
        if (_.has(object, 'detail') && !_.isEmpty(object.detail)) {
          result.push(object.detail);
        }

        return result;
      },
      [],
    );

    otherValuesDetail.port = cleanedPort;
  }

  if (_.has(selectorValues, 'port_range') && !_.isEmpty(selectorValues.port_range)) {
    const cleanedPortRange = _.reduce(
      selectorValues.port_range,
      (result, object) => {
        if (_.has(object, 'detail') && !_.isEmpty(object.detail)) {
          result.push(object.detail);
        }

        return result;
      },
      [],
    );

    otherValuesDetail.port = _.isNil(otherValuesDetail.port)
      ? cleanedPortRange
      : [...otherValuesDetail.port, ...cleanedPortRange];
  }

  return otherValuesDetail;
}

export const hasMismatchedAppGroupFilter = (query, appGroups) => {
  return ['consumerInclude', 'consumerExclude', 'providerInclude', 'providerExclude'].some(
    filterType =>
      query.filters[filterType]?.appgroups?.some(appGroupFilter =>
        (appGroups || []).every(
          appGroup => (appGroup.detail?.href || appGroup.href) !== (appGroupFilter.detail?.href || appGroupFilter.href),
        ),
      ) ||
      (query.filters[filterType]?.appgroups && !query.filters[filterType]?.appgroups.length),
  );
};

const isLegacyExplorerQuery = query =>
  !(query.queryName ?? '').startsWith(mapQueryNamePrefix) && Boolean(query.filters?.action);

export const filterLegacyQueries = (queries, appGroups) =>
  queries.filter(query => !isLegacyExplorerQuery(query) && !hasMismatchedAppGroupFilter(query, appGroups));

export const transformTime = (time, legacyCustomRange) => {
  const value = typeof time === 'string' ? time : time.value;

  // Add range for custom time if received from legacy query
  if (!_.isEmpty(legacyCustomRange)) {
    return {
      label: intl('Common.Custom'),
      value: 'custom',
      range: legacyCustomRange,
    };
  }

  const option =
    explorerTimeOptions.find(option => option.value === value || option.label === value) ?? explorerTimeOptions[3];
  const range = time.value === 'custom' ? getTimeRange(time) : undefined;
  const label = time.value === 'custom' ? getCustomRangeLabel(range) : option.label;

  return {label, value: option.value, range};
};

export const transformQueries = queries => {
  return queries
    .filter(query => Boolean(query?.filters?.time))
    .sort((a, b) => (a.date || b.date < 0 || 0 ? -1 : b.date || a.date < 0 || 0 ? 1 : 0))
    .map(query => ({
      ...query,
      queryName: (query.queryName ?? query.label ?? '').replace(new RegExp(`^${mapQueryNamePrefix}`), ''),
      filters: {
        ...EmptyFilters,
        ...convertFiltersValuesToMap(query.filters),
        time: transformTime(query.filters.time),
      },
    }));
};

export const fixBadLegacyConversion = query => {
  return {
    ...query,
    filters: Object.fromEntries(
      Object.entries(query.filters).map(([fieldName, fieldValue]) => {
        if (fieldName === 'servicesInclude' || fieldName === 'servicesExclude') {
          return [
            fieldName,
            Object.fromEntries(
              Object.entries(fieldValue).map(([serviceFieldName, serviceFieldValue]) => {
                if (serviceFieldName === 'port_proto') {
                  return [
                    'port_proto',
                    serviceFieldValue.map(portProto => {
                      // Find the bad empty detail, and reformulate it
                      if (_.isEmpty(portProto.detail)) {
                        return {...portProto, detail: parsePortProtoString(portProto.value)};
                      }

                      return portProto;
                    }),
                  ];
                }

                return [serviceFieldName, serviceFieldValue];
              }),
            ),
          ];
        }

        return [fieldName, fieldValue];
      }),
    ),
  };
};

export const convertLegacyQuery = (legacyQuery, appGroups, services) => {
  const queryName = legacyQuery.queryName ?? legacyQuery.label;

  return {
    queryId: legacyQuery.queryId ?? legacyQuery.filters?.uuid ?? generalUtils.randomString(10),
    queryName,
    label: queryName,
    date: legacyQuery.date,
    filters: Object.fromEntries(
      Object.entries(legacyQuery.filters).map(([legacyFieldName, legacyFieldValue]) => {
        const fieldName = {
          [legacyFieldName]: legacyFieldName,
          portsInclude: 'servicesInclude',
          portsExclude: 'servicesExclude',
          action: 'policyDecisions',
        }[legacyFieldName];
        let fieldValue;

        if (fieldName === 'time') {
          let range;
          const value = typeof legacyFieldValue === 'string' ? legacyFieldValue : legacyFieldValue.value;
          const legacyCustomRange = (value ?? '').match(legacyCustomTimeRangePattern);

          // Check for legacy custom time and create range object(using legacy filters "dateFrom" & "dateTo" fields) for transformation
          if (legacyCustomRange?.length > 3) {
            range = {start: legacyQuery.filters.dateFrom, end: legacyQuery.filters.dateTo};
          }

          // Transform legacy time filter value to Illumination Plus time filter value
          fieldValue = transformTime(legacyFieldValue, range);
        } else if (fieldName === 'policyDecisions') {
          fieldValue = {policy_decisions: Object.entries(legacyFieldValue).map(([label, [value]]) => ({label, value}))};
        } else if (
          fieldName === 'consumerInclude' ||
          fieldName === 'providerInclude' ||
          fieldName === 'consumerOrProviderInclude' ||
          fieldName === 'servicesInclude' ||
          fieldName === 'consumerExclude' ||
          fieldName === 'providerExclude' ||
          fieldName === 'consumerOrProviderExclude' ||
          fieldName === 'servicesExclude'
        ) {
          fieldValue = legacyFieldValue.reduce((values, {key, value, href, name, label, hostname, allWorkloads}) => {
            const resourceKey = allWorkloads
              ? 'workload'
              : {
                  iplist: 'ip_list',
                  ipaddress: 'ip_or_cidr',
                  cidrBlock: 'ip_or_cidr',
                  workloads: 'workload',
                  containerWorkloads: 'container_workload',
                  fqdn: 'fqdn',
                  transmission: 'transmission',
                  appgroups: 'appgroups',
                  policyService: 'policy_services',
                  windowsService: 'windows_service_name',
                  portRange: 'port_range',
                  portProtocol: 'port_proto',
                  processName: 'process_name',
                }[key] ?? 'labelsAndLabelGroups';
            let selection = {key, value, href, name, label, hostname};

            switch (resourceKey) {
              case 'ip_list':
                Object.assign(selection, {ip_ranges: value});
                break;
              case 'policy_services':
                const fullService = services.find(service => service.href === href);

                if (fullService) {
                  Object.assign(selection, fullService);
                } else {
                  Object.assign(selection, {
                    service_ports: {
                      port: value[0]?.port,
                      proto: value[0]?.proto,
                    },
                    process_name: value[0]?.process_name,
                    service_name: value[0]?.service_name,
                    protocol: value[0]?.protocol,
                  });
                }

                break;
              case 'transmission':
                Object.assign(selection, {label: value, value: href});
                break;
              case 'appgroups':
                const appGroup = appGroups.find(appGroup => appGroup.href === href || appGroup.value === value);

                // Handle the case where the app group type has changed since the filter was saved
                if (appGroup) {
                  Object.assign(selection, {
                    detail: appGroup?.detail ?? appGroup,
                  });
                } else {
                  selection = null;
                }

                break;
              case 'workload':
                if (allWorkloads) {
                  selection = {name: intl('Workloads.All'), value: 'all_workloads'};
                }

                break;
              case 'process_name':
              case 'windows_service_name':
                selection = value;
                break;
              case 'port_proto':
                Object.assign(selection, {detail: parsePortProtoString(value)});
                break;
              case 'port_range':
                Object.assign(selection, {detail: parsePortRangeProtoString(value)});
                break;
              default:
                break;
            }

            values[resourceKey] ??= [];

            if (selection) {
              values[resourceKey].push(selection);
            }

            return values;
          }, {});
        }

        return [fieldName, fieldValue];
      }),
    ),
  };
};

export const convertLegacyQueries = (queries, appGroups, services) => {
  return queries.reduce((result, query) => {
    const newQuery = isLegacyExplorerQuery(query)
      ? convertLegacyQuery(query, appGroups, services)
      : fixBadLegacyConversion(query);

    if (!hasMismatchedAppGroupFilter(newQuery, appGroups)) {
      result.push(newQuery);
    }

    return result;
  }, []);
};
