/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {tidUtils} from '@illumio-shared/utils';
import type {IconName} from './IconName';

const files = require.context('../../icons', false, /.*\.svg$/);
export const fileNames: string[] = files.keys();
const fileNameMap: {[key: string]: string} = fileNames.reduce(
  (result, fileName) => ({...result, [files(fileName).default.id]: fileName}),
  {},
);

function getRawSVG(iconName: string, color?: string): string {
  const icon = files(fileNameMap[iconName]);

  if (__DEV__ && !icon) {
    throw new Error(`No icon with name '${iconName}' exists`);
  }

  const {content = '', viewBox = ''} = icon?.default || {};
  const svgColor = color ? `<defs><style>* {fill: ${color}}</style></defs>` : '';
  const svgSymbol = content.match(/<symbol[^>]*>((.|\n)*?)<\/symbol>/)?.[1] ?? '';

  // firefox requires width & height on svg when rendered with drawImage https://bugzilla.mozilla.org/show_bug.cgi?id=700533
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}" width="100" height="100">${svgColor}${svgSymbol}</svg>`;
}

export const getIconDataUri = _.memoize(
  (iconName: string, color?: string) =>
    `data:image/svg+xml;utf8,${encodeURIComponent(getRawSVG(iconName, color || ''))}`,
  (iconName, color) => `${iconName}-${color}`,
);

export const tidByName = (icons: IconName[]): Map<IconName, string> =>
  new Map(icons.map(name => [name, tidUtils.getTid('comp-icon', name)]));

export const getTitle = (iconName: IconName): string => {
  switch (iconName) {
    case 'ad':
      return intl('Common.ActiveDirectory');
    case 'ad-certificate':
      return intl('Common.ActiveDirectoryCertificate');
    case 'ad-federation':
      return intl('Users.SSOConfig.ActiveDirectory');
    case 'ad-lightweight-directory':
      return intl('Common.ActiveDirectoryLightweightDirectory');
    case 'ad-rights-management':
      return intl('Common.ActiveDirectoryRightsManagement');
    case 'add':
    case 'add-diff':
    case 'add-diff-classic':
      return intl('Common.Add');
    case 'remove':
    case 'remove-diff':
    case 'remove-diff-classic':
      return intl('Common.Remove');
    case 'close':
    case 'close-classic':
      return intl('Common.Close');
    case 'back':
    case 'back-classic':
      return intl('Common.Back');
    case 'next':
    case 'next-classic':
      return intl('Common.Next');
    case 'comment':
    case 'comment-classic':
      return intl('Common.Comment');
    case 'capslock':
      return intl('Common.Capslock');
    case 'delete':
      return intl('Common.Delete');
    case 'duplicate':
      return intl('Common.Duplicate');
    case 'export':
      return intl('Common.Export');
    case 'down':
    case 'down-classic':
      return intl('Common.Down');
    case 'download':
      return intl('Common.Download');
    case 'import':
      return intl('Common.Import');
    case 'edit':
    case 'edit-classic':
    case 'edit-underline':
      return intl('Common.Edit');
    case 'email':
      return intl('Common.Email');
    case 'group':
      return intl('Common.Group');
    case 'help':
    case 'help-classic':
      return intl('Common.Help');
    case 'env':
    case 'env-classic':
      return intl('Labels.Environment');
    case 'key':
      return intl('PairingProfiles.KeyGen');
    case 'version':
      return intl('Common.Version');
    case 'illuminate':
      return intl('Common.Illumination');
    case 'illumination':
      return intl('Common.Illumination');
    case 'internet':
      return intl('Common.Internet');
    case 'info':
    case 'info-classic':
    case 'info-filled':
      return intl('Common.Information');
    case 'loc':
      return intl('Common.Location');
    case 'map':
      return intl('Map.Illumination');
    case 'menu':
      return intl('Menu.Navigation');
    case 'org':
      return intl('Common.Organization');
    case 'user':
      return intl('Users.User');
    case 'pause':
      return intl('Common.Pause');
    case 'play':
      return intl('Common.Play');
    case 'provision':
    case 'provision-classic':
      return intl('Common.Provision');
    case 'revert':
      return intl('Common.Undo');
    case 'role':
      return intl('Role.Label');
    case 'expand-role':
      return intl('Role.Expand');
    case 'collapse-role':
      return intl('Role.Collapse');
    case 'save':
      return intl('Common.Save');
    case 'search':
    case 'search-classic':
      return intl('Common.Search');
    case 'secure-connect':
      return intl('Common.SecureConnect');
    case 'settings':
    case 'settings-classic':
      return intl('Common.Settings');
    case 'stop':
      return intl('Common.Stop');
    case 'check':
    case 'check-classic':
    case 'check-filled':
      return intl('Common.Success');
    case 'warning':
    case 'warning-classic':
    case 'warning-filled':
      return intl('Common.Warning');
    case 'critical':
      return intl('Common.Critical');
    case 'arrow-left':
      return intl('Common.ArrowLeft');
    case 'arrow-right':
      return intl('Common.ArrowRight');
    case 'arrow-up':
      return intl('Common.ArrowUp');
    case 'arrow-down':
      return intl('Common.ArrowDown');
    case 'arrow-left-long':
      return intl('Common.ArrowLeftLong');
    case 'arrow-right-long':
      return intl('Common.ArrowRightLong');
    case 'filter':
      return intl('Common.Filter');
    case 'add-user':
      return intl('Users.Add');
    case 'app':
      return intl('Labels.Application');
    case 'app-group-map':
      return intl('Common.AppGroupMap');
    case 'private-address':
      return intl('Common.PrivateAddress');
    case 'server':
      return intl('Common.Server');
    case 'video':
      return intl('Common.Video');
    case 'zoom-to-fit':
      return intl('Common.ZoomToFit');
    case 'cancel':
      return intl('Common.Cancel');
    case 'sort-up':
    case 'sort-down':
      return intl('Common.Sort');
    case 'enforced':
      return intl('Common.Enforced');
    case 'unmanaged':
      return intl('Common.Unmanaged');
    case 'error':
    case 'error-classic':
    case 'error-filled':
      return intl('Common.Error');
    case 'online':
      return intl('Common.Online');
    case 'syncing':
      return intl('Workloads.Status.Syncing');
    case 'list':
      return intl('Common.BackToList');
    case 'workload':
      return intl('Common.Workload');
    case 'third-party':
      return intl('Common.ThirdParty');
    case 'pending':
      return intl('Common.Pending');
    case 'expand':
      return intl('Common.Expand');
    case 'move-tool':
      return intl('Common.Move');
    case 'selection-tool':
      return intl('Common.Select');
    case 'pair':
      return intl('PairingProfiles.Pair');
    case 'unpair':
      return intl('Common.Unpair');
    case 'copy':
      return intl('Common.Copy');
    case 'navigateup':
      return intl('Common.NavigateUp');
    case 'manage':
      return intl('LoadBalancers.Detail.Manage');
    case 'service':
      return intl('Common.Service');
    case 'enabled':
      return intl('Common.Enabled');
    case 'disabled':
    case 'disabled-status':
    case 'disabled-status-classic':
      return intl('Common.Disabled');
    case 'deny':
      return intl('Common.Deny');
    case 'stopped':
      return intl('Common.Stopped');
    case 'dashboard':
    case 'dashboard-classic':
      return intl('Common.Dashboard');
    case 'allowlist':
      return intl('Common.IPList');
    case 'link':
      return intl('Common.Link');
    case 'unlink':
      return intl('Common.Unlink');
    case 'ven':
      return intl('Common.VEN');
    case 'admin':
      return intl('Role.Admin');
    case 'machine-auth':
      return intl('Common.MachineAuthentication');
    case 'network':
      return intl('Rulesets.Rules.AllNetworks');
    case 'endpoint':
      return intl('Rulesets.Rules.NonCorporateNetworks');
    case 'container-workload':
      return intl('Common.ContainerWorkload');
    case 'grab-handle':
    case 'grab-handle-classic':
      return intl('Common.Grab');
    case 'refresh':
    case 'refresh-classic':
      return intl('Common.Refresh');
    case 'inuse':
    case 'inuse-classic':
      return intl('Common.InUse');
    case 'access':
      return intl('RuleSearch.Access');
    case 'appgroup':
      return intl('Common.AppGroup');
    case 'explore':
      return intl('Common.Explore');
    case 'folder':
      return intl('Common.Folder');
    case 'health':
      return intl('VEN.Health');
    case 'infrastructure':
      return intl('Menu.Infrastructure');
    case 'policyobj':
      return intl('Policy.Objects');
    case 'progress':
      return intl('Common.InProgress');
    case 'troubleshoot':
      return intl('Common.Troubleshoot');
    case 'userprof':
      return intl('Common.UserProfile');
    default:
      return _.capitalize(_.replace(iconName, /-/g, ' '));
  }
};
