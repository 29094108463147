/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories} from './ContainerClusterContainerWorkloadProfileListConfig';
import {getAllUsersMap, isUserReadOnlyClusterInsensitive, isSuperclusterMember} from 'containers/User/UserState';
import {getRouteParams} from 'containers/App/AppState';
import * as GridUtils from 'components/Grid/GridUtils';
import {isLocalPce} from 'containers/App/AppUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {
  getDisplayNames,
  getLabelSelectorOptions,
  getLabelSelectorOptionsMap,
} from 'containers/Label/LabelSettings/LabelSettingState';
import {getContainerClusterDetail} from '../../ContainerClusterDetailState';
import {isOpenShift} from 'containers/ContainerCluster/ContainerClusterUtils';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';
import {inferLabelSource} from '../../ContainerClusterDetailUtils';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

export default {
  containerWorkloadProfileList(state = [], action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_CONTAINER_WORKLOAD_PROFILES':
        return action.data.containerWorkloadProfileList;
      default:
        return state;
    }
  },

  containerWorkloadProfileCount(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_CONTAINER_WORKLOAD_PROFILES':
        return action.data.containerWorkloadProfileCount;
      default:
        return state;
    }
  },

  containerWorkloadProfileDefault(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_CONTAINER_WORKLOAD_PROFILE_DEFAULT':
        return action.data.containerWorkloadProfileDefault?.[0] ?? state;
      default:
        return state;
    }
  },
};

export const getContainerClusterContainerWorkloadProfiles = state =>
  state.containerClusters.containerWorkloadProfileList;
export const getContainerClusterContainerWorkloadProfilesCount = state =>
  state.containerClusters.containerWorkloadProfileCount;
export const getContainerClusterContainerWorkloadProfileDefault = state =>
  state.containerClusters.containerWorkloadProfileDefault;

const getContainerClusterContainerWorkloadProfilesRows = createSelector(
  [
    getContainerClusterContainerWorkloadProfiles,
    getAllUsersMap,
    isUserReadOnlyClusterInsensitive,
    getDisplayNames,
    getLabelSelectorOptionsMap,
  ],
  (profiles, usersMap, userIsReadOnlyClusterInsensitive, labelTypesNameObj, typeMap) => {
    return profiles.map(item => {
      const multiMap = new Map();
      const singleMap = new Map();
      const noLabelArray = [];

      item.labels.forEach(label => {
        if (label.restriction?.length) {
          multiMap.set(label.key, label.restriction);
        }

        if (label.assignment) {
          singleMap.set(label.key, [label.assignment]);
        }

        if (label.restriction?.length === 0) {
          noLabelArray.push(typeMap.get(label.key));
        }
      });

      return {
        key: item.href,
        selectable: !userIsReadOnlyClusterInsensitive,
        removable: !item.linked,
        data: {
          ...item,
          multiMap,
          singleMap,
          noLabelArray,
          updated_by: fillUserInfo(usersMap, item.updated_by),
          labels: GridUtils.getLabelsMap(item.labels),
          labelTypesNameObj,
        },
      };
    });
  },
);

const getContainerClusterContainerWorkloadProfileDefaultRows = createSelector(
  [getContainerClusterContainerWorkloadProfileDefault],
  defaultProfile => {
    return defaultProfile
      ? defaultProfile.labels.map(label => {
          return {
            key: label.key,
            selectable: true,
            data: {
              ...label,
              multi: label.restriction ?? [],
              single: label.assignment,
              nolabel: label.restriction?.length === 0,
            },
          };
        })
      : [];
  },
);

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getContainerClusterContainerWorkloadProfilesRows,
    filterMap: getFilterMap,
  });

export const getContainerClusterContainerWorkloadProfilesPage = createSelector(
  [
    getContainerClusterDetail,
    getGrid,
    getRouteParams,
    getContainerClusterContainerWorkloadProfilesCount,
    isSuperclusterMember,
    getContainerClusterContainerWorkloadProfileDefault,
    isUserReadOnlyClusterInsensitive,
    getClusters,
    getLocalFQDN,
    categories,
    getDisplayNames,
    getContainerClusterContainerWorkloadProfileDefaultRows,
    getLabelSelectorOptions,
    getLabelSelectorOptionsMap,
  ],
  (
    clusterDetail,
    grid,
    {id},
    count,
    isMember,
    defaultProfile = {mode: 'unmanaged'},
    userIsReadOnlyClusterInsensitive,
    clusters,
    local,
    categories,
    labelTypesNameObj,
    defaultInitialRows,
    labelSelectorOptions,
    typeMap,
  ) => {
    return {
      isOpenshift: isOpenShift(clusterDetail),
      isLocalPce: isLocalPce({clusters, local, pceFqdn: clusterDetail.pce_fqdn}),
      allowCreateTypes: isMember ? [] : ['labels'],
      grid,
      id,
      count,
      defaultProfile,
      userIsReadOnlyClusterInsensitive,
      ...GridUtils.getLabelsMap(defaultProfile.labels),
      defaultLabels: GridUtils.getFlattenedLabelModeMap(defaultProfile.labels),
      roleSource: inferLabelSource('role', defaultProfile.labels),
      appSource: inferLabelSource('app', defaultProfile.labels),
      envSource: inferLabelSource('env', defaultProfile.labels),
      locSource: inferLabelSource('loc', defaultProfile.labels),
      categories,
      labelTypesNameObj,
      defaultInitialRows,
      labelSelectorOptions,
      typeMap,
    };
  },
);
