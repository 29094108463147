/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {useCallback, useLayoutEffect, useRef, useState, useMemo, useEffect} from 'react';
import Resources from './Resource/Resources';
import {OPTIONPANEL_ID, CATEGORYPANEL_ID, COLUMN_WIDTH, populateInitialLoadPromises} from './SelectorUtils';
import {useFilialPiety} from './SelectorFormatUtils';

export default function OptionPanel(props) {
  const {
    theme,
    saveRef,
    category,
    category: {id: categoryId, maxColumns: maxColumnsProp},
    registerHandlers,
    infoPanel,
    allResources,
    pathArr,
    ...restProps
  } = props;
  const hasContainerResource = Object.values(category.resources).some(resource => resource.type === 'container');
  const maxColumns = maxColumnsProp ?? (hasContainerResource ? 'auto' : 2);

  const resources = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(allResources).filter(([, {sticky, categoryId}]) =>
          pathArr.includes(CATEGORYPANEL_ID) ? categoryId === category.id : sticky || categoryId === category.id,
        ),
      ),
    [allResources, category, pathArr],
  );

  const [initialLoadPromises, setInitialLoadPromises] = useState(populateInitialLoadPromises(resources));
  const prevCategoryId = useRef(null);
  const elementRef = useRef(null);
  const shouldRecomputeWidthRef = useRef(null);
  const [renderedWidth, setRenderedWidth] = useState(null);

  const skipWidthComputation = true;
  const appliedWidth = shouldRecomputeWidthRef.current || skipWidthComputation ? null : renderedWidth;
  const {saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild, keyDown} = useFilialPiety();

  const saveRefCallback = useCallback(
    element => {
      elementRef.current = element;
      saveRef(OPTIONPANEL_ID, element);
    },
    [saveRef],
  );

  const handleSizeChange = useCallback(() => {
    shouldRecomputeWidthRef.current = true;
    setInitialLoadPromises(populateInitialLoadPromises(resources));
  }, [resources]);

  useEffect(() => {
    setRenderedWidth(appliedWidth);
  }, [appliedWidth]);

  useEffect(() => {
    if (skipWidthComputation) {
      return;
    }

    window.addEventListener('resize', handleSizeChange);

    return () => {
      window.removeEventListener('resize', handleSizeChange);
    };
  }, [handleSizeChange, skipWidthComputation]);

  useLayoutEffect(() => {
    if (skipWidthComputation) {
      return;
    }

    if (categoryId !== prevCategoryId.current) {
      prevCategoryId.current = categoryId;
      shouldRecomputeWidthRef.current = true;
      setInitialLoadPromises(populateInitialLoadPromises(resources));
    }
  }, [categoryId, resources, skipWidthComputation]);

  useLayoutEffect(() => {
    if (!shouldRecomputeWidthRef.current) {
      return;
    }

    (async () => {
      try {
        const promises = initialLoadPromises;

        await Promise.all(Object.values(promises).map(({promise}) => promise));

        shouldRecomputeWidthRef.current = false;
        setRenderedWidth(elementRef.current.getBoundingClientRect().width);
      } catch (error) {
        console.log(error); //placeholder for error handling
      }
    })();
  }, [initialLoadPromises]);

  useLayoutEffect(() => {
    registerHandlers(OPTIONPANEL_ID, {setHighlightedChild, resetHighlightedChild, keyDown});
  }, [registerHandlers, setHighlightedChild, resetHighlightedChild, keyDown]);

  const style = {};

  if (appliedWidth) {
    style.width = appliedWidth;
  }

  if (typeof maxColumns === 'number') {
    style.maxWidth = `${maxColumns * COLUMN_WIDTH}px`;
  }

  Object.assign(restProps, {
    theme,
    allResources,
    resources,
    pathArr,
    category,
    saveRef: saveChildRef,
    registerHandlers: registerChildHandlers,
    initialLoadParams: initialLoadPromises,
    shouldRenderInfoPanel: Boolean(appliedWidth),
  });

  return (
    <div
      ref={saveRefCallback}
      className={theme.optionPanel}
      {...(_.isEmpty(style) ? {} : {style})}
      data-tid="comp-selector-optionpanel"
    >
      <Resources {...restProps} />
    </div>
  );
}
