/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import styles from './Selectors.css';
import * as PropTypes from 'prop-types';
import {Pill} from 'components';
import {PureComponent} from 'react';
import {pillIconMap} from './SelectorUtils';

export default class Item extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    value: PropTypes.any,
    object: PropTypes.object,
    noIcon: PropTypes.bool,
  };

  static defaultProps = {
    object: {},
    noIcon: false,
  };

  constructor(props) {
    super(props);

    this.handleItemDelete = this.handleItemDelete.bind(this);
  }

  handleItemDelete(evt) {
    const {value, object, onClose} = this.props;

    onClose(evt, value, object);
  }

  render() {
    const {value, children, noIcon} = this.props;
    const labelType = value.type || value.key || value.categoryKey;

    const props = {
      icon: noIcon
        ? null
        : pillIconMap()[labelType] === 'workload' && value.name
        ? 'unmanaged'
        : pillIconMap()[labelType],
      onClose: this.handleItemDelete,
      group: Boolean(value.name) && pillIconMap()[labelType] !== 'workload' && pillIconMap()[labelType] !== 'allowlist',
      pinned: value.scope,
      deleted: value.deleted,
      type: value.key || value?.value?.key,
      noContextualMenu: true,
    };

    return (
      <div className={styles.item} data-tid="comp-combobox-selected">
        <div className={styles.labelWrapper} data-tid="comp-combobox-selected-value">
          <Pill.Label {...props}>{children}</Pill.Label>
        </div>
      </div>
    );
  }
}
