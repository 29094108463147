/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Link, Pill, StatusIcon, Tooltip} from 'components';
import styles from './LabelList.css';
import {createSelector} from 'reselect';
import {isClasEnabled, isIPForwardingEnabled} from 'containers/App/AppState';
import {populateFacetCategory} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getDisplayNames} from '../LabelSettings/LabelSettingState';
import {clickableColumn} from 'components/Grid/GridUtils';

export const filterMap = createSelector([], () => ({value: intl('Common.Name'), key: intl('Common.Type')}));

export const categories = createSelector([getDisplayNames], labelTypesNameObj => [
  populateFacetCategory({
    id: 'value',
    name: intl('Common.Name'),
    resourceType: 'labels',
  }),
  {
    id: 'key',
    name: intl('Common.Type'),
    resources: {
      key: {
        statics: Object.entries(labelTypesNameObj).map(([id, value]) => ({value: id, text: value})),
        optionProps: {
          textPath: 'text',
          idPath: 'value',
        },
      },
    },
  },
]);

export const UsageLink = ({children, data, link, param, otherParams, clickableRef, tooltip}) => {
  if (link) {
    return (
      <Link
        to={link}
        tooltip={tooltip}
        params={{
          ...otherParams,
          [param]: {
            filter: {
              labelsAndLabelGroups: [{key: data.key, value: data.value, href: data.href}],
            },
          },
        }}
        ref={clickableRef}
      >
        {children}
      </Link>
    );
  }

  return tooltip ? (
    <Tooltip inline instant content={tooltip}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

const usageCell = ({name, valueKey, link, param, otherParams, tooltip, ...rest}) => {
  return clickableColumn({
    header: name,
    value: ({row}) => row.data.usage[valueKey],
    format: ({value, row, clickableRef}) => {
      if (!value) {
        return null;
      }

      return (
        <UsageLink
          data={row.data}
          link={link}
          param={param}
          otherParams={otherParams}
          clickableRef={clickableRef}
          tooltip={tooltip}
        >
          <StatusIcon position="before" status="inuse" />
          {name}
        </UsageLink>
      );
    },
    ...rest,
  });
};
export const formatHeader = areas => (
  <>
    <div className={styles.usageHeader}>{intl('Common.InUseBy')}</div>
    {areas}
  </>
);

/**
 [{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([isIPForwardingEnabled], isIPForwardingEnabled => ({
  id: 'labellist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => row.data.value,
    },
    type: {
      header: intl('Common.Type'),
      value: 'display_name',
      format: ({row, value}) => (
        <Pill.Label noAffix noFill insensitive noContextualMenu type={row.data.key}>
          {value}
        </Pill.Label>
      ),
    },
    workloads: usageCell({
      name: intl('Common.Workloads'),
      valueKey: 'workload',
      link: 'workloads.list',
      param: 'workloadlist',
    }),
    containerWorkloads: usageCell({
      name: intl('Common.ContainerWorkloads'),
      valueKey: 'container_workload',
      link: 'workloads.containers.list',
      param: 'cclusterworkloadlist',
    }),
    ...(isClasEnabled && {
      kubernetesWorkloads: usageCell({
        name: intl('Common.KubernetesWorkloads'),
        valueKey: 'kubernetes_workload',
        link: 'workloads.kubernetes.list',
        param: 'kubernetesworkloadlist',
      }),
    }),
    containerWorkloadProfiles: usageCell({
      name: intl('ContainerClusters.ContainerWorkloadProfiles'),
      valueKey: 'container_workload_profile',
      tooltip: intl('Labels.UsedInContainerCluster'),
    }),
    enforcementBoundary: usageCell({
      name: intl('Rulesets.DenyRules'),
      valueKey: 'enforcement_boundary',
      link: 'boundaries.list',
      param: 'boundarieseruleslist',
    }),
    rulesets: usageCell({
      name: intl('Common.Rulesets'),
      valueKey: 'ruleset',
      link: 'rulesets.list',
      param: 'rulesetlist',
    }),
    pairingProfiles: usageCell({
      name: intl('PairingProfiles.Profiles'),
      valueKey: 'pairing_profile',
      link: 'pairingProfiles.list',
      param: 'pairingprofilelist',
    }),
    virtualServices: usageCell({
      name: intl('Common.VirtualServices'),
      valueKey: 'virtual_service',
      link: 'virtualServices.list',
      param: 'virtualservicelist',
    }),
    virtualServers: usageCell({
      name: intl('Common.VirtualServer'),
      valueKey: 'virtual_server',
      link: 'virtualServers.list',
      param: 'virtualserverlist',
    }),
    loopbackInterfaces: usageCell({
      name: intl('Settings.LoopbackInterfaces.Title'),
      valueKey: 'loopback_interfaces_in_policy_scopes',
      link: 'securitysettings.section',
      param: 'securitysettings-loopbackinterfaces',
      otherParams: {section: 'loopbackinterfaces', mode: 'draft'},
      disabled: __ANTMAN__,
    }),
    containerSettings: usageCell({
      name: intl('Settings.Containers.ContainerSettings'),
      valueKey: 'containers_inherit_host_policy_scopes',
      link: 'securitysettings.section',
      param: 'securitysettings-containers',
      otherParams: {section: 'containers', mode: 'draft'},
      disabled: __ANTMAN__,
    }),
    blockaction: usageCell({
      name: intl('Common.BlockAction'),
      valueKey: 'blocked_connection_reject_scopes',
      link: 'securitysettings.section',
      param: 'securitysettings-blockaction',
      otherParams: {section: 'blockaction', mode: 'draft'},
      disabled: __ANTMAN__,
    }),
    ipForwarding: usageCell({
      name: intl('Settings.IPForwarding.Title'),
      valueKey: 'ip_forwarding_enabled_scopes',
      link: 'securitysettings.section',
      param: 'securitysettings-ipforwarding',
      otherParams: {section: 'ipforwarding', mode: 'draft'},
      disabled: __ANTMAN__ || !isIPForwardingEnabled,
    }),
    labelGroups: usageCell({
      name: intl('Labels.Groups'),
      valueKey: 'label_group',
      link: 'labelGroups.list',
      param: 'labelgrouplist',
    }),
    RBAC: usageCell({
      name: intl('Common.RBAC'),
      valueKey: 'permission',
      link: 'rbac.roles.scope.list',
      param: 'scopelist',
    }),
    staticPolicy: usageCell({
      name: intl('Common.StaticPolicy'),
      valueKey: 'static_policy_scopes',
      link: 'securitysettings.section',
      param: 'securitysettings-staticscopes',
      otherParams: {section: 'staticscopes', mode: 'draft'},
    }),
    firewallCoexistence: usageCell({
      name: intl('Firewall.Coexistence.Title'),
      valueKey: 'firewall_coexistence_scope',
      link: 'securitysettings.section',
      param: 'securitysettings-firewallcoexistence',
      otherParams: {section: 'firewallcoexistence', mode: 'draft'},
    }),
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['name'], size: 'minmax(200px, auto)'},
      {columns: ['type'], size: 'minmax(150px, auto)'},
      {
        columns: ['workloads', 'containerWorkloads', 'containerWorkloadProfiles', 'enforcementBoundary'],
        size: 'minmax(100px, auto)',
        header: formatHeader,
      },
      {columns: ['rulesets', 'pairingProfiles', 'virtualServers'], size: 'minmax(100px, auto)', header: formatHeader},
      {
        columns: ['virtualServices', 'loopbackInterfaces', 'containerSettings', 'blockaction'],
        size: 'minmax(100px, auto)',
        header: formatHeader,
      },
      {columns: ['labelGroups', 'RBAC'], size: 'minmax(100px, auto)', header: formatHeader},
      {
        columns: ['staticPolicy', 'firewallCoexistence', 'ipForwarding'],
        size: 'minmax(150px, auto)',
        header: formatHeader,
      },
    ],
    {
      maxWidth: 1152,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type'], size: 'minmax(200px, auto)'},
          {
            columns: ['workloads', 'containerWorkloads', 'containerWorkloadProfiles', 'enforcementBoundary'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: ['rulesets', 'pairingProfiles', 'virtualServers'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: ['virtualServices', 'loopbackInterfaces', 'containerSettings', 'blockaction', 'labelGroups'],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: ['RBAC', 'staticPolicy', 'firewallCoexistence', 'ipForwarding'],
            size: 'minmax(150px, auto)',
            header: formatHeader,
          },
        ];
      },
    },
    {
      maxWidth: 960,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type'], size: 'minmax(100px, auto)'},
          {
            columns: [
              'workloads',
              'containerWorkloads',
              'containerWorkloadProfiles',
              'enforcementBoundary',
              'rulesets',
            ],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: [
              'pairingProfiles',
              'virtualServers',
              'virtualServices',
              'loopbackInterfaces',
              'containerSettings',
            ],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: ['blockaction', 'labelGroups', 'RBAC', 'staticPolicy', 'firewallCoexistence', 'ipForwarding'],
            size: 'minmax(150px, auto)',
            header: formatHeader,
          },
        ];
      },
    },
    {
      maxWidth: 640,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'type'], size: 'minmax(100px, auto)'},
          {
            columns: [
              'workloads',
              'containerWorkloads',
              'containerWorkloadProfiles',
              'enforcementBoundary',
              'rulesets',
              'pairingProfiles',
              'virtualServices',
            ],
            size: 'minmax(100px, auto)',
            header: formatHeader,
          },
          {
            columns: [
              'virtualServers',
              'loopbackInterfaces',
              'containerSettings',
              'labelGroups',
              'RBAC',
              'staticPolicy',
              'firewallCoexistence',
              'blockaction',
              'ipForwarding',
            ],
            size: 'minmax(130px, auto)',
            header: formatHeader,
          },
        ];
      },
    },
  ],
}));
