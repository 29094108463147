/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {getPort} from 'containers/Service/ServiceUtils';
import {hrefUtils, portUtils} from '@illumio-shared/utils';
import _ from 'lodash';
import {explorerTimeOptions, otherTimeOptions} from 'containers/IlluminationMap/MapUtils';
import {getFiltersWithoutExclusions} from 'containers/IlluminationMap/ToolBar/QuickFilter/MapQuickFilter';

export const QUERY_STATUS = {
  WORKING: 'working',
  QUEUED: 'queued',
  KILLED: 'killed',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCEL_REQUESTED: 'cancel_requested',
};
export function isQueryPending(status) {
  return status === QUERY_STATUS.QUEUED || status === QUERY_STATUS.WORKING;
}

export function isQueryComplete(status) {
  return status === QUERY_STATUS.COMPLETED;
}

export function isQueryKilled(status) {
  return status === QUERY_STATUS.KILLED;
}

export function isQueryFailed(status) {
  return status === QUERY_STATUS.FAILED;
}

export function isQueryCancelled(status) {
  return status === QUERY_STATUS.CANCEL_REQUESTED;
}

export const calculateTime = value => {
  const now = new Date();

  switch (value) {
    case intl('DateTimeInput.Now'):
      return now;
    case intl('Explorer.LastHours', {count: 1}):
    case intl('Explorer.HoursAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'h', 1);
    case intl('Explorer.LastHours', {count: 24}):
    case intl('Explorer.HoursAgo', {count: 24}):
    case intl('Explorer.LastDays', {count: 1}):
    case intl('Explorer.DaysAgo', {count: 1}):
    case intl('Explorer.LastDay'):
      return intl.utils.subtractTime(now, 'd', 1);
    case intl('Explorer.LastWeeks', {count: 1}):
    case intl('Explorer.WeeksAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'd', 7);
    case intl('Explorer.LastWeeks', {count: 2}):
      return intl.utils.subtractTime(now, 'd', 14);
    case intl('Explorer.LastMonths', {count: 1}):
    case intl('Explorer.MonthsAgo', {count: 1}):
      return intl.utils.subtractTime(now, 'M', 1);
    case intl('DateTimeInput.Anytime'):
      return intl.utils.subtractTime(now, 'y', 5);
  }
};

export const getTimeRange = (time = {}) => {
  const value = typeof time === 'string' ? time : time.value;

  if (value === 'custom' && time.range?.start && time.range?.end) {
    return {start: new Date(time.range.start), end: new Date(time.range.end)};
  }

  const selectedOption = [...explorerTimeOptions, ...otherTimeOptions]
    .filter(option => option.value !== 'custom')
    .find(option => value === option.value || value === option.label);

  if (selectedOption) {
    return {start: calculateTime(selectedOption.label), end: new Date()};
  }

  return {start: calculateTime(intl('Explorer.DaysAgo', {count: 1})), end: new Date()};
};

export const getBaseExplorerQuery = time => {
  const {start: startDate, end: endDate} = getTimeRange(time);

  return {
    sources: {
      include: [[]],
      exclude: [],
    },
    destinations: {
      include: [[]],
      exclude: [],
    },
    services: {
      include: [],
      exclude: [],
    },
    include_rule_coverage: true,
    sources_destinations_query_op: 'and',
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    policy_decisions: ['potentially_blocked', 'allowed', 'blocked', 'unknown'],
    query_name: '',
  };
};

export const queryMatchesAppId = (result, id) => {
  return ['sources', 'destinations'].some(endpoint =>
    result.queryParameters[endpoint].include.some(items =>
      (id?.split('x') || []).every(id => {
        return (items || []).some(item => {
          return hrefUtils.getId(item.label?.href || '') === id;
        });
      }),
    ),
  );
};

export const getProviderTransmissionInclude = providerInclude => {
  return providerInclude.filter(provider => provider && provider.key !== 'transmission');
};

export const getItems = types => (types ? Object.values(types || {}).filter(type => type.length) : []);

export function cartesianProductForServices(types) {
  const productTypes = {...types};

  delete productTypes.policyServices;

  // For the Label items send the cartesian product of each type of label
  // Example: role: [r1, r2, r3] and env: [e1, e2]
  // Is sent as: [[r1, e1], [r1, e2], [r2, e1], [r2, e2], [r3, e1], [r3, e2]]
  // Algorithm taken from http://stackoverflow.com/questions/12303989
  const product = getItems(productTypes).reduce(
    (result, serviceType) => result.flatMap(inner => serviceType.map(service => ({...inner, ...service}))),
    [[]],
  );

  return [...product.flat(), ...types.policyServices];
}

export function transformPolicyFilters(filterValues) {
  // Transforms the "Policy Decision" filters into the "policyDecisions" and "boundaryDecisions" fields
  // expected by the API.

  return filterValues.reduce(
    (result, value) =>
      value.endsWith('by_boundary')
        ? {
            boundaryDecisions: ['blocked'],
            policyDecisions: [...result.policyDecisions, value.replace(/_by_boundary$/, '')],
          }
        : {...result, policyDecisions: [...result.policyDecisions, value]},
    {policyDecisions: [], boundaryDecisions: []},
  );
}

const getPortProtocol = item => {
  if (item.value.includes(' ')) {
    const portAndProtocol = item.value.split(' ');

    return {
      port: Number(portAndProtocol[0]),
      proto: portUtils.reverseLookupProtocol(portAndProtocol[1]),
    };
  }

  if (!isNaN(Number(item.value))) {
    return {port: Number(item.value)};
  }

  return {proto: portUtils.reverseLookupProtocol(item.value)};
};

export function getPolicyService(item) {
  return item.value.map(service => {
    const result = {};

    if (service.hasOwnProperty('protocol') && service.protocol >= 0) {
      result.proto = service.protocol;
    }

    if (service.hasOwnProperty('port') && service.port >= 0) {
      result.port = service.port;
    }

    if (service.hasOwnProperty('to_port')) {
      result.to_port = service.to_port;
    }

    if (service.hasOwnProperty('process_name') && service.process_name !== null) {
      result.process_name = service.process_name.split('\\').pop();
    }

    if (service.hasOwnProperty('service_name') && service.service_name !== null) {
      result.windows_service_name = service.service_name;
    }

    return result;
  });
}

export function getStartDate(time) {
  let range = time.split(`${intl('DateTimeInput.From')}: `);

  if (range.length === 2) {
    range = range[1].split(` ${intl('DateTimeInput.To')}: `);

    return calculateTime(range[0]) || new Date(range[0]);
  }

  return calculateTime(time);
}

export function getEndDate(time) {
  let range = time.split(`${intl('DateTimeInput.From')}: `);

  if (range.length === 2) {
    range = range[1].split(` ${intl('DateTimeInput.To')}: `);

    return calculateTime(range[1]) || new Date(range[1]);
  }

  return new Date();
}

export function getMinMaxComplete(inProgress, complete) {
  if (complete && !(complete.max - complete.min)) {
    complete = false;
  }

  if (!inProgress?.limit) {
    inProgress = false;
  }

  if (complete && inProgress) {
    return {
      min: Math.min(complete.min, inProgress.offset),
      max: Math.max(complete.max, inProgress.offset + inProgress.limit),
    };
  }

  if (inProgress) {
    return {min: inProgress.offset, max: inProgress.offset + inProgress.limit};
  }

  if (complete) {
    return {...complete};
  }

  return {min: 0, max: 0};
}

export function isRangeContained(range, containingRange) {
  return (
    (isNaN(range.offset) ? range.min : range.offset) >=
      (isNaN(containingRange.offset) ? containingRange.min : containingRange.offset) &&
    (isNaN(range.offset) ? range.max : range.offset + range.limit) <=
      (isNaN(containingRange.offset) ? containingRange.max : containingRange.offset + containingRange.limit)
  );
}

export function calculateMissingRanges(newSection, completed) {
  const {min, max} = newSection;

  if (!completed?.length) {
    return [{offset: min, limit: max - min}];
  }

  const sorted = [...completed].filter(range => range && range.max !== 0).sort((a, b) => a.min - b.min);

  return sorted.reduce((missingRanges, complete, i) => {
    const nextComplete = sorted.length > i + 1 ? sorted[i + 1] : null;
    const prevComplete = i > 0 ? sorted[i - 1] : null;

    // Handle everything missing below the first range
    if (!prevComplete && complete && complete.min > min) {
      missingRanges.push({offset: min, limit: Math.min(complete.min, max) - min});
    }

    // Handle everything missing between two ranges
    const nextOffset = Math.max(min, complete.max);

    if (nextComplete && nextComplete.min > nextOffset && complete && complete.max < max) {
      missingRanges.push({offset: nextOffset, limit: Math.min(max, nextComplete.min) - nextOffset});
    }

    // Handle everything missing above the top
    if (!nextComplete && complete && complete.max < max) {
      const offset = Math.max(complete.max, min);
      const limit = nextComplete ? nextComplete.min - (complete.max || min) : max - (complete.max || min);

      if (limit > 0) {
        missingRanges.push({offset, limit});
      }
    }

    return missingRanges;
  }, []);
}

export function removeOldRuleStatus(statuses) {
  const now = new Date();
  const lastWeek = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

  return Object.keys(statuses).reduce((result, key) => {
    if (statuses[key]?.timestamp && new Date(statuses[key].timestamp).getTime() > lastWeek) {
      result[key] = statuses[key];
    }

    return result;
  }, {});
}

export const getNestedEndpointQuery = types => {
  const finalProduct = Object.keys(types).reduce((result, type) => {
    const items = types[type];

    if (type === 'labels') {
      // For the Label items send the cartesian product of each type of label
      // Example: role: [r1, r2, r3] and env: [e1, e2]
      // Is sent as: [[r1, e1], [r1, e2], [r2, e1], [r2, e2], [r3, e1], [r3, e2]]
      // Algorithm taken from http://stackoverflow.com/questions/12303989
      const product = Object.values(getItems(items)).reduce(
        (result, labelType) => result.flatMap(inner => labelType.map(label => inner.concat([label]))),
        [[]],
      );

      return product[0].length ? [...result, ...product] : result;
    }

    if (items.length && type !== 'tranmission') {
      items.forEach(item => result.push(type === 'appgroups' ? item : [item]));
    }

    return result;
  }, []);

  return finalProduct.length ? finalProduct : [[]];
};

export function getUnNestedEndpointQuery(types) {
  return Object.keys(types || {}).reduce((result, type) => {
    if (type === 'labels') {
      Object.values(types[type]).forEach(labelType => {
        if (labelType.length) {
          result = [...result, ...labelType];
        }
      });
    } else {
      result = [...result, ...types[type]];
    }

    return result;
  }, []);
}

const getPortRange = item => {
  const portRange = item.value.split(' ');
  const portsInRange = portRange[0].split(';');

  if (item.value.includes(' ')) {
    return {
      port: Number(portsInRange[0]),
      to_port: Number(portsInRange[1]),
      proto: portUtils.reverseLookupProtocol(portRange[1]),
    };
  }

  return {
    port: Number(portsInRange[0]),
    to_port: Number(portsInRange[1]),
  };
};

export const formatPortFilterArray = items => {
  return items.reduce((services, item) => {
    if (item.key === 'portProtocol') {
      services.push(getPortProtocol(item));
    } else if (item.key === 'portRange') {
      services.push(getPortRange(item));
    } else if (item.key === 'policyService' && item && item.value && Array.isArray(item.value)) {
      return services.concat(getPolicyService(item));
    } else if (item.key === 'processName') {
      services.push({process_name: item.value.split('\\').pop()});
    } else if (item.key === 'windowsService') {
      services.push({windows_service_name: item.value});
    }

    return services;
  }, []);
};

const sortItems = (filters, type, appGroupMap = {}) => {
  let allWorkloads = false;

  // Sort the filters into various types
  const types = {
    allWorkloads: [],
    labels: {
      role: [],
      app: [],
      env: [],
      loc: [],
    },
    ipaddress: [],
    cidrBlock: [],
    workloads: [],
    appgroups: [],
    fqdn: [],
    transmission: [],
    iplist: [],
    label_group: [],
  };

  filters.forEach(filter => {
    const appGroupNode = appGroupMap[filter.href];

    if (
      types[filter.key] ||
      types.labels[filter.key] ||
      filter.key === 'iplist' ||
      filter.key === 'containerWorkloads' ||
      filter.allWorkloads
    ) {
      if (filter.allWorkloads) {
        allWorkloads = true;
        types.allWorkloads.push({actors: 'ams'});
      } else if (filter.key === 'transmission' && type.includes('provider')) {
        types[filter.key].push({transmission: filter.href});
      } else if (filter.key === 'workloads' || filter.key === 'containerWorkloads') {
        types.workloads.push({workload: {href: filter.href}});
      } else if (filter.key === 'fqdn' && type.includes('provider')) {
        types[filter.key].push({fqdn: filter.href});
      } else if (filter.key === 'ipaddress' || filter.key === 'cidrBlock') {
        types[filter.key].push({ip_address: filter.href});
      } else if (filter.href && filter.href.includes('label_group')) {
        types.labels[filter.key].push({label_group: {href: filter.href}});
      } else if (filter.key === 'iplist') {
        // iplist now has href so it behaves just like labels/workload etc.
        types[filter.key].push({ip_list: {href: filter.href}});
      } else if (filter.key === 'appgroups' && appGroupNode) {
        types[filter.key].push(
          appGroupNode.labels.map(({label}) => ({
            label: {href: label.href},
          })),
        );
      } else if (filter.key !== 'transmission' && filter.key !== 'fqdn' && filter.key !== 'containerWorkloads') {
        if (types.labels[filter.key]) {
          types.labels[filter.key].push({label: {href: filter.href}});
        } else {
          types[filter.key].push({label: {href: filter.href}});
        }
      }
    }
  });

  if (allWorkloads) {
    // Remove extraneous workload fitlers
    types.labels = {role: [], app: [], env: [], loc: []};
    types.appgroups = [];
    types.workloads = [];
  }

  return types;
};

const formatAndSortPortFilters = items => {
  const categories = {
    ports: [],
    processes: [],
    windowsServices: [],
    policyServices: [],
  };

  items.forEach(item => {
    if (item.key === 'portProtocol') {
      categories.ports.push(getPortProtocol(item));
    } else if (item.key === 'portRange') {
      categories.ports.push(getPortRange(item));
    } else if (item.key === 'policyService' && item && item.value && Array.isArray(item.value)) {
      categories.policyServices = [...categories.policyServices, ...getPolicyService(item)];
    } else if (item.key === 'processName') {
      categories.processes.push({process_name: item.value.split('\\').pop()});
    } else if (item.key === 'windowsService') {
      categories.windowsServices.push({windows_service_name: item.value});
    }
  });

  return categories;
};

const getProviderTransmissionExclude = (providerInclude, providerExclude) => {
  const includedTransmissionValues = [];
  const excludedTransmissionValues = [];
  const transmissionValues = ['unicast', 'broadcast', 'multicast'];

  (providerInclude || []).forEach(provider => {
    if (provider.key === 'transmission') {
      includedTransmissionValues.push(provider.href);
    }
  });

  (providerExclude || []).forEach(provider => {
    if (provider.key === 'transmission') {
      excludedTransmissionValues.push(provider.href);
    }
  });

  const intersection = _.difference(transmissionValues, includedTransmissionValues);

  // This check is necessary to ensure we don't push anything to exclude if user hasn't selected anything to exclude
  if (intersection.length < 3) {
    intersection.forEach(transmissionValue => {
      if (!excludedTransmissionValues.includes(transmissionValue)) {
        providerExclude.push({key: 'transmission', href: transmissionValue});
      }
    });
  }

  return providerExclude;
};

export const getFiltersPayload = ({filters, queryName, appGroups, maxResults}) => {
  let {
    consumerInclude,
    providerInclude,
    servicesInclude,
    consumerExclude,
    providerExclude,
    servicesExclude,
    consumerOrProviderInclude,
    consumerOrProviderExclude,
  } = filters;
  const isLegacyExplorerQuery = !servicesInclude && !servicesExclude;
  const {
    Or: orQuery = !(
      _.isEmpty((consumerOrProviderInclude ?? []).flat()) && _.isEmpty((consumerOrProviderExclude ?? []).flat())
    ),
  } = filters;

  if (orQuery) {
    consumerInclude = consumerOrProviderInclude;
    providerInclude = consumerOrProviderInclude;
    consumerExclude = consumerOrProviderExclude;
    providerExclude = consumerOrProviderExclude;
  }

  const consumerProviderArguments = {
    filter: {
      consumerInclude,
      providerInclude,
      consumerExclude,
      providerExclude,
    },
    variables: {
      consumerInclude: [],
      providerInclude: [],
      consumerExclude: [],
      providerExclude: [],
    },
  };

  // legacy explorer queries use portsInclude/portsExclude instead of servicesInclude/servicesExclude
  if (isLegacyExplorerQuery) {
    servicesInclude = filters.portsInclude ?? [];
    servicesExclude = filters.portsExclude ?? [];
  }

  servicesInclude = cartesianProductForServices(formatAndSortPortFilters(servicesInclude));
  servicesExclude = formatAndSortPortFilters(servicesExclude);

  // First move the transmission values to the exclusion
  consumerProviderArguments.filter.providerExclude = getProviderTransmissionExclude(providerInclude, providerExclude);
  consumerProviderArguments.filter.providerInclude = getProviderTransmissionInclude(providerInclude);

  // Loop through each type of Provider/Consumer combination
  Object.keys(consumerProviderArguments.filter).forEach(key => {
    if (key.includes('Include')) {
      //Assign each individual type of variable to its appropriate value
      consumerProviderArguments.variables[key] = getNestedEndpointQuery(
        sortItems(consumerProviderArguments.filter[key], key, appGroups),
      );
    } else {
      // For the exclude values just map the values without the cartesianProduct
      consumerProviderArguments.variables[key] = getUnNestedEndpointQuery(
        sortItems(consumerProviderArguments.filter[key], key, appGroups),
      );
    }
  });

  const {policyDecisions, boundaryDecisions} = transformPolicyFilters(
    Object.values(filters.action).map(action => action[0]),
  );

  let startDate;
  let endDate;

  if (isLegacyExplorerQuery) {
    startDate = filters.dateFrom ? new Date(filters.dateFrom).toISOString() : getStartDate(filters.time).toISOString();
    endDate = filters.dateTo ? new Date(filters.dateTo).toISOString() : getEndDate(filters.time).toISOString();
  } else {
    const range = getTimeRange(filters.time);

    startDate = range.start.toISOString();
    endDate = range.end.toISOString();
  }

  return {
    query_name: queryName,
    sources: {
      include: consumerProviderArguments.variables.consumerInclude,
      exclude: consumerProviderArguments.variables.consumerExclude.flat(), //Ensure that exclude is always a single array not double array [[{}]]
    },
    destinations: {
      include: consumerProviderArguments.variables.providerInclude,
      exclude: consumerProviderArguments.variables.providerExclude.flat(), //Ensure that exclude is always a single array not double array [[{}]]
    },
    services: {
      include: servicesInclude,
      exclude: servicesExclude,
    },
    sources_destinations_query_op: orQuery ? 'or' : 'and',
    start_date: startDate,
    end_date: endDate,
    policy_decisions: policyDecisions,
    boundary_decisions: boundaryDecisions,
    max_results: maxResults,
  };
};

export function parseIpTraffic(data) {
  return parseIpTrafficDirection(data, 'src').concat(parseIpTrafficDirection(data, 'dst'));
}

// Reduce the traffic into one entry per IP Address per direction
function parseIpTrafficDirection(links, endpoint) {
  const linksArray = links && _.isArray(links) ? links : [];
  const linksDataObj = linksArray?.reduce((result, link) => {
    if (!link[endpoint].workload) {
      result[link[endpoint].ip] = mergeIpTraffic(result[link[endpoint].ip], link, endpoint);
    }

    return result;
  }, {});

  return Object.values(linksDataObj).map(traffic => ({
    ...traffic,
    workloads: Object.keys(traffic.workloads),
    ports: Object.keys(traffic.ports),
  }));
}

// Aggregate a new piece of traffic to the rest of the traffic for that address - in the same direction
// We need:
// Number of workloads this address is talking to
// A list of Ports it is talking on
// Number of flows to/from the address
// The direction of the flow
function mergeIpTraffic(ipAddress, newLink, endpoint) {
  // Collect the information from the newLink
  const otherend = endpoint === 'src' ? 'dst' : 'src';

  newLink.service.windows_service_name ||= '';

  newLink.service.process_name ||= '';

  const portKey = `${getPort(newLink.service) || ''} ${portUtils.lookupProtocol(newLink.service.proto)} ${
    newLink.service.process_name
  } ${newLink.service.windows_service_name}`;
  let ports = {};
  let workloads = {};
  let flows = newLink.num_connections;

  // If an entry already exists for this address, aggregate it
  if (ipAddress) {
    workloads = ipAddress.workloads;
    ports = ipAddress.ports;
    flows += ipAddress.flows;
  }

  const address = newLink[endpoint].ip;
  const direction = endpoint === 'src' ? intl('Common.Inbound') : intl('Common.Outbound');
  const domain = newLink?.dst?.fqdn || ipAddress?.domain || '';
  const portObject = (ipAddress && ipAddress.portObject) || {};
  let transmission = newLink.transmission ?? intl('Map.Traffic.Unicast');

  transmission = transmission.charAt(0).toUpperCase() + transmission.slice(1);

  portObject[portKey] = newLink.service;
  ports[portKey] = true;

  if (newLink[otherend].workload) {
    workloads[newLink[otherend].workload.hostname] = true;
  }

  // Return all the unique items
  return {
    id: [address, direction].join(','),
    domain,
    transmission,
    address,
    workloads,
    ports,
    portObject,
    newLink,
    flows,
    direction,
  };
}

export function getQuickFilterApi(quickFilters, policyVersion) {
  const policy = getPolicyFilter(quickFilters, policyVersion);
  const policyForVersion =
    policyVersion === 'draft' ? {draft_policy_decision: policy} : {reported_policy_decision: policy};

  const ip = getIpFilter(quickFilters);
  const transmission = getTransmissionFilter(quickFilters);

  return {
    ...(policy ? policyForVersion : {}),
    ...(transmission ? {transmission} : {}),
    ...(ip ? {ip_property: ip} : {}),
  };
}

export function getTransmissionFilter(transmissionFilter) {
  let options = [];

  if (transmissionFilter.has('broadcast')) {
    options = ['broadcast'];
  }

  if (transmissionFilter.has('multicast')) {
    options = [...options, 'multicast'];
  }

  if (transmissionFilter.has('unicast')) {
    options = [...options, 'unicast'];
  }

  if (options.length === 3) {
    return null;
  }

  return JSON.stringify(options);
}

export function getIpFilter(ipFilter) {
  let options = ['other'];

  if (ipFilter.has('iplist')) {
    options = [...options, 'iplist'];
  }

  if (ipFilter.has('private')) {
    options = [...options, 'private'];
  }

  if (ipFilter.has('public')) {
    options = [...options, 'public'];
  }

  if (options.length === 4) {
    return null;
  }

  return JSON.stringify(options);
}

export function getPolicyFilter(policyFilter, version) {
  let options = [];

  if (policyFilter.has('allowed')) {
    options = ['allowed', 'allowed_across_boundary'];
  }

  if (policyFilter.has('blocked')) {
    options = [...options, 'blocked', 'blocked_by_boundary'];
  }

  if (policyFilter.has('potentiallyblocked')) {
    options = [...options, 'potentially_blocked', 'potentially_blocked_by_boundary'];
  }

  if (policyFilter.has('unknown') && version !== 'draft') {
    options = [...options, 'unknown'];
  }

  if (options.length === (version === 'draft' ? 6 : 7)) {
    return;
  }

  return JSON.stringify(options);
}

export function getApiQuickFilterWithExclusions(filters, exclusions, policy) {
  return getQuickFilterApi(getFiltersWithoutExclusions(filters, exclusions), policy);
}

export const compareResults = (prev, next) => {
  if (!_.isEqual(Object.keys(prev), Object.keys(next))) {
    return false;
  }

  const prevResults = _.cloneDeep(prev);
  const nextResults = _.cloneDeep(next);
  let compare = true;

  Object.keys(prev).forEach(query => {
    if (prev[query].updatedAt < next[query].updatedAt || prev[query].updatedAt > next[query].updatedAt) {
      compare = false;
    }

    // Do not compare the dates
    delete prevResults[query].updatedAt;
    delete prevResults[query].createdAt;
    delete nextResults[query].updatedAt;
    delete nextResults[query].createdAt;

    if (!_.isEqual(prevResults[query], nextResults[query])) {
      compare = false;
    }
  });

  return compare;
};
