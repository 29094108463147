/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {combineReducers} from 'redux';
import {actionTypes} from 'redux-router5';
import {portUtils} from '@illumio-shared/utils';

const matchesReducer = combineReducers({
  container_clusters(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTERS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  container_workloads(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOADS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  container_workload_profiles(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOAD_PROFILES_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  virtual_services(state = {}, action) {
    switch (action.type) {
      case 'VIRTUAL_SERVICES_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  ip_lists(state = {}, action) {
    switch (action.type) {
      case 'IP_LISTS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  networks(state = {}, action) {
    switch (action.type) {
      case 'NETWORKS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  network4(state = {}, action) {
    switch (action.type) {
      case 'NETWORK4_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  network6(state = {}, action) {
    switch (action.type) {
      case 'NETWORK6_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  vens(state = {}, action) {
    switch (action.type) {
      case 'VENS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  enforcement_boundaries(state = {}, action) {
    switch (action.type) {
      case 'ENFORCEMENT_BOUNDARIES_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  ven_library(state = {}, action) {
    switch (action.type) {
      case 'VEN_LIBRARY_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  labels: combineReducers({
    app(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABELS_GET_MATCHES' && key === 'app') {
        return data;
      }

      return state;
    },
    env(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABELS_GET_MATCHES' && key === 'env') {
        return data;
      }

      return state;
    },
    loc(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABELS_GET_MATCHES' && key === 'loc') {
        return data;
      }

      return state;
    },
    role(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABELS_GET_MATCHES' && key === 'role') {
        return data;
      }

      return state;
    },
    all(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABELS_GET_MATCHES' && !key) {
        return data;
      }

      return state;
    },
  }),
  label_groups: combineReducers({
    app(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABEL_GROUPS_GET_MATCHES' && key === 'app') {
        return data;
      }

      return state;
    },
    env(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABEL_GROUPS_GET_MATCHES' && key === 'env') {
        return data;
      }

      return state;
    },
    loc(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABEL_GROUPS_GET_MATCHES' && key === 'loc') {
        return data;
      }

      return state;
    },
    role(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABEL_GROUPS_GET_MATCHES' && key === 'role') {
        return data;
      }

      return state;
    },
    all(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'LABEL_GROUPS_GET_MATCHES' && !key) {
        return data;
      }

      return state;
    },
  }),
  services(state = {}, action) {
    switch (action.type) {
      case 'SERVICES_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  service_accounts(state = {}, action) {
    switch (action.type) {
      case 'SERVICE_ACCOUNTS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  network_devices(state = {}, action) {
    switch (action.type) {
      case 'NETWORK_DEVICES_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  virtual_servers(state = {}, action) {
    switch (action.type) {
      case 'VIRTUAL_SERVERS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  workloads(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  rule_sets(state = {}, action) {
    switch (action.type) {
      case 'RULE_SETS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  groups(state = {}, action) {
    switch (action.type) {
      case 'GROUPS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  security_principals(state = {}, action) {
    switch (action.type) {
      case 'SECURITY_PRINCIPALS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  slbs(state = {}, action) {
    switch (action.type) {
      case 'SLBS_GET_MATCHES':
        return action.data;
      default:
        return state;
    }
  },
  users: (state = {}, action) => {
    switch (action.type) {
      case 'USERS_GET_MATCHES':
        //  Manipulate the data from the API to include 'name'. Currently
        // 'users.get_collections' does not return 'name' which is used for <Selector> component
        const matches = action.data.matches.map(cur => ({...cur, name: cur.username}));

        return {...action.data, matches};
      default:
        return state;
    }
  },
  org_auth_security_principals: combineReducers({
    name(state = {}, action) {
      const {type, data, key} = action;

      if (type === 'ORG_AUTH_SECURITY_PRINCIPALS_GET_MATCHES' && key === 'name') {
        return data;
      }

      return state;
    },
    group(state = {}, action) {
      const {type, data, key} = action;

      // Reducer for 'External Group'
      if (type === 'ORG_AUTH_SECURITY_PRINCIPALS_GET_MATCHES' && key === 'group') {
        return data;
      }

      return state;
    },
  }),
});
const facetsReducer = combineReducers({
  container_clusters(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTERS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  container_workloads(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOADS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  container_workload_profiles(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOAD_PROFILES_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  labels(state = {}, action) {
    switch (action.type) {
      case 'LABELS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  label_groups(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUPS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  workloads(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  ip_lists(state = {}, action) {
    switch (action.type) {
      case 'IP_LISTS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  networks(state = {}, action) {
    switch (action.type) {
      case 'NETWORKS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  vens(state = {}, action) {
    switch (action.type) {
      case 'VENS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  ven_library(state = {}, action) {
    switch (action.type) {
      case 'VEN_LIBRARY_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  services(state = {}, action) {
    switch (action.type) {
      case 'SERVICES_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  service_accounts(state = {}, action) {
    switch (action.type) {
      case 'SERVICE_ACCOUNTS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  rule_sets(state = {}, action) {
    switch (action.type) {
      case 'RULE_SETS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  groups(state = {}, action) {
    switch (action.type) {
      case 'GROUPS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  virtual_services(state = {}, action) {
    switch (action.type) {
      case 'VIRTUAL_SERVICES_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  discovered_virtual_servers(state = {}, action) {
    switch (action.type) {
      case 'DISCOVERED_VIRTUAL_SERVERS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  virtual_servers(state = {}, action) {
    switch (action.type) {
      case 'VIRTUAL_SERVERS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  network_devices(state = {}, action) {
    switch (action.type) {
      case 'NETWORK_DEVICES_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  pairing_profiles(state = {}, action) {
    switch (action.type) {
      case 'PAIRING_PROFILES_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  enforcement_boundaries(state = {}, action) {
    switch (action.type) {
      case 'ENFORCEMENT_BOUNDARIES_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  security_principals(state = {}, action) {
    switch (action.type) {
      case 'SECURITY_PRINCIPALS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
  org_permissions(state = {}, action) {
    switch (action.type) {
      case 'ORG_PERMISSIONS_GET_FACETS':
        return {facet: action.query.facet, ...action.data};
      default:
        return state;
    }
  },
});

export default {
  loadingFacets(state = false, action) {
    switch (action.type) {
      case 'LOADING_FACETS':
        return true;
      case 'FACETS_LOADED':
        return false;
      default:
        return state;
    }
  },
  loadingMatches(state = false, action) {
    switch (action.type) {
      case 'LOADING_MATCHES':
        return true;
      case 'MATCHES_LOADED':
        return false;
      default:
        return state;
    }
  },
  matches: (state, action) => {
    if (
      action.type === actionTypes.TRANSITION_SUCCESS &&
      action.payload?.previousRoute?.name !== action.payload?.route?.name
    ) {
      state = undefined;
    }

    return matchesReducer(state, action);
  },
  facets: (state, action) => {
    if (
      action.type === actionTypes.TRANSITION_SUCCESS &&
      action.payload?.previousRoute?.name !== action.payload?.route?.name
    ) {
      state = undefined;
    }

    return facetsReducer(state, action);
  },
};

export const getServiceFacets = (matches, facet) => {
  if (facet?.includes('port')) {
    matches = matches.map(String).filter(match => match !== '-1');
  }

  if (facet?.includes('proto')) {
    matches = matches
      .filter(protocol => protocol.toString() !== '-1')
      .map(protocol => portUtils.lookupProtocol(protocol));
  }

  return matches;
};

export const getAllMatches = (state, objects) => {
  const res = objects.reduce((prev, object) => {
    const {key, type} = object;
    const isLabelObject = type === 'labels' || type === 'label_groups';
    let matches;
    let count;

    if (key === undefined && !isLabelObject) {
      try {
        matches = state.matches[type]?.matches || [];
        count = state.matches[type]?.num_matches || 0;
      } catch (err) {
        console.log('Error', {matches: state.matches, type, err});
      }
    } else {
      matches = (state.matches[type] && state.matches[type][key === undefined ? 'all' : key]?.matches) || [];
      count = (state.matches[type] && state.matches[type][key === undefined ? 'all' : key]?.num_matches) || 0;
    }

    prev[type] = {
      matches,
      num_matches: count,
    };

    return prev;
  }, {});

  return res;
};

export const getFacetName = (state, object) => state.facets[object].facet;

export const getFacetNameFromArray = (state, objects) => {
  const objectForFetchedFacet = objects.find(({type}) => _.get(state.facets[type], 'facet'));

  return objectForFetchedFacet ? state.facets[objectForFetchedFacet.type].facet : null;
};

export const getSelectorFacets = (state, object) => {
  const matches = _.get(state.facets[object], 'matches');

  if (object === 'services' || object === 'discovered_virtual_servers') {
    return getServiceFacets(matches, state.facets[object].facet);
  }

  return matches;
};

export const getFacetCount = (state, object) => _.get(state.facets[object], 'num_matches');

export const getSelectorMatches = (state, object, key) => {
  if (!state.matches[object]) {
    return;
  }

  const isLabelObject = object === 'labels' || object === 'label_groups';

  if (key === undefined && !isLabelObject) {
    return state.matches[object].matches;
  }

  return _.get(state.matches[object][key === undefined ? 'all' : key], 'matches');
};

export const getMatchCount = (state, object, key) => {
  if (!state.matches[object]) {
    return;
  }

  if (key === undefined) {
    return state.matches[object].num_matches;
  }

  return _.get(state.matches[object][key], 'num_matches', 0);
};

export const getMatchesAndFacets = (state, objects) => {
  const map = new Map();

  objects.forEach(object => {
    const {type, key} = object;
    const facets = getSelectorFacets(state, type);
    const matches = getSelectorMatches(state, type, key);

    if (facets) {
      const facetName = getFacetName(state, type);
      const count = getFacetCount(state, type);

      map.set({facet: facetName}, {count, matches: facets});
    }

    if (matches) {
      const count = getMatchCount(state, type, key);

      map.set({object}, {count, matches});
    }
  });

  return map;
};

export const isLoadingMatches = state => state.loadingMatches;

export const isLoadingFacets = state => state.loadingFacets;
