/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {all, setContext} from 'redux-saga/effects';
import {sagaUtils} from '@illumio-shared/utils';
import {watchLogout} from 'containers/App/AppSaga';
import {watchNetworkEnforcementNodeSelect} from 'containers/Switch/NetworkEnforcementNodeSaga';
import {changePassword} from 'containers/RBAC/UsersAndGroups/LocalUsers/Detail/LocalUserDetailSaga';
import {
  watchSelectorRequests,
  watchClearSelectorCache,
  watchGetCollectionSelectorRequests,
  watchFacetRequests,
  watchCreateRequests,
} from 'containers/Selectors/SelectorSaga';
import {checkProcessingActionListener} from 'containers/Provisioning/ProvisioningSaga';
import {removeSelection} from 'containers/Provisioning/Provision/ProvisionSaga';
import {eventsWatchers} from 'containers/Events/List/EventsListSaga';
import {watchUpdateGridSettings} from 'components/Grid/GridSaga';
import {watchUpdateSelectorHistory} from 'containers/Selector/SelectorSaga';

export default function* rootSaga(store) {
  // Put store into saga context, to be able to take it in any child saga like `yield getContext('store')`
  yield setContext({store});

  yield all([
    sagaUtils.startPageVisibilitySaga(5000),
    watchCreateRequests(),
    watchFacetRequests(),
    watchSelectorRequests(),
    watchGetCollectionSelectorRequests(),
    watchClearSelectorCache(), // added by Stephanie Guo
    watchNetworkEnforcementNodeSelect(),
    watchLogout(),
    changePassword(),
    removeSelection(),
    ...(__MSP__ ? [] : [checkProcessingActionListener()]),
    eventsWatchers(),
    watchUpdateGridSettings(),
    watchUpdateSelectorHistory(),
  ]);
}
