/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import * as PropTypes from 'prop-types';
import Selectors from '../Selectors';
import {PureComponent} from 'react';
import {connect} from '@illumio-shared/utils/redux';
import {AppContext} from 'containers/App/AppUtils';
import {Modal, TypedMessages} from 'components';
import {createNewObject} from 'containers/Selectors/SelectorSaga';
import {getAllMatches, isLoadingMatches} from 'containers/Selectors/SelectorState';

const getEmptyState = () => ({
  createObjectError: null,
});

@connect((state, props) => ({
  loading: isLoadingMatches(state),
  matches: getAllMatches(state, props.objects),
}))
export default class SingleGroupSingleItemSelect extends PureComponent {
  static contextType = AppContext;
  static propTypes = {
    initialItems: PropTypes.array,
    disabled: PropTypes.bool,
    facets: PropTypes.object,
    statics: PropTypes.object,
    categories: PropTypes.array.isRequired,
    hiddenCategories: PropTypes.array,
    objects: PropTypes.array.isRequired,
    allowCreateTypes: PropTypes.array,
    addValue: PropTypes.func,
    placeholder: PropTypes.string,
    createKey: PropTypes.string,
    footer: PropTypes.any,
    filterMatches: PropTypes.func,
    onSelectionChange: PropTypes.func,
    dontAllowCreate: PropTypes.func,
    tid: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    onSelectionChange: _.noop,
    tid: '',
  };

  constructor(props) {
    super(props);

    this.state = getEmptyState();

    this.fetchData = this.fetchData.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAlertCancel = this.handleAlertCancel.bind(this);
  }

  getDropdownValues(matches) {
    if (!matches) {
      return {};
    }

    return _.reduce(
      matches,
      (prev, object, key) => {
        const matches = object.matches
          ? object.matches.map(match => {
              if (typeof match === 'string') {
                return {categoryKey: key, value: match};
              }

              return {categoryKey: key, ...match};
            })
          : [];

        prev[key] = {
          matches,
          count: object.num_matches,
        };

        return prev;
      },
      {},
    );
  }

  handleInputChange(input, object) {
    this.fetchData(input, object);
  }

  async handleCreate(input, object) {
    let {type, key, pversion} = object;

    const params = {pversion};

    key ||= this.props.createKey;

    const query = {query: '', key};

    let data = {key, name: input};

    if (type === 'labels') {
      data = {key, value: input};
    } else if (type === 'label_groups') {
      data = {
        name: input,
        key,
        description: '',
      };
    }

    try {
      const created =
        type === 'labels'
          ? await this.context.store.runSaga(createNewObject, type, data, query).toPromise()
          : await this.context.store.runSaga(createNewObject, type, data, query, params).toPromise();

      this.props.onSelectionChange([
        {key: created.key, value: type === 'labels' ? created.value : created.name, href: created.href},
      ]);
    } catch (error) {
      error.errorData = _.get(error, 'data[0]');
      this.setState({createObjectError: error.errorData});
    }
  }

  handleAlertCancel() {
    this.setState(getEmptyState());
  }

  fetchData(input, object) {
    const {type, key, labelGroup, ...params} = object;
    const query = {query: input, key};

    if (labelGroup) {
      query.label_group = labelGroup;
    }

    this.props.dispatch({type: `${type.toUpperCase()}_MATCHES_REQUEST`, object: type, query, params});
  }

  renderErrorAlert() {
    const {createObjectError} = this.state;

    return (
      <Modal.Alert
        title={intl('Common.Alert')}
        onClose={this.handleAlertCancel}
        buttonProps={{tid: 'ok', text: intl('Common.OK')}}
      >
        <TypedMessages>{[{icon: 'error', content: createObjectError.message}]}</TypedMessages>
      </Modal.Alert>
    );
  }

  render() {
    const {
      initialItems,
      hiddenCategories,
      placeholder,
      footer,
      loading,
      disabled,
      statics,
      facets,
      objects,
      allowCreateTypes,
      dontAllowCreate,
      tid,
      filterMatches,
      clearItemsOnSelect,
    } = this.props;
    const matches = this.getDropdownValues(this.props.matches);
    const {createObjectError} = this.state;

    if (filterMatches) {
      Object.keys(matches).forEach(category => {
        if (matches[category].count) {
          matches[category].matches = matches[category].matches.filter(filterMatches);
          matches[category].count = matches[category].matches.length;
        }
      });
    }

    const categories = [...this.props.categories];

    if (hiddenCategories && hiddenCategories.length) {
      categories.push({key: 'show_all_categories', value: intl('ObjectSelector.ShowAllCategories')});
    }

    const props = {
      clearItemsOnSelect,
      initialItems,
      placeholder,
      activeCategoryKey: categories[0].categoryKey,
      categories,
      disabled,
      hiddenCategories,
      facets,
      statics,
      matches,
      objects,
      footer,
      loading,
      allowCreateTypes,
      dontAllowCreate,
      onCreate: this.handleCreate,
      showContainerTitle: true,
      onInputChange: this.handleInputChange,
      onSelectionChange: this.props.onSelectionChange,
      tid,
    };

    return (
      <>
        <Selectors {...props} />
        {createObjectError && this.renderErrorAlert()}
      </>
    );
  }
}
